/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */ }
import Box from '@mui/material/Box';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Image } from "semantic-ui-react";
import './dialogbox.scss'
interface curosalList {
    popUpImages: any
}



const MyCarouselComponent = (data: any) => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <React.Fragment>
            <Carousel
                responsive={responsive}
                slidesToSlide={1}
                swipeable={false}
                draggable={false}
                showDots={true}
                keyBoardControl={true}
                customLeftArrow={<button className="carouselPreviousBtn">
                    {"Previous"}
                </button>}
                customRightArrow={<button className="carouselNextBtn">
                    {"Next"}
                </button>}
                renderButtonGroupOutside
            >
                {data.popUpImages.map((item: any, key: any) => (
                    <Box key={key} className="fastClass">
                        <Image src={item.imageUrl} alt="Tabs Image" />
                    </Box>

                ))}
            </Carousel>
        </React.Fragment>
    )
}

const DialogBoxCarousal = (props: curosalList) => {
    const { popUpImages } = props;
    return (<div className="dialogBoxAthletepopup">
        <MyCarouselComponent popUpImages={popUpImages}></MyCarouselComponent>
    </div >)
}
export default DialogBoxCarousal;