import React from "react";
import { ApolloError, useQuery } from "@apollo/client";
import { ATHLETE_EARNINGS_SUMMARY } from "../../../queries";
import { AthleteTransactionSummaryResponse } from "../../../types";
import { formatCurrency } from "../../../utils";
import { useNotifications } from "../../../providers/Notification";
import Box from "@mui/material/Box";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EarningsHeader = () => {
  const notify = useNotifications();

  const { data } = useQuery<AthleteTransactionSummaryResponse>(
    ATHLETE_EARNINGS_SUMMARY,
    {
      onError: (error: ApolloError) => {
        notify.error(error.message);
      },
    }
  );

  const total = data?.athleteTransactionSummary.totalEarnings || 0;
  const totalItems = data?.athleteTransactionSummary.totalSuccessfulTransactionCount || 0;

  return (
    <React.Fragment>

      <Box className="total-earnings">
        <span><small>Total Earnings</small><small>Transactions Completed</small></span>
        <Box className="total-earnings__total">
          <strong>{formatCurrency(total)}</strong>
          <strong>{totalItems} <small>completed</small></strong>
        </Box>
      </Box>
    </React.Fragment>
  );
};
