/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { Image } from "semantic-ui-react";

import Box from "@mui/material/Box";
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import Filters from "assets/images/filters.png";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './fan.css'
import { fanSplashImagesRes } from "types/fanSplashImages";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FAN_SPLASH_IMAGES } from '../../../queries/fanSplashImages';
import whatcanido from '../../../assets/images/what_can_do_fan.png';
import fan from '../../../assets/images/what-can-do-fan/Frame-1.png';
import whyusestep1 from '../../../assets/images/why-use-mynilpay/Frame-1.png';
import whyusestep2 from '../../../assets/images/why-use-mynilpay/Frame-2.png';
import whyusestep3 from '../../../assets/images/why-use-mynilpay/Frame-3.png';
import whyusestep4 from '../../../assets/images/why-use-mynilpay/Frame-4.png';

import universalnillpaystep1 from '../../../assets/images/what-is-universal-nil/Frame-1.png';
import universalnillpaystep2 from '../../../assets/images/what-is-universal-nil/Frame-2.png';
import universalnillpaystep3 from '../../../assets/images/what-is-universal-nil/Frame-3.png';
import transparency from '../../../assets/images/transparency.png';
import transparencyStep1 from '../../../assets/images/payment-Transparency/Frame-1.png';
import transparencyStep2 from '../../../assets/images/payment-Transparency/Frame-2.png'

import howItWorksBusiness from '../../../assets/images/how-it-works-business.png';
import whatCanDoBusiness from '../../../assets/images/what-can-do-business.png';
import whyUseBusinessNilpay from '../../../assets/images/whay-use-business-nilpay.png';
import whynilluniversal from '../../../assets/images/what-is-nil-universal.png';

import whyusemynillpayfan from '../../../assets/images/whyusemynillpayfan.png'
import howItWorksBusinessStep1 from '../../../assets/images/how-it-works-business/Frame-1.png';
import howItWorksBusinessStep2 from '../../../assets/images/how-it-works-business/Frame-2.png';
import howItWorksBusinessStep3 from '../../../assets/images/how-it-works-business/Frame-3.png';
import howItWorksBusinessStep4 from '../../../assets/images/how-it-works-business/Frame-4.png';
import howItWorksBusinessStep5 from '../../../assets/images/how-it-works-business/Frame-5.png';
import howItWorksBusinessStep6 from '../../../assets/images/how-it-works-business/Frame-6.png';
import howItWorksBusinessStep7 from '../../../assets/images/how-it-works-business/Frame-7.png';

import what_can_do_for_business_step1 from '../../../assets/images/what-can-i-do-for-business/Frame-1.png';
import what_can_do_for_business_step2 from '../../../assets/images/what-can-i-do-for-business/Frame-2.png';

import why_use_mynilpay_busines_step1 from '../../../assets/images/why-use-mynilpay-business/Frame-1.png';
import why_use_mynilpay_busines_step2 from '../../../assets/images/why-use-mynilpay-business/Frame-2.png';
import why_use_mynilpay_busines_step3 from '../../../assets/images/why-use-mynilpay-business/Frame-3.png';


import how_it_works_fan_01 from '../../../assets/images/howitworksfan/Frame-1.png';
import how_it_works_fan_02 from '../../../assets/images/howitworksfan/Frame-2.png';
import how_it_works_fan_03 from '../../../assets/images/howitworksfan/Frame-3.png';
import how_it_works_fan_04 from '../../../assets/images/howitworksfan/Frame-4.png';
import how_it_works_fan_05 from '../../../assets/images/howitworksfan/Frame-5.png';
import how_it_works_fan_06 from '../../../assets/images/howitworksfan/Frame-6.png';


import how_it_works_fan from '../../../assets/images/how-it-works-for-fan.png';
import about from '../../../assets/images/about.png';
import about_banner_01 from '../../../assets/images/aboutbanner-02.png';

import fan_legal_fan_01 from '../../../assets/images/fanlegal/Frame-1.png';
import fan_legal_fan_02 from '../../../assets/images/fanlegal/Frame-2.png';
import fan_legal_fan_03 from '../../../assets/images/fanlegal/Frame-3.png';
import safe_banner from '../../../assets/images/mynilpaysafeandlegal.png';

import useUser from "../../../hooks/useUser";

import DialogBoxCarousal from './DialogBoxCarousal';


const fanLegal = [{ imageUrl: fan_legal_fan_01 }, { imageUrl: fan_legal_fan_02 }, { imageUrl: fan_legal_fan_03 }]

const how_its_for_fan_flow = [
    {
        imageUrl: how_it_works_fan_01
    },
    {
        imageUrl: how_it_works_fan_02
    },
    {
        imageUrl: how_it_works_fan_03
    },
    {
        imageUrl: how_it_works_fan_04
    },
    {
        imageUrl: how_it_works_fan_05
    },
    {
        imageUrl: how_it_works_fan_06
    }

]

const why_use_mynilpay_for_business = [
    {
        imageUrl: why_use_mynilpay_busines_step1
    },
    {
        imageUrl: why_use_mynilpay_busines_step2
    },
    {
        imageUrl: why_use_mynilpay_busines_step3
    }

]

const what_can_do_for_business = [
    {
        imageUrl: what_can_do_for_business_step1
    },
    {
        imageUrl: what_can_do_for_business_step2
    }

]

const howItworksBusiness = [
    {
        imageUrl: howItWorksBusinessStep1
    },
    {
        imageUrl: howItWorksBusinessStep2
    },
    {
        imageUrl: howItWorksBusinessStep3
    },
    {
        imageUrl: howItWorksBusinessStep4
    },
    {
        imageUrl: howItWorksBusinessStep5
    },
    {
        imageUrl: howItWorksBusinessStep6
    },
    {
        imageUrl: howItWorksBusinessStep7
    }
]

const aboutbanner = [
    {
        imageUrl: about_banner_01
    }
]

const universalNillPay = [{
    imageUrl: universalnillpaystep1
}, {
    imageUrl: universalnillpaystep2
},
{
    imageUrl: universalnillpaystep3
}
]


const whatcanidoFan = [{
    imageUrl: fan
}]


const whyusemynillpay = [{
    imageUrl: whyusestep1
},
{
    imageUrl: whyusestep2
},
{
    imageUrl: whyusestep3
},
{
    imageUrl: whyusestep4
}
]

const myNILpayPaymentTransparency = [{
    imageUrl: transparencyStep1
},
{
    imageUrl: transparencyStep2
}
]

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
interface fanProps {
    onGotoAthelete: () => void
}



function FanHome(props: fanProps) {
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    // eslint-disable-next-line
    const [popUpImages, setPopUpImages] = useState<any>([]);
    const [imageScreen, setImageScreen] = useState('');
    const group = "fan_offer";
    const screen = "";
    const user = useUser();
    const { onGotoAthelete } = props;

    const { data } = useQuery<fanSplashImagesRes>(FAN_SPLASH_IMAGES, {
        variables: {
            group: group,
            screen: screen
        },
    });

    const [getSplashImages, { data: courousal, refetch }] = useLazyQuery(FAN_SPLASH_IMAGES, {
        variables: {
            group: "",
            screen: imageScreen
        }
    });

    const handleClickOpenworksfan = () => {
        setOpen(true);
        setPopUpImages(how_its_for_fan_flow)
    }


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const handleClickOpenWhatCando = () => {
        setPopUpImages(whatcanidoFan);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (courousal) {
            setOpen(true);
            setPopUpImages(courousal.activeSplashImageDetails)
        }
    }, [courousal])

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    // eslint-disable-next-line
    const handleOpenDynamic = async (item: any) => {
        getSplashImages();
        setImageScreen(item.screen);
        refetch();
        setOpen(true);
    }

    const handleClickOpenMyNillPayFan = () => {
        setPopUpImages(whyusemynillpay);
        setOpen(true);
    }

    const handleClickOpenTransparency = () => {
        setOpen(true);
        setPopUpImages(myNILpayPaymentTransparency);
    }


    const handleClickOpenHowItWorkBusiness = () => {
        setOpen(true);
        setPopUpImages(howItworksBusiness);
    }
    const handleClickDoBusiness = () => {
        setOpen(true);
        setPopUpImages(what_can_do_for_business);
    }

    const handleClickOpenBusinessnillpay = () => {
        setOpen(true);
        setPopUpImages(why_use_mynilpay_for_business);
    }
    const handleClickOpenBusinessUniversal = () => {
        setOpen(true);
        setPopUpImages(universalNillPay);
    }
    const handleAboutOpen = () => {
        setOpen(true);
        setPopUpImages(aboutbanner);
    }
    const handleClickOpenSafe = () => {
        setPopUpImages(fanLegal);
        setOpen(true);
    }


    return (
        <Box className="main-home" sx={{ position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px", }} paddingTop={{ xs: "10px", md: "0" }}>
            <Box className="main-home__header">
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                    <Grid item xs={8} sx={{ textAlign: 'right' }}>
                        <h4>First-of-its-kind experience to direct-pay college athletes</h4>
                    </Grid>
                    <Grid item xs={4} sx={{ justifyContent: 'flex-end' }}>
                        <Box className="user" sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                            <span>
                                <strong>{user?.firstName} {user?.lastName}</strong>
                                <small>Fan</small>
                            </span>
                            <AccountCircleIcon />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="main-home__body">
                <Box className="main-home__search">
                    <h1>Find & Pay Athletes</h1>
                    <Box className="main-home__search-layout">
                        <FormControl variant="standard" onClick={onGotoAthelete}
                        >
                            <Input className="main-home__input" placeholder="Search athlete, team, school"
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <img src={Filters} alt="myNILpay logo" />
                    </Box>
                </Box>

                <Dialog className="carousal-dialog"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">
                        <div style={{ float: 'right' }}>
                            <IconButton onClick={handleClose}>  <div style={{ float: 'right' }}><CloseIcon /></div></IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ minHeight: '100%' }}>
                        <DialogBoxCarousal
                            popUpImages={popUpImages}
                        />
                    </DialogContent>
                </Dialog>
                <Box className="main-home__tabs fan_page">
                    <Box>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="For Fans" {...a11yProps(0)} />
                            <Tab label="For Business" {...a11yProps(1)} />
                            <Tab label="About" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} >
                                <Carousel swipeable={false}
                                    draggable={true}
                                    showDots={false}
                                    responsive={responsive}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={false}
                                    autoPlay={false}
                                    keyBoardControl={true}
                                    customTransition="all .5"
                                    transitionDuration={500}
                                    containerClass="carousel-container"
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    deviceType={"desktop"}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                >
                                    {/* eslint-disable-next-line */}
                                    {data?.activeSplashImageDetails?.map((item: any, key) => {
                                        return <div key={key} className="carousalmainContainer"> <Image src={item.imageUrl} alt="Tabs Image" onClick={() => handleOpenDynamic(item)} /></div>
                                    })}
                                    <div className="carousalmainContainer" onClick={handleClickOpenworksfan}>
                                        <div>How does myNILpay works?</div>
                                        <Image src={how_it_works_fan} alt="Tabs Image" /> </div>
                                    <div className="carousalmainContainer" onClick={handleClickOpenWhatCando}> <div>What can i do with myNILpay?</div><Image src={whatcanido} alt="Tabs Image" /></div>
                                    <div className="carousalmainContainer" onClick={handleClickOpenMyNillPayFan}><div>Why should I use myNILpay?</div> <Image src={whyusemynillpayfan} alt="Tabs Image" /></div>
                                    <div className="carousalmainContainer" onClick={handleClickOpenTransparency}><div>Payment Transparency</div><Image src={transparency} alt="Tabs Image" /></div>
                                </Carousel>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Carousel
                            swipeable={false}
                            draggable={true}
                            showDots={true}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={false}
                            autoPlay={true}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            deviceType={"desktop"}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-20-px">
                            <div className="carousalmainContainer" onClick={handleClickOpenHowItWorkBusiness} > <div>How does MyNILpay works?</div>
                                <Image src={howItWorksBusiness} alt="Tabs Image" /></div>
                            <div className="carousalmainContainer" onClick={handleClickDoBusiness} > <div>What can I do with myNILpay?</div><Image src={whatCanDoBusiness} alt="Tabs Image" /></div>
                            <div className="carousalmainContainer" onClick={handleClickOpenBusinessnillpay} ><div>Why should I use myNILpay?</div>
                                <Image src={whyUseBusinessNilpay}
                                    className="whyUseMyNilpay"
                                    alt="Tabs Image" /></div>
                        </Carousel>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Carousel
                            swipeable={false}
                            draggable={true}
                            showDots={true}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={false}
                            autoPlay={true}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            deviceType={"desktop"}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-20-px">
                            <div className="carousalmainContainer" onClick={handleClickOpenBusinessUniversal} ><div>What is NIL</div><Image src={whynilluniversal} className="whyUseMyNilpay" alt="Tabs Image" /></div>
                            <div className="carousalmainContainer" onClick={handleAboutOpen}><div>About Us</div><Image className="whyUseMyNilpay" src={about} alt="Tabs Image" /></div>
                            <div className="carousalmainContainer" onClick={handleClickOpenSafe} ><div>myNILpay is SAFE and LEGAL-Universal</div><Image src={safe_banner} className="whyUseMyNilpay" alt="Tabs Image" /></div>
                        </Carousel>
                    </TabPanel>
                </Box>
            </Box>
        </Box >

    );
}

export default FanHome;
