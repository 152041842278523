import React from "react";

import { PublicProfile } from "pages/Fan/AthleteProfile/PublicProfile";
import { RequestSuccess } from "pages/Public/Register/AthleteSignUp/RequestSuccess";
import { Route } from "react-router-dom";
import { SSO } from "pages/Public/Login/SSO";
import AthleteSignUp from "pages/Public/Register/AthleteSignUp";
import FanSignUpForm from "pages/Public/Register/FanSignUp";
import ForgotPassword from "pages/Public/ForgotPassword";
import Login from "pages/Public/Login";
import Request from "pages/Public/Register/AthleteSignUp/Request";
import UserTypeSelection from "pages/Public/Register/UserTypeSelection";
import LandingPage from "pages/Public";
import ConfirmUser from "components/VerifyAccount";
import WellKnown from "components/WellKnown";
import AppleAppState from "components/AppleAppState";
import Invoice from "pages/Fan/Profile/invoiceContent";

export const PublicRoutes = () => (
  <React.Fragment>
    <Route path="/" element={<LandingPage />} />
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<UserTypeSelection />} />
    <Route path="/register/fan" element={<FanSignUpForm />} />
    <Route path="/register/athlete" element={<AthleteSignUp />} />
    <Route path="/register/athlete/request" element={<Request />} />
    <Route
      path="/register/athlete/request/success"
      element={<RequestSuccess />}
    />
    <Route path="/forgotPassword" element={<ForgotPassword />} />
    <Route path="/sso" element={<SSO />} />
    <Route path="/public/:id" element={<PublicProfile />} />
    <Route path="/confirmUser" element={<ConfirmUser />} />
    <Route path="/.well-known/assetlinks.json" element={<WellKnown />} />
    <Route path="/.well-known/apple-app-site-association" element={<AppleAppState />} />
    <Route path="/invoice/:id" element={<Invoice />} />
  </React.Fragment>
);
