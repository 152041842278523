import React from "react";
import Header from "components/Header";
import Box from "@mui/material/Box";
import useAlerts from "hooks/useAlerts";
import CircularProgress from "@mui/material/CircularProgress";
import { getFormatedTimeStamp } from '../../utils';
import moment from 'moment'
import InfiniteScroll from "../AlertsInfiniteScroll";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useLazyQuery } from "@apollo/client";
import { getNotificationsCount } from "queries";
type notificationDisplay = {
    id: string;
    dismissed: string;
    title: string;
    data: string;
    createdAt: Date;
}
interface notifications {
    [key: string]: notificationDisplay[]
}

function Alert() {

    const deviceType = "DESKTOP";
    const sortByDesc = true;
    const dismissed = "-1";
    const athleteEarningActivities = false;
    const page = 1;
    const limit = 20;

    const { data, loading, fetchMore: LoadMore } = useAlerts(deviceType, sortByDesc, dismissed, athleteEarningActivities, page, limit);
    const [getNotifications_Count, { refetch }] = useLazyQuery(getNotificationsCount, {
        variables: {
            deviceType: "DESKTOP",
        }
    });

    React.useEffect(() => {
        getNotifications_Count()
        refetch()
    }, [])

    const obj: notifications = {};
    const res = data?.getNotifications?.items;
    // eslint-disable-next-line
    res?.forEach((item: any) => {
        if (item?.createdAt) {
            const dt = moment(item.createdAt).format('DD-MM-YYYY');
            if (obj[dt]) {
                obj[dt].push(item);
            } else {
                obj[dt] = [item];
            }

        }
    })

    if (loading) { return <CircularProgress />; }

    const scrollGetNotificationCount = () => {
        getNotifications_Count();
        refetch()
    }

    return (
        <>  <Box className="notifications">

            <Header text="Notifications" />

            <Box>

                {obj && Object.keys(obj).length > 0 ? Object.keys(obj).map((item) => {
                    return <Box key={item}>
                        <Box className="lable"><em>{getFormatedTimeStamp(item)}</em></Box>
                        <Box> <InfiniteScroll
                            fetchMore={LoadMore}
                            page={Number(data?.getNotifications?.pageNumber) + 1}
                            scrollGetNotificationCount={scrollGetNotificationCount}
                        >
                            {obj[item].map((items: notificationDisplay, i) => {
                                return <Box key={i}>
                                    <Box className="list">
                                        <small></small>
                                        <strong>{items.title}</strong>
                                        <p>{items.data} </p>
                                    </Box>

                                </Box>
                            })}
                        </InfiniteScroll>
                        </Box>


                    </Box>
                }) : <Box>No Notifications</Box>}</Box>

            {data && Number(data?.getNotifications?.itemTotal === data?.getNotifications.items.length) ? "" :
                < Stack spacing={1} marginBottom={4}>
                    <Skeleton variant="rectangular" height={50} />
                    <Skeleton variant="rectangular" height={50} />
                    <Skeleton variant="rectangular" height={50} />
                </Stack>
            }

        </Box>
        </>
    );
}

export default Alert;
