import React, { useState } from "react";
import { apolloClient } from "providers/Apollo";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteAccountModal from "components/DeleteAccountModal";
import Stack from "@mui/material/Stack";
import Header from "components/Header";

const Settings = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const notify = useNotifications();

  const settingsOptions = [
    "Personal Information",
    "Privacy",
    "Help / FAQs",
    "About",
    "Logout",
    "Delete Account",
  ];

  async function logout() {
    try {
      await Auth.signOut();
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("LoginUserObject");
      apolloClient.cache.reset();
      return navigate("/login", { replace: true });
    } catch (error) {
      notify.error("error signing out");
      console.log("error signing out: ", error);
    }
  }

  const handleClick = (page: string) => {
    switch (page) {
      case "Personal Information":
        navigate("/personalinformation");
        break;
      case "Logout":
        logout();
        break;
      case "Delete Account":
        setOpenModal(true);
        break;

      default:
        console.log(page);
        break;
    }
  };

  return (
    <Box>
      <DeleteAccountModal open={openModal} setOpen={setOpenModal} />
      <Header text="Account Settings" />
      <Stack sx={{ marginLeft: 2 }} spacing={1} alignItems="flex-start">
        {settingsOptions.map((name) => (
          <Button key={name} onClick={() => handleClick(name)}>
            {name}
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

export default Settings;
