import React, { useState } from "react";
import { athleteEmailSearchResponse } from "types";
import { Auth } from "aws-amplify";
import { LogoHeader } from "../LogoHeader";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";
import FinalStep from "./FinalStep";
import Grid from "@mui/material/Grid";
import RegisterStep, { RegisterForm } from "./RegisterStep";
import SearchStep from "./SearchStep";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export type SearchResult = athleteEmailSearchResponse["athleteEmailSearch"];

const AthleteSignUp = () => {
  const navigate = useNavigate();
  const notify = useNotifications();
  const [registerData, setRegisterData] = useState<RegisterForm | null>(null);
  const [showRegister, setShowRegister] = useState(false);
  const [email, setEmail] = useState("");
  const [result, setResult] = useState<SearchResult | null>(null);

  const login = async () => {
    if (registerData) {
      try {
        await Auth.signIn({ username: email, password: registerData.password });
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();

        if (token) {
          window.localStorage.setItem("token", token);
          navigate("/home", { replace: true });
        } else {
          notify.error("Login Failed");
          navigate("/login");
        }
      } catch (error) {
        console.log(error);
        notify.error("Login Failed");
        navigate("/login");
      }
    }
  };

  const backToLogin = () => navigate("/login");

  if (registerData) {
    return (
      <FinalStep handleClick={login} firstName={result?.firstName || ""} />
    );
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <LogoHeader />
      <Grid item xs={10} md={4} lg={3} marginTop={8}>
        <Stack marginBottom="30px">
          <Typography fontSize={14} fontWeight={300}>
            Enter your .edu email so we can find your profile and verify your
            identity
          </Typography>
        </Stack>

        {!showRegister && (
          <SearchStep
            result={result}
            setResult={setResult}
            setEmail={setEmail}
            setShowRegister={setShowRegister}
            backToLogin={backToLogin}
          />
        )}

        {result && showRegister && (
          <RegisterStep
            result={result}
            email={email}
            setRegisterData={setRegisterData}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AthleteSignUp;
