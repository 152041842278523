import { EDU_EMAIL_REGEX, EMAIL_REGEX } from "constant";

export function formatCurrency(x: number) {
  return x.toLocaleString("en-US", { style: "currency", currency: "USD" });
  // const dollar = (x == 0) ? x : (x / 100);
  // const res = dollar.toLocaleString("en-US", { style: "currency", currency: "USD" });
  // return res
}

export function getFormatedCurrency(x: number) {
  const res = x.toLocaleString("en-US", { style: "currency", currency: "USD" });
  return res
}


export function toBoolean(dataStr: string | boolean) {
  if (typeof dataStr === "string") {
    return !!(
      dataStr?.toLowerCase?.() === "true" || Number.parseInt(dataStr, 10) === 0
    );
  } else {
    return dataStr === true;
  }
}

export function athleteEmailValidationRegex() {
  const athlete_edu_email_check = process.env.REACT_APP_ATHLETE_EDU_EMAIL_CHECK || false;
  if (toBoolean(athlete_edu_email_check)) {
    return EDU_EMAIL_REGEX;
  } else {
    return EMAIL_REGEX;
  }
}

export const getFormatedTimeStamp = (date: string) => {
  return date;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatPhoneNumber(value: any) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}- ${phoneNumber.slice(3)}`;
  }
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatEmployeeNumber(value: any) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 2) return phoneNumber;

  if (phoneNumberLength < 3) {
    return `${phoneNumber.slice(0, 1)} - ${phoneNumber.slice(1)}`;
  }
  return `${phoneNumber.slice(0, 2)}-${phoneNumber.slice(2, 9)}`;
}


