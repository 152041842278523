/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import StreamlineImage from "../../assets/images/streamline.png";
import Typography from "@mui/material/Typography";
import ModalWrapper from "../ModalWrapper";
import { useMutation } from "@apollo/client";
import { filedPaymentResponse } from "types/createPaymentToken";
import { createFiledTokens } from "queries/createFiledToken";
import { CircularProgress } from "@mui/material";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  multi?: string | null;
  amount: number,
  tax: number,
  id: any
}

export function MemorabuilaErrorModel({ open, setOpen, amount, id }: Props) {
  const navigate = useNavigate();
  const [createPayMentToken, { loading }] = useMutation<filedPaymentResponse>(
    createFiledTokens
  );
  if (loading) { return <CircularProgress />; }


  const onGotoHome = async () => {
    const res = await createPayMentToken({
      variables: { "amount": `${"" + amount + ""}` }
    });
    localStorage.setItem("checkoutToken", `${res?.data?.getPrePaymentDetails.token}`);
    setOpen(false);
    navigate('/CheckoutDetails', { state: { athleteId: id, amount: amount, tax: res?.data?.getPrePaymentDetails.tax } });
  }
  return (
    <ModalWrapper open={open} setOpen={setOpen} dark>
      <>
        <Box marginBottom={4}>
          <Box>
            <Typography fontSize={34} fontWeight={600}>
              SOMETHING <br />DIDN’T WORK
            </Typography>
            <Typography fontSize={16} fontWeight={500}>
              Your direct payment request has been declined by your payment provider
            </Typography>
          </Box>

          <Box margin="1rem auto">
            <img
              src={StreamlineImage}
              alt="check mark"
              height={126}
              width={126}
            ></img>
            <Typography fontSize={16} fontWeight={500}>
              Try resubmitting your payment
            </Typography>
          </Box>
          {/* {athleteNotOnboarded && (
          <Typography fontSize={20} fontWeight={600}>
            You will be notified as soon as the athlete accepts your request.
          </Typography>
        )} */}
        </Box>
        <Button className="primaty_btn_custom"
          onClick={onGotoHome}
          color="secondary"
          variant="contained"
        >
          Back to Payment Screen
        </Button>
      </>
    </ModalWrapper>
  );
}
