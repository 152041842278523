import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import MNPLogo from "../../assets/images/mnp_logo.svg";
import SideNav from "../../components/Navigation/SideNav";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNav from "../Navigation/BottomNav";
import ErrorBoundary from "../../ErrorBoundary";
import { useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useNotificationsCount from "hooks/useNotificationsCount";
import { Badge, CircularProgress } from "@mui/material";
import { NOTIFICATIONS_POLLING_MS } from "constant";

const drawerWidth = 270;

interface MainProp {
  open?: boolean;
  isMobile?: boolean;
  handleClickAlerts?: () => void;
}

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isMobile",
})<MainProp>(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  position: "relative",
  minHeight: "100vh",
  padding: "10px",
  paddingTop: isMobile ? "40px" : "90px",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const Layout = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(!isMobile);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const deviceType = "DESKTOP";
  const { data, loading, startPolling } = useNotificationsCount(deviceType);
  useEffect(() => {
    if (isMobile) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }, [isMobile]);

  const handleClickAlerts = () => {
    navigate("/alerts")
  }
  useEffect(() => {
    startPolling(NOTIFICATIONS_POLLING_MS)
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      startPolling(NOTIFICATIONS_POLLING_MS);

    }, NOTIFICATIONS_POLLING_MS);

    return () => clearTimeout(timer);

  }, [])
  if (loading) return <CircularProgress />;
  return (
    <Box display={"flex"}>
      <Drawer className="left-container"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "white",
          },
        }}
        hideBackdrop={!isMobile}
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        onClose={handleDrawerClose}
        open={open}
      >
        <Box margin={1}>
          <Stack direction="row">
            <Box margin="10px">
              <img src={MNPLogo} alt="myNILpay logo" height={25} />
            </Box>
            <Box margin="10px" sx={{ position: 'relative', marginTop: 2 }}>
              {data && data?.getNonDismissedNotificationsCount > 0 ? <Box>
                <Badge badgeContent={`${data?.getNonDismissedNotificationsCount}`} color="error">
                  <NotificationsIcon onClick={handleClickAlerts} sx={{
                    color: '#592EE5',
                    fontSize: 20,
                    cursor: 'pointer',
                  }} />

                </Badge>
              </Box> : <NotificationsIcon onClick={handleClickAlerts} sx={{
                color: '#592EE5',
                fontSize: 20,
                cursor: 'pointer',
              }} />}

            </Box>
          </Stack>
          <SideNav />
        </Box>
      </Drawer>
      <Main open={isMobile || open} isMobile={isMobile}>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={6} maxWidth="800px">
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </Grid>
        </Grid>
      </Main>
      {isMobile && (
        <Box
          boxShadow={6}
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            left: 0,
          }}
        >
          <BottomNav />
        </Box>
      )}
    </Box>
  );
};

export default Layout;
