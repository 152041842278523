import React from "react";
import { ApolloError, useQuery } from "@apollo/client";
import { CircularProgress, Stack } from "@mui/material";
import { ME, MeResponse, } from "queries";
import { useNotifications } from "providers/Notification";
import Box from "@mui/material/Box";
import Form from "./Form";
import UserInfo from "./UserInfo";
import useUserProfile from "hooks/useUserProfile";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const PersonalInfo = () => {

  const notify = useNotifications();
  const { loading, data, refetch } = useQuery<MeResponse>(ME, {
    onError: (error: ApolloError) => {
      notify.error(error.message);
    },
  });
  const id = String(data?.me?.id)
  const { loading: loadingInfo, data: Info } = useUserProfile(id);
  const userType = data?.me.userType;
  const isAthlete = userType === "athlete";
  const navigate = useNavigate();
  const navigation = () => {
    navigate('requestchanges')
  }
  if (loading) return <CircularProgress />;
  if (loadingInfo) return <CircularProgress />;
  return (
    <Box>
      <Stack direction="row" alignItems="flex-start" marginBottom={2} color="secondary.dark" spacing={2}>
        Personal Information
      </Stack>
      {data && !isAthlete &&
        <Form
          {...data.me}
          birthday={moment(data.me.birthday).format('MM/DD/YYYY')}
          refetch={refetch} />}
      {data && isAthlete &&
        <UserInfo
          {...data?.me}
          jerseyNumber={Info?.userProfile.athleteSport.jerseyNumber || 0}
          athlete_class={Info?.userProfile.athleteSport.sportDetails.athlete_class || ""}
          sport={String(Info?.userProfile.athleteSport.sport.name || '')}
          position={Info?.userProfile.athleteSport.sportDetails.position || ''}
          hometown={Info?.userProfile.hometown || ''}
          highSchool={Info?.userProfile.highSchool || ''}
          school={String(Info?.userProfile.school.name || '')}
          onHandel={navigation}
        />
      }
    </Box>
  );
};

export default PersonalInfo;
