/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import React, { useState } from "react";
import Header from "components/Header";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useNotifications } from "providers/Notification";
import { useMutation } from "@apollo/client";
import { useForm, UseFormReturn } from "react-hook-form";
import BackButton from "components/BackButton/BackButton";
import { ONBOARD_ATHLETE } from "queries/athlete";
import { EMAIL_REGEX } from "constant";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { format } from "date-fns";
import { states } from "./stateData";
import DoneImage from "assets/images/done_all.png";
import background from "assets/images/SignUp-Successv2.png";
import moment from "moment";
import { formatPhoneNumber } from "utils";
// /home/PayoutInfo

interface PersonalDetailsFormValues {
  firstName: string;
  lastName: string;
  address: string;
  zip: string;
  dob: string;
  personalIdentificationNumber: string;
  email: string;
  payoutType: string;
  bankAccountType: string;
  nameOnAccount: string;
  bankName: string;
  bankAccountClass: string;
  phone: string;
  bankId: string;
  state: string;
  city: string;
  bankAddress: string;
  bankZip: string;
  bankAccountId: string;
}

function PersonalDetails() {
  const notify = useNotifications();
  const [step, setStep] = useState(1);
  const [onboardAthlete] = useMutation(ONBOARD_ATHLETE);
  const [LoginUserObject] = useState(JSON.parse(localStorage.getItem('LoginUserObject') || ""))
  const form = useForm<PersonalDetailsFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      address: "",
      zip: "",
      dob: "",
      phone: "",
      personalIdentificationNumber: "",
      email: (LoginUserObject.email || ""),
      payoutType: "",
      bankAccountType: "",
      nameOnAccount: "",
      bankName: "",
      bankAccountClass: "",
      bankId: "",
      state: "",
      city: "",
      bankAddress: "",
      bankZip: "",
      bankAccountId: "",
    },
    mode: "all",
  });

  const onSubmit = async (FormObject: PersonalDetailsFormValues) => {
    FormObject.firstName = (FormObject.firstName).trim()
    FormObject.lastName = (FormObject.lastName).trim()
    FormObject.phone = (FormObject.phone).trim()
    FormObject.address = (FormObject.address).trim()
    FormObject.nameOnAccount = (FormObject.nameOnAccount).trim()
    FormObject.bankName = (FormObject.bankName).trim()
    FormObject.city = (FormObject.city).trim()
    FormObject.bankAddress = (FormObject.bankAddress).trim()
    FormObject.dob = moment(FormObject.dob).format('DD-MM-YYYY');
    if (FormObject.dob == "Invalid date") {
      form.setValue('dob', "", { shouldValidate: false, shouldTouch: false })
      form.setError("dob", { type: "focus", message: 'Invalid Date Of Birth' }, { shouldFocus: true })
      notify.error("Invalid Date Of Birth");
      setStep(1)
    } else {
      try {
        const response = await onboardAthlete({
          variables: {
            data: FormObject,
          },
        });
        if (response.data.onboardAthlete.status > 300) {
          try {
            const responsAry: [{ code: string, errorName: string, description: string }] = JSON.parse(response.data.onboardAthlete.data) || []
            const errorMessage = `${response.data.onboardAthlete.status + " : " + responsAry[responsAry.length - 1].description}`
            notify.error(errorMessage);
          } catch (e) {
            const responsAry: { status: string, data: string } = response.data.onboardAthlete
            const errorMessage = `${responsAry.status + " : " + responsAry.data}`
            notify.error(errorMessage);
            if (responsAry.data == 'User already onboarded with BlueSnap account') {
              window.location.replace('/home')
            }
          }
        } else {
          if (response) {
            setStep(3);
          }
        }
      } catch (error) {
        notify.error(error);
      }
    }
  };
  const props = {
    form,
    setStep,
  };
  if (step === 3) {
    return (
      <Stack spacing={2} sx={{
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        zIndex: "0",
        paddingTop: "70px"
      }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{ color: '#fff', fontSize: "2rem" }}
        >{`Welcome ${form.getValues('firstName')}!`}</Typography>
        <Typography sx={{ color: '#fff' }}>You’re all set!</Typography>

        <Box margin="auto">
          <img src={DoneImage} alt="check mark" height={126} width={126}></img>
        </Box>
        <Typography sx={{ color: '#fff' }} fontSize={16} fontWeight={600}>
          You can now begin accepting support and payments from your fans
        </Typography>

        <Typography sx={{ color: '#fff' }} fontSize={16} fontWeight={600}>
          Once a payment is authorized, it will take approximately 48-72 hours for the funds to be deposited to your account.
        </Typography>
        <Box padding={4}>
          <Button variant="contained" href="/home" sx={{ background: "#FFF", color: 'primary.dark', width: '400px', ":hover": { backgroundColor: "#f0ebff" } }} fullWidth >
            Let’s go!
          </Button>
        </Box>
      </Stack>
    );
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
      {step === 1 && (
        <Step1 {...props} />
      )}
      {step === 2 && (
        <>
          <BackButton onClick={() => setStep(1)} />
          <Step2 {...props} />
        </>

      )}
    </form>
  );
}
interface FormStepProps {
  form: UseFormReturn<PersonalDetailsFormValues>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const Step1 = ({ form, setStep }: FormStepProps) => {
  // const navigation = userNavigation()
  const [dateValue, setDateValue] = React.useState<unknown>(form.getValues('dob') || "");
  const { isDirty, errors, isSubmitting, touchedFields } = form.formState;
  const [zipCode, setZipCode] = useState(form.getValues('zip') || "")
  const [firstName, setFirstName] = useState(form.getValues('firstName') || "")
  const [lastName, setLastName] = useState(form.getValues('lastName') || "")
  const [LastFourDigits, setLastFourDigits] = useState(form.getValues('personalIdentificationNumber') || "")
  const EmailEditable = (process.env.REACT_APP_ATHLETE_ONBORDING_EMAIL_EDITABLE || "true") === "true" ? false : true;
  const [phone, setPhoneNumber] = useState(form.getValues('phone') || "")
  const handleChangeZip = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value.length <= 5) {
        setZipCode(e.target.value);
      }
    }
  }
  const handleChangeLastFourDigits = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value.length <= 4) {
        setLastFourDigits(e.target.value);
      }
    }
  }
  const isFirstStepValid = () => {
    const noErrors = () => {
      const { firstName,
        lastName,
        address,
        zip,
        dob,
        phone,
        personalIdentificationNumber,
        email, } = errors;
      return !firstName && !lastName && !address && !zip && !dob && !phone && !email && !personalIdentificationNumber;
    };
    const touchedAllFields = () => {
      const { firstName,
        lastName,
        address,
        zip,
        phone,
        dob,
        personalIdentificationNumber } = touchedFields;
      return firstName && lastName && address && zip && dob && phone && personalIdentificationNumber;
    };
    return Boolean(
      noErrors() && touchedAllFields()
    );

  }
  const handleChangePhoneNumber = (e: any) => {
    const arr = Array.from(e.target.value)
    if (arr[0] !== "0") {
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      setPhoneNumber(formattedPhoneNumber);
    }
  };
  return (
    <Box className="ath-personal-details">
      <Header text="Personal Details" />
      <Box sx={{ mt: 4 }} textAlign={"left"} className="personal-details__form">
        <Stack sx={{ mt: 2 }} direction="column" alignItems="flex-start">
          <TextField
            margin="dense"
            label="First Name"
            placeholder="First Name"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            value={firstName}
            inputProps={{
              "data-testid": "firstName",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.firstName)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.firstName?.message) || " "}
            {...form.register("firstName", {
              required: "First Name is required",
              minLength: { value: 2, message: "First Name should be minimum 2 or above characters." },
              maxLength: { value: 50, message: "First Name should be maximum 50 or below characters." }
            })}
            onChange={(e) => {
              const regex = /^[A-z\s]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                setFirstName(e.target.value);
              }
            }}
          />
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Last Name"
            label="Last Name"
            placeholder="Last Name"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            value={lastName}
            inputProps={{
              "data-testid": "lastName",
              form: {
                autocomplete: 'off',
              }
            }}
            error={isDirty && Boolean(errors.lastName)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.lastName?.message) || " "}
            {...form.register("lastName", {
              required: "Last Name is required",
              minLength: { value: 2, message: "Last Name should be minimum 2 or above characters." },
              maxLength: { value: 50, message: "Last Name should be maximum 50 or below characters." }
            })}
            onChange={(e) => {
              const regex = /^[A-z\s]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                setLastName(e.target.value);
              }
            }}
          />
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Address"
            label="Address"
            placeholder="Address"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            inputProps={{
              "data-testid": "address",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.address)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.address?.message) || " "}
            {...form.register("address", {
              required: "Address is required"
            })}
          />
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="zip"
            label="Zip Code"
            placeholder="zip"
            InputLabelProps={{
              shrink: true,
            }}
            value={zipCode}
            type="text"
            fullWidth
            inputProps={{
              "data-testid": "zip",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.zip)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.zip?.message) || " "}
            {...form.register("zip", {
              required: "Zip Code is required",
              minLength: { value: 5, message: "Please enter 5 minimum digits number" }
            })}
            onChange={(e) => handleChangeZip(e)}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat={"DD-MM-YYYY"}
              // onError={() => isDirty && Boolean(errors.birthday)}
              disabled={isSubmitting}
              value={dateValue}
              minDate={"1921-05-01"}
              maxDate={format(new Date(), "yyyy-MM-dd")}
              onChange={(newValue: any) => {
                setDateValue(newValue);
                form.setValue('dob', format(new Date(newValue), "MM-dd-yyyy"), { shouldValidate: true, shouldTouch: true })
              }}
              renderInput={(params: any) => {
                return <TextField error={isDirty && Boolean(errors.dob)} label="Date of Birth" inputProps={{
                  placeholder: "DD/MM/YYYY", "data-testid": "Date of Birth",
                  readOnly: true, value: params?.inputProps.value,
                  form: {
                    autocomplete: 'off',
                  }
                }} InputProps={params.InputProps}
                  inputRef={params.inputRef}
                  {...form.register("dob", {
                    required: "Date of birth is required",
                  })}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={(isDirty && errors.dob?.message) || " "}
                />
              }}
            />
          </LocalizationProvider>
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Last four digits of social security number"
            label="Last four digits of social security number"
            placeholder="Last four digits of social security number"
            InputLabelProps={{
              shrink: true,
            }}
            value={LastFourDigits}
            type="text"
            fullWidth
            inputProps={{
              "data-testid": "personalIdentificationNumber",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.personalIdentificationNumber)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.personalIdentificationNumber?.message) || " "}
            {...form.register("personalIdentificationNumber", {
              required: "Last four digits of social security number is required"
            })}
            onChange={handleChangeLastFourDigits}
          />
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Email"
            label="Email"
            placeholder="Email"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              readOnly: EmailEditable
            }}
            type="text"
            fullWidth
            inputProps={{
              "data-testid": "email",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.email)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.email?.message) || " "}
            {...form.register("email", {
              required: "Email is required",
              pattern: {
                value: EMAIL_REGEX,
                message: "Invalid email address",
              },
            })}
          />
          <TextField
            margin="dense"
            inputProps={{ "data-testid": "phone" }}
            label="Phone Number"
            placeholder="XXX-XXX-XXXX"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            value={phone}
            fullWidth
            error={Boolean(isDirty && errors.phone)}
            disabled={isSubmitting}
            helperText={
              (isDirty && errors.phone?.message) || " "
            }
            {...form.register("phone", {
              required: "Phone number required",
              minLength: {
                value: 12,
                message: "Phone number should be 10 digits"
              },
            })}
            onChange={(e) => handleChangePhoneNumber(e)}
          />
        </Stack>
      </Box>
      <Button sx={{ mt: 8 }}
        type="button"
        fullWidth
        color={"primary"}
        variant="contained"
        onClick={() => setStep(2)}
        disabled={!isFirstStepValid()}
      >
        Next
      </Button>
    </Box>
  )
}
const Step2 = ({ form }: FormStepProps) => {
  const { isDirty, errors, isSubmitting, isValid } = form.formState;
  const [zipCode, setZipCode] = useState(form.getValues('bankZip') || "")
  const [nameOnAccount, setNameOnAccount] = useState(form.getValues('nameOnAccount') || "")
  const [bankName, setBankName] = useState(form.getValues('bankName') || "")
  const [city, setCity] = useState(form.getValues('city') || "")
  const [bankAccountId, setBankAccountId] = useState(form.getValues('bankAccountId') || "")
  const [bankId, setBankId] = useState(form.getValues('bankId') || "")
  const handleChangeZip = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value.length <= 5) {
        setZipCode(e.target.value);
      }
    }
  }
  return (
    <Box className="ath-personal-details">
      <Header text="Payout info" />
      <Box sx={{ mt: 4 }} textAlign={"left"} className="personal-details__form">
        <Stack sx={{ mt: 2 }} direction="column" alignItems="flex-start">
          <TextField sx={{ mt: 3 }}
            select
            margin="dense"
            data-testid="NameOnAccount"
            label="Payout Type"
            placeholder="Payout Type"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            defaultValue={form.getValues('payoutType') || ''}
            inputProps={{
              "data-testid": "payoutType",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.payoutType)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.payoutType?.message) || " "}
            {...form.register("payoutType", {
              required: "Payout Type is required"
            })}
          >
            <MenuItem value={'ACH'}>ACH</MenuItem>
            {/* <MenuItem value={'CHAPS'}>CHAPS</MenuItem>
            <MenuItem value={'FAST'}>FAST</MenuItem>
            <MenuItem value={'SEPA'}>SEPA</MenuItem>
            <MenuItem value={'WIRE'}>WIRE</MenuItem> */}
          </TextField>
          <TextField sx={{ mt: 3 }}
            select
            margin="dense"
            data-testid="NameOnAccount"
            label="Bank Account Type"
            placeholder="Bank Account Type"
            defaultValue={form.getValues('bankAccountType') || ''}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            inputProps={{
              "data-testid": "bankAccountType",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.bankAccountType)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.bankAccountType?.message) || " "}
            {...form.register("bankAccountType", {
              required: "Bank Account Type is required"
            })}
          >
            <MenuItem value={'CHECKING'}>CHECKING</MenuItem>
            <MenuItem value={'SAVINGS'}>SAVINGS</MenuItem>
          </TextField>
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="NameOnAccount"
            label="Name On Account"
            placeholder="Name On Account"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            value={nameOnAccount}
            inputProps={{
              "data-testid": "nameOnAccount",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.nameOnAccount)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.nameOnAccount?.message) || " "}
            {...form.register("nameOnAccount", {
              required: "Name On Account is required"
            })}
            onChange={(e) => {
              const regex = /^[A-z\s]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                setNameOnAccount(e.target.value);
              }
            }}
          />
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="BankName"
            label="Bank Name"
            placeholder="Bank Name"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            value={bankName}
            inputProps={{
              "data-testid": "bankName",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.bankName)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.bankName?.message) || " "}
            {...form.register("bankName", {
              required: "Bank Name is required"
            })}
            onChange={(e) => {
              const regex = /^[A-z\s]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                setBankName(e.target.value);
              }
            }}
          />
          <TextField sx={{ mt: 3 }}
            select
            margin="dense"
            data-testid="BankAccountClass"
            label="Bank Account Class"
            placeholder="Bank Account Class"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            defaultValue={form?.getValues('bankAccountClass') || ''}
            inputProps={{
              "data-testid": "bankAccountClass",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.bankAccountClass)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.bankAccountClass?.message) || " "}
            {...form.register("bankAccountClass", {
              required: "Bank Account Class is required"
            })}
          >
            <MenuItem value={'PERSONAL'}>PERSONAL</MenuItem>
            <MenuItem value={'CORPORATE'}>CORPORATE</MenuItem>
          </TextField>
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="BankId"
            label="Routing Number"
            placeholder="Routing Number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={bankId}
            inputProps={{
              "data-testid": "bankId",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.bankId)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.bankId?.message) || " "}
            {...form.register("bankId", {
              required: "Routing Id is required",
              minLength: { value: 9, message: "Please Enter 9 minimum number" },
            })}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                if (e.target.value.length < 10) {
                  setBankId(e.target.value);
                }
              }
            }}
          />
          <TextField sx={{ mt: 3 }}
            select
            margin="dense"
            data-testid="State"
            label="State"
            placeholder="State"
            InputLabelProps={{
              shrink: true
            }}
            type="text"
            fullWidth
            defaultValue={form?.getValues('state') || ''}
            inputProps={{
              "data-testid": "state",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.state)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.state?.message) || " "}
            {...form.register("state", {
              required: "State is required"
            })}
          >
            {states()?.map((val, key) =>
              <MenuItem key={key} value={val.value}>{val.name}</MenuItem>
            )}
          </TextField>
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="City"
            label="City"
            placeholder="City"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            value={city}
            inputProps={{
              "data-testid": "city",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.city)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.city?.message) || " "}
            {...form.register("city", {
              required: "City is required"
            })}
            onChange={(e) => {
              const regex = /^[A-z\s]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                setCity(e.target.value);
              }
            }}
          />
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Address"
            label="Bank Address"
            placeholder="Bank Address"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            inputProps={{
              "data-testid": "bankAddress",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.bankAddress)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.bankAddress?.message) || " "}
            {...form.register("bankAddress", {
              required: "Bank Address is required"
            })}
          />
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Zip"
            label="Bank Zip Code"
            placeholder="Zip"
            InputLabelProps={{
              shrink: true,
            }}
            value={zipCode}
            type="text"
            fullWidth
            inputProps={{
              "data-testid": "bankZip",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.bankZip)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.bankZip?.message) || " "}
            {...form.register("bankZip", {
              required: "Bank Zip Code is required",
              minLength: { value: 5, message: "Please Enter 5 minimum number" }
            })}
            onChange={(e) => handleChangeZip(e)}
          />
          <TextField sx={{ mt: 3 }}
            margin="dense"
            data-testid="Bank Account Id"
            label="Bank Account Id"
            placeholder="Bank Account Id"
            InputLabelProps={{
              shrink: true,
            }}
            value={bankAccountId}
            fullWidth
            inputProps={{
              "data-testid": "bankAccountId",
              form: {
                autocomplete: 'off',
              }
            }}
            error={Boolean(isDirty && errors.bankAccountId)}
            disabled={isSubmitting}
            helperText={(isDirty && errors.bankAccountId?.message) || " "}
            {...form.register("bankAccountId", {
              required: "Bank Account Id is required",
              minLength: { value: 9, message: "Please enter 9 minimum digits number" },
              maxLength: { value: 34, message: "Please enter 34 max digits number" }

            })}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                setBankAccountId(e.target.value);
              }
            }}
          />
        </Stack>
      </Box>
      <Button sx={{ mt: 8 }}
        type="submit"
        fullWidth
        color={"primary"}
        variant="contained"
        disabled={!isValid || isSubmitting}
      >
        Submit Request
      </Button>
    </Box>
  )
}
export default PersonalDetails;

