import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const PaymentAccount = () => {
  const navigationn = useNavigate()
  const [loading] = useState(false);



  return (
    <Box marginTop={{ xs: "10px", md: "30px" }} padding="20px">
      <Stack textAlign="left">
        <Typography fontSize="20px" fontWeight="600">
          Your Payment Receiving Account
        </Typography>
        <Typography fontSize="14px" fontWeight="300">
          This is the account that will receive all payments from fans in the
          app
        </Typography>
        <Button
          onClick={() => navigationn('/home/PersonalDetails')}
          disabled={loading}
          variant="outlined"
          sx={{
            width: "100%",
            maxWidth: "400px",
            padding: "40px",
            marginTop: "40px",
            borderRadius: "18px",
            borderWidth: "2px",
            "&:hover": {
              borderWidth: "2px",
            },
          }}
        >
          <Stack>
            <Typography fontSize="80px">+</Typography>

            <Typography fontSize="20px">
              {loading && <CircularProgress size={20} />} Add Account
            </Typography>
          </Stack>
        </Button>
      </Stack>
    </Box>
  );
};

export default PaymentAccount;
