import { useMutation } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useNotifications } from "providers/Notification";
import { TRANSACTION_INVOICE } from "queries";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { InvoiceArgs, InvoiceResponse } from "types";

const Invoice = () => {
    const notify = useNotifications();
    const params = useParams();
    const transactionId = params.id
    const [retrieveReceipt, { data, loading }] =
        useMutation<InvoiceResponse, InvoiceArgs>(TRANSACTION_INVOICE, {
            onError: () => notify.error("Something went wrong. Try again later"),
        });
    useEffect(() => {
        if (transactionId) {
            retrieveReceipt({ variables: { transactionId } });
        }
    }, [transactionId]);
    if (loading) { return <CircularProgress />; }
    console.log("data", data)

    return (
        <>
            {data ?
                <Box className="payment-receipt">
                    <Box textAlign={"center"}>
                        <h2>Receipt From myNILpay, Inc.</h2>
                    </Box>
                    <Box className="payment-receipt__details" sx={{ mt: 3 }} justifyContent={"space-between"} display={"flex"} textAlign={"center"}>
                        <Box>
                            <strong>Amount Paid:</strong>
                            <small>${data?.retrieveReceipt.amountWithoutTax}</small>
                        </Box>
                        <Box>
                            <strong>Date Paid:</strong>
                            <small>{data?.retrieveReceipt.transactionApprovalDate}</small>
                        </Box>
                        <Box>
                            <strong>Payment Method:</strong>
                            <small>Card-{data?.retrieveReceipt.cardLastFourDigits}</small>
                        </Box>
                    </Box>
                    <Box className="payment-receipt__summary" sx={{ mt: 3 }}>
                        <strong>Summary</strong>

                    </Box>
                    <Box sx={{ mt: 2 }} className="payment-receipt__amount">
                        <Box justifyContent={"space-between"} display={"flex"}>
                            <span>Payment x {data?.retrieveReceipt.athleteCount}</span>
                            <span>${data?.retrieveReceipt.amountWithoutTax}</span>
                        </Box>
                        <Box sx={{ mt: 1 }} justifyContent={"space-between"} display={"flex"}>
                            <span>State Sales Tax</span>
                            <span>${data?.retrieveReceipt.tax}</span>
                        </Box>
                        <Divider sx={{ mt: 1 }} />
                        <Box sx={{ mt: 1 }} justifyContent={"space-between"} display={"flex"}>
                            {data?.retrieveReceipt.cardTransactionType === "SUCCESSFUL" ?
                                <strong>Amount Charged</strong> : <strong>Refund Amount</strong>}
                            <strong>${data?.retrieveReceipt.amountWithTax}</strong>
                        </Box>
                    </Box>
                    <Box className="payment-receipt__footer" textAlign={"center"}>
                        <p>If you have any questions, visit our support site at <a target={"_new"} href="https://www.mynilpay.com/support">https://www.mynilpay.com/support</a></p>
                        <p>Contact us at <a href="mailto:${EMAIL}?subject=General Support">Support@mynilpay.com</a> Or call at <a href="#">+1 510-369-5137</a></p>
                    </Box>
                </Box>
                : <p>Something went wrong. Try again later</p>}
        </>
    )
}
export default Invoice;