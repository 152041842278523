import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { SignInUserSession } from "types";
import { useNavigate } from "react-router-dom";
import { Provider } from "./SSOIcons";

export const SSO = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((response) => {
        const signInUserSession: SignInUserSession =
          response?.signInUserSession;

        const token = signInUserSession?.idToken?.jwtToken;
        localStorage.setItem("token", token);
        navigate("/home");
      })
      .catch((error) => {
        // NOTE: there is a known issue with Cognito SSO
        // the first time a user registers will always throw an error
        // the work around is to call again to bypass this error
        // The following ticket has all the information about it
        // https://mynilpay.atlassian.net/browse/MNP-227
        if (error == "The user is not authenticated") {
          const provider = localStorage.getItem(
            "cognitoProvider"
          ) as Provider | null;

          if (provider) {
            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider[provider],
            });
          }
        }
      });
  }, []);

  return <></>;
};
