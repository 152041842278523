import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import FanHome from './FanHome';
import { useNotifications } from "providers/Notification";
function FanHomePage() {
  const params = useParams();
  const notify = useNotifications()
  const navigate = useNavigate()
  const onGotoAthelete = () => {
    navigate('/fanhome');
    localStorage.setItem('setClearData', "clearData")

    // localStorage.setItem('transactionStatus', "")

  }
  useEffect(() => {
    localStorage.setItem("errCode", "");
    if (params.errorCode == "200") {
      notify.error("Something went wrong. Try again later");
    } else if (params.errorCode == "100") {
      notify.error("Payment Session expired");
    }
  }, [params])

  return (
    <>
      <Box>
        <FanHome onGotoAthelete={onGotoAthelete} />
      </Box>
    </>
  );
}

export default FanHomePage;
