import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import { SportCard } from "components/SportCard/SportCard";
import { SPORTS_BY_SCHOOL } from "queries";
import { Sport, SportsBySchoolResponse } from "types";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import Header from "components/Header";
import { useSchool } from "hooks/useSchool";

export function School() {
  const params = useParams();
  const navigate = useNavigate();
  const schoolId = params.schoolId || "";
  const school = useSchool(schoolId);

  const { loading, data } = useQuery<SportsBySchoolResponse>(SPORTS_BY_SCHOOL, {
    variables: { schoolId },
  });

  const handleClick = (sport: Sport) => {
    navigate(`sport/${sport.id}`);
  };

  return (
    <Box>
      <Header text={school?.name || "Loading..."} />
      {loading && <CircularProgress />}
      {!loading &&
        data &&
        data.sportsBySchool.map((sport) => (
          <SportCard key={sport.id} sport={sport} handleClick={handleClick} />
        ))}

      {data && !data.sportsBySchool.length && (
        <Typography>No teams found</Typography>
      )}
    </Box>
  );
}
