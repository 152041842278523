import React from "react";
import { LogoHeader } from "./LogoHeader";
import { useNavigate } from "react-router-dom";
import AthleteIcon from "assets/icons/athlete_icon.svg";
import Button from "@mui/material/Button";
import FanIcon from "assets/icons/fan_icon.svg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface CardButtonProp {
  onClick: () => void;
  imgSrc: string;
  title: string;
  description: string;
}

const CardButton = ({
  onClick,
  imgSrc,
  title,
  description,
}: CardButtonProp) => (
  <Button
    onClick={onClick}
    sx={{
      flex: 1,
      border: "solid 1px #091F82",
      borderRadius: "8px",
      maxWidth: "400px",
      minWidth: "300px",
      height: { xs: "275px", sm: "400px" },
      margin: { xs: "30px", sm: "30px" },
    }}
  >
    <Stack
      padding="24px"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <img src={imgSrc} alt="user icon" height={60} width={60} />

      <Typography fontSize={{ xs: 20, sm: 30 }} fontWeight={600}>
        {title}
      </Typography>
      <Typography
        fontSize={{ xs: 12, sm: 18 }}
        fontWeight={300}
        color="text.primary"
      >
        {description}
      </Typography>
    </Stack>
  </Button>
);

const UserTypeSelection = () => {
  const navigate = useNavigate();
  const navigateToFan = () => {
    navigate("/register/fan");
    localStorage.setItem("fanBirthDate", "")
  }
  const navigateToAthelete = () => {
    navigate("/register/athlete");
    localStorage.setItem("fanBirthDate", "")

  }
  return (
    <>
      <LogoHeader />
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
      >
        <CardButton
          onClick={navigateToFan}
          imgSrc={FanIcon}
          title={"I'm a Fan or Business"}
          description={"Send money or sponsorship requests to an NCAA athlete"}
        />
        <CardButton
          onClick={navigateToAthelete}
          imgSrc={AthleteIcon}
          title={"I'm an NCAA Athlete"}
          description={
            "Receive money or sponsorship offers from fans and businesses"
          }
        />
      </Stack>
    </>
  );
};

export default UserTypeSelection;
