import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ModalWrapper from "../ModalWrapper";
import { TextField, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { DELETE_ACCOUNT, ME, MeResponse } from "../../queries";
import { useNotifications } from "../../providers/Notification";
import { useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteAccountModal = ({ open, setOpen }: Props) => {
  const notify = useNotifications();
  const navigate = useNavigate();
  const [deleteAccount] = useMutation(DELETE_ACCOUNT);

  const { loading, data } = useQuery<MeResponse>(ME, {
    onError: (error: ApolloError) => {
      notify.error(error.message);
    },
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting, isValid },
  } = useForm<{ email: string }>({
    defaultValues: {
      email: "",
    },
    mode: "all",
  });

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async () => {
    try {
      const response = await deleteAccount();
      if (response) {
        notify.success("Account Deleted Successfully");
        window.localStorage.removeItem("token");
        return navigate("/login", { replace: true });
      }
      notify.error("Something went wrong. Try again later");
    } catch (error) {
      notify.error(error);
    }
  };

  if (!data || loading) return <></>;

  return (
    <ModalWrapper open={open} setOpen={setOpen} onClose={handleClose}>
      <>
        <Stack spacing={4}>
          <Typography
            fontSize={20}
            fontWeight={600}
            lineHeight={"32px"}
            marginTop={"60px"}
          >
            Are you sure you want to delete your account?
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={300}
            color="text.primary"
            marginTop={"24px"}
            marginBottom={"54px"}
          >
            This action cannot be undone. Your account will be permanently deleted.
          </Typography>
        </Stack>
        <Stack spacing={2} marginTop={4}>
          <Typography
            fontSize={14}
            fontWeight={500}
            color="text.primary"
            textAlign="left"
          >
            Enter your email to confirm this action
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              autoFocus
              margin="dense"
              inputProps={{ "data-testid": "email" }}
              label="Email"
              placeholder="Enter email"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              fullWidth
              error={Boolean(isDirty && errors.email)}
              helperText={(isDirty && errors.email?.message) || " "}
              {...register("email", {
                required: "Email is required",
                validate: (value) => {
                  return (
                    value.toLowerCase() === data.me.email.toLowerCase() ||
                    "Email does not match"
                  );
                },
              })}
            />
            <Typography
              fontSize={14}
              fontWeight={600}
              color="primary.dark"

              marginTop={2}
              marginBottom={2}
            >
              myNILpay will validate and
              <br />
              complete your request
            </Typography>

            <Button
              fullWidth
              sx={{
                borderRadius: "46px",
                padding: 2,
              }}
              data-testid="submitButton"
              variant="contained"
              color="error"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Delete Account
            </Button>
          </form>
        </Stack>
      </>
    </ModalWrapper>
  );
};

export default DeleteAccountModal;
