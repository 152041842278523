import React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { EMAIL_REGEX } from "constant";
import { useMutation } from "@apollo/client";
import { requestPersonalInfoChange } from "queries";
import { useNotifications } from "providers/Notification";

export interface ConfirmRequestPersonalInfoResponse {
  requestPersonalInfoChange: boolean
}

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  nickname: string;
  jerseyNumber: number;
  sport: string;
  position: string;
  school: string;
  athlete_class: string;
  highSchool: string;
  homeTown: string;
  onHandler: () => void;
}

interface personalInfoProps {
  firstName: string;
  lastName: string;
  email: string;
  nickname: string;
  jersyNumber: number;
  sport: string;
  position: string;
  school: string;
  class: string;
  highSchool: string;
  homeTown: string;
  comment: string;
}


const UserInfo = ({ firstName, lastName, email, jerseyNumber, sport, position, school, athlete_class, highSchool, homeTown, onHandler }: Props) => {

  const form = useForm<personalInfoProps>({
    mode: 'onChange',
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      jersyNumber: jerseyNumber,
      sport: sport,
      position: position,
      school: school, class: athlete_class,
      homeTown: homeTown,
      highSchool: highSchool == "" ? undefined : highSchool,
      comment: undefined
    }
  })
  const notify = useNotifications();
  const [reqPersonalInfoChang] = useMutation<ConfirmRequestPersonalInfoResponse, personalInfoProps>(requestPersonalInfoChange);
  const onSubmit = async (form: personalInfoProps) => {
    try {
      const result = await reqPersonalInfoChang({
        variables: {
          ...form,
        },
      })
      if (!result) {
        notify.error("Something went wrong. Try again later");
      }
      if (result.data?.requestPersonalInfoChange == true) {
        notify.success("Your Request Changes has been Submitted");
        onHandler()
      } else {
        notify.info("Your Request Changes Failed!")
      }
    } catch (error) {
      notify.error(error);
    }

  };

  const { isDirty, errors, isSubmitting, isValid } = form.formState;
  return (
    <Box
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
    >
      <form onSubmit={form.handleSubmit(onSubmit)} >
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <Stack display={'flex'} position={'relative'} >
            <Typography position={'absolute'} left={76} color='red'>*</Typography>
            <TextField
              inputProps={{ "data-testid": "firstName" }}
              disabled={isSubmitting}
              label="First Name"
              type="text"
              margin="dense"
              variant="standard"
              error={Boolean(isDirty && Boolean(errors.firstName))}
              helperText={(isDirty && errors.firstName?.message) || " "}

              fullWidth
              {...form.register("firstName", {
                required: "First Name is required",
              })}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
          <Stack display={'flex'} position={'relative'} >
            <Typography position={'absolute'} left={76} color='red'>*</Typography>

            <TextField
              inputProps={{ "data-testid": "lastName" }}
              disabled={isSubmitting}
              margin="dense"
              label="Last Name"
              type="text"
              variant="standard"
              fullWidth
              helperText={(isDirty && errors.lastName?.message) || " "}

              error={Boolean(isDirty && Boolean(errors.lastName))}

              {...form.register("lastName", {
                required: "Last Name is required",
              })}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </Stack>
        <Stack>
          <TextField
            inputProps={{ "data-testid": "email" }}
            disabled
            margin="dense"
            label="Email"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(isDirty && Boolean(errors.email))}
            helperText={(isDirty && errors.email?.message) || " "}
            {...form.register("email", {
              required: "Email is required",
              pattern: {
                value: EMAIL_REGEX,
                message: "Invalid email address",
              },
            })}
            variant="standard"
            fullWidth
          />
        </Stack>
        <br />
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          Athlete Information
        </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <TextField
            inputProps={{ "data-testid": "school" }}
            disabled={isSubmitting}
            margin="dense"
            label="School"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}

            {...form.register("school")}
            variant="standard"
            fullWidth
          />
          <TextField
            inputProps={{ "data-testid": "sport" }}
            disabled={isSubmitting}
            margin="dense"
            label="Sport"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            {...form.register("sport")}
            variant="standard"
            fullWidth
          />
        </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <TextField
            inputProps={{ "data-testid": "position" }}
            disabled={isSubmitting}
            margin="dense"
            label="Position"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            {...form.register("position")}
            variant="standard"
            fullWidth
          />
          <TextField
            inputProps={{ "data-testid": "jerseyNumber" }}
            disabled={isSubmitting}
            margin="dense"
            label="Jersey Number"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            {...form.register("jersyNumber")}
            variant="standard"
            fullWidth
          />
        </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <TextField
            inputProps={{ "data-testid": "athlete_class" }}
            disabled={isSubmitting}
            margin="dense"
            label="Class"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            {...form.register("class")}
            variant="standard"
            fullWidth
          />
          <TextField
            inputProps={{ "data-testid": "hometown" }}
            disabled={isSubmitting}
            margin="dense"
            label="Home Town"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            {...form.register("homeTown")}
            variant="standard"
            fullWidth
          />
        </Stack>
        <Stack>
          <TextField
            inputProps={{ "data-testid": "highSchool" }}
            disabled={isSubmitting}
            margin="dense"
            label="High School"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            {...form.register("highSchool")}
            variant="standard"
            fullWidth
          />
        </Stack>
        <Stack display={'flex'} position={'relative'} >
          <Typography position={'absolute'} left={72} color='red'>*</Typography>
          <TextField
            inputProps={{ "data-testid": "comment" }}
            disabled={isSubmitting}
            margin="dense"
            label="Comment"
            type="textarea"
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(isDirty && Boolean(errors.comment))}
            helperText={(isDirty && errors.comment?.message) || " "}
            {...form.register("comment", {
              required: "Comment is Required",
            })}
            variant="standard"
            fullWidth
          />
        </Stack>

        <Button
          data-testid={"requestbutton"}
          style={{ width: "400px" }}
          type="submit"
          variant="contained"
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          disabled={!isValid || isSubmitting}
        >
          Submit Request
        </Button>

      </form>
    </Box>

  )
};

export default UserInfo;
