/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { format } from "date-fns";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Button, CircularProgress } from "@mui/material";
import { useMutation } from "@apollo/client";
import { ADMIN_ImageUpload } from "queries";
import { ImageUploadResponse, ImageUploadArgs } from "types"
import { useNotifications } from "providers/Notification";
import { apolloClient } from "providers/Apollo";
import ImageDataList from "./imageDataList";

interface FormSumbitValues {
    imageUrl: string;
    screenValue: string;
    startDate: string;
    endDate: string;
    carouselImage: string;
    groupValue: string;
}

function ImageUploadForm() {
    const notify = useNotifications();
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [startdateValidation, setStartDateValidation] = React.useState(false);
    const [imageValidation, setImageValidation] = React.useState(false);
    const [caroselValidation, setCaroselValidation] = React.useState(false);
    const [screenValidation, setScreenValidation] = React.useState(false);
    const [groupValidation, setGroupValidation] = React.useState(false);
    const [errText, setErrText] = React.useState('');
    const [file, setFile] = useState<File>();
    const [caroselfile, setCaroselFile] = useState<File>();
    const [imageUrl, setImageUrl] = useState<string>('');
    const [caroselImageUrl, setCaroselImageUrl] = useState<string>('');
    const [screenValue, setScreenValue] = useState<string>('');
    const [groupValue, setGroupValue] = useState<string>('');


    const form = useForm<FormSumbitValues>({
        defaultValues: {
            imageUrl: "",
            carouselImage: "",
            startDate: "",
            endDate: "",
            screenValue: "",
            groupValue: "",
        },
        mode: "all",
    });
    const { isDirty, errors, isSubmitting, isValid } = form.formState;
    useEffect(() => {
        if (file) {
            upload();
        }

    }, [file]);
    useEffect(() => {
        if (caroselfile) {
            caroselUpload();
        }
    }, [caroselfile])

    const [UploadImage] = useMutation<ImageUploadResponse, ImageUploadArgs>(
        ADMIN_ImageUpload
    );
    const onSubmit = async () => {
        try {
            await UploadImage({
                variables: {
                    imageUrl: imageUrl,
                    startDate: startDate,
                    expiryDate: endDate,
                    screen: screenValue,
                    carouselImage: caroselImageUrl,
                    group: groupValue
                },
            });
            notify.success("File Uploaded Successfully")
            form.reset();
            apolloClient.cache.reset();
            setStartDate('');
            setEndDate('');
        } catch (error) {
            notify.error("Upload Failed");
        }

    };

    const hadleChangeStartDate = (newValue: string | number | Date) => {
        setStartDate(format(new Date(newValue), "yyyy-MM-dd"));
        setStartDateValidation(false);
        setErrText("")

    }
    const handleChangeEndDate = (newValue: string | number | Date) => {
        setEndDate(format(new Date(newValue), "yyyy-MM-dd"));
        setStartDateValidation(false);
        setErrText("")
    }
    function uploadChange(e: any) {
        if (e.target.files) {
            setFile(e.target.files[0]);
            setImageValidation(true)
        }
    }
    function uploadCarsoleFileChange(e: any) {
        if (e.target.files) {
            setCaroselFile(e.target.files[0]);
            setCaroselValidation(true)
        }
    }
    function screenHandler(e: { target: { value: React.SetStateAction<string>; }; }) {
        if (e.target.value) {
            setScreenValue(e.target.value)
            setScreenValidation(true)
        }
    }
    function groupHandler(e: { target: { value: React.SetStateAction<string>; }; }) {
        if (e.target.value) {
            setGroupValue(e.target.value)
            setGroupValidation(true)
        }
    }
    const upload = () => {
        if (!file) {
            return;
        }
        formdata.append("file", file);
        formdata.append("type", "splash-images");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        fetch(`${process.env.REACT_APP_SERVER_URI}/file/upload`, {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            headers: myHeaders,
        }).then(
            response => response.text())
            .then(result => setImageUrl(result)
            ).catch(
        );
    };
    const caroselUpload = () => {
        if (!caroselfile) {
            return;
        }
        formdata.append("file", caroselfile);
        formdata.append("type", "splash-images");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        fetch(`${process.env.REACT_APP_SERVER_URI}/file/upload`, {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            headers: myHeaders,
        }).then(
            response => response.text())
            .then(result => setCaroselImageUrl(result)
            ).catch(
        );
    };
    const formdata = new FormData();
    const myHeaders = new Headers();
    return (<form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack direction="column" alignItems="flex-start" spacing={1}>
            <Typography marginBottom={2}>
                Upload Image
            </Typography>
            <TextField
                autoFocus
                margin="dense"
                inputProps={{ "data-testid": "imageUrl", accept: 'image/*' }}
                label="Upload Image:"
                InputLabelProps={{
                    shrink: true,
                }}
                type="file"
                fullWidth
                error={Boolean(isDirty && errors.imageUrl)}
                onChange={uploadChange}
            />
            <TextField
                autoFocus
                margin="dense"
                inputProps={{ "data-testid": "carosel", accept: 'image/*' }}
                label="Upload CarouselImage:"
                InputLabelProps={{
                    shrink: true,
                }}
                type="file"
                fullWidth
                error={Boolean(isDirty && errors.imageUrl)}
                onChange={uploadCarsoleFileChange}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    inputFormat={"DD/MM/YYYY"}
                    disabled={isSubmitting}
                    value={startDate || null}
                    onChange={(newValue: any) => {
                        hadleChangeStartDate(newValue)
                        form.setValue('startDate', format(new Date(newValue), "yyyy-MM-dd"), { shouldValidate: true })
                    }}
                    renderInput={(params: any) => {
                        return <TextField error={isDirty && Boolean(errors.startDate) || startdateValidation} label="Start Date" inputProps={{
                            placeholder: "MM/DD/YYYY", "data-testid": "startDate",
                            readOnly: true, value: params?.inputProps.value
                        }} InputProps={params.InputProps}
                            inputRef={params.inputRef}
                            {...form.register("startDate", {
                                required: "StartDate is required",
                            })}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={(isDirty && errors.startDate?.message || errText) || " "}
                        />
                    }}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    inputFormat={"DD/MM/YYYY"}
                    disabled={isSubmitting}
                    value={endDate || null}
                    minDate={format(new Date(), "yyyy-MM-dd")}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(newValue: any) => {
                        handleChangeEndDate(newValue)
                        form.setValue('endDate', format(new Date(newValue), "yyyy-MM-dd"), { shouldValidate: true })
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    renderInput={(params: any) => {
                        return <TextField
                            error={isDirty && Boolean(errors.endDate)} label="Expire Date" inputProps={{
                                placeholder: "MM/DD/YYYY", "data-testid": "endDate",
                                readOnly: true, value: params?.inputProps.value
                            }} InputProps={params.InputProps}
                            inputRef={params.inputRef}
                            {...form.register("endDate", {
                                required: "ExpireDate is required",
                            })}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={(isDirty && errors.endDate?.message) || " "}
                        />
                    }}
                />
            </LocalizationProvider>
            <TextField
                autoFocus
                margin="dense"
                inputProps={{ "data-testid": "Screen", }}
                label="Screen:"
                InputLabelProps={{
                    shrink: true,
                }}
                type="text"
                fullWidth
                error={Boolean(isDirty && errors.screenValue)}
                onChange={screenHandler}
            />
            <TextField
                autoFocus
                margin="dense"
                inputProps={{ "data-testid": "group", }}
                label="group:"
                InputLabelProps={{
                    shrink: true,
                }}
                type="text"
                fullWidth
                error={Boolean(isDirty && errors.groupValue)}
                onChange={groupHandler}
            />
            <Button
                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                data-testid="submitButton"
                type="submit"
                variant="contained"
                disabled={!isValid || isSubmitting || startdateValidation || !imageValidation || !screenValidation || !caroselValidation || !groupValidation}
            >
                Submit
            </Button>
            <div>
                <ImageDataList />
            </div>
        </Stack>
    </form>)

}

export default ImageUploadForm