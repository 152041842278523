import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { EMAIL_REGEX } from "constant";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PasswordField from "components/PasswordField";
import { SSOIcons } from "./SSOIcons";

interface LoginFormValues {
  username: string;
  password: string;
}

function LoginForm() {
  const notify = useNotifications();
  const navigate = useNavigate();
  const [rememberEmail, setRememberEmail] = useState(
    Boolean(localStorage.getItem("savedEmail"))
  );

  const { register, handleSubmit, formState, watch, control } =
    useForm<LoginFormValues>({
      defaultValues: {
        username: localStorage.getItem("savedEmail") || "",
        password: "",
      },
      mode: "all",
    });

  const usernameValue = watch("username");

  useEffect(() => {
    if (rememberEmail) {
      localStorage.setItem("savedEmail", usernameValue);
    } else {
      localStorage.removeItem("savedEmail");
    }
  }, [rememberEmail]);

  const handleRememberCheckbox = () => {
    setRememberEmail(!rememberEmail);
  };

  const onSubmit = async ({ username, password }: LoginFormValues) => {
    try {
      await Auth.signIn({ username, password });
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();

      if (token) {
        window.localStorage.setItem("token", token);
        navigate("/home", { replace: true });
      } else {
        notify.error("Login Failed");
      }
    } catch (error) {
      console.log(error);
      notify.error("Login Failed");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" alignItems="flex-start" spacing={1}>
          <Typography marginBottom={2} fontWeight="light">
            {"Please sign in with your information"}
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            data-testid="username"
            label="Email"
            placeholder="Enter email"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            error={Boolean(formState.isDirty && formState.errors.username)}
            disabled={formState.isSubmitting}
            helperText={
              (formState.isDirty && formState.errors.username?.message) || " "
            }
            {...register("username", {
              required: "Email is required",
              pattern: {
                value: EMAIL_REGEX,
                message: "Invalid email address",
              },
            })}
          />
          <Stack width="100%">
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PasswordField
                  inputProps={{ "data-testid": "password" }}
                  error={
                    formState.isDirty && Boolean(formState.errors.password)
                  }
                  disabled={formState.isSubmitting}
                  helperText={
                    (formState.isDirty &&
                      formState.errors.password?.type === "required" &&
                      "Password is required") ||
                    " "
                  }
                  {...field}
                  ref={null}
                />
              )}
            />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
              width="100%"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="remember-checkbox"
                    onChange={handleRememberCheckbox}
                    size="small"
                    checked={rememberEmail}
                  />
                }
                label={<Typography fontSize={14}>Remember Me</Typography>}
              />
              <Button
                data-testid="forgot-button"
                variant="text"
                onClick={() => navigate("/forgotPassword")}
              >
                <Typography fontSize={14}>Forgot Password</Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <SSOIcons />
        <Stack
          marginTop="100px"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          width="100%"
        >
          <Box marginBottom="24px" display="flex" alignItems="center">
            <Typography>Don&apos;t have an account?</Typography>
            <Button variant="text" onClick={() => navigate("/register")}>
              <Typography fontSize={16} fontWeight={500}>
                Register
              </Typography>
            </Button>
          </Box>
        </Stack>
        <Box>
          <Button
            startIcon={
              formState.isSubmitting ? <CircularProgress size={20} /> : null
            }
            data-testid="submit-button"
            type="submit"
            variant="contained"
            disabled={!formState.isValid || formState.isSubmitting}
            fullWidth
          >
            Log In
          </Button>
        </Box>
      </form>
    </>
  );
}

export default LoginForm;
