/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import { MeResponse, UPDATE_USER } from "queries";
import { useNotifications } from "providers/Notification";
import { formatPhoneNumber } from "utils";



interface PersonalInfo {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  birthday: string;
  zipCode: string;
  phoneNumber: string;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<MeResponse>>;

}

interface FormValues {
  firstName: string;
  lastName: string;
  birthday: string;
  zipCode: string;
  phoneNumber: string;
}


function Form({ firstName, lastName, email, birthday, zipCode, phoneNumber, refetch }: PersonalInfo) {
  const notify = useNotifications();
  const [updateUserInfo] = useMutation(UPDATE_USER);
  const { register, handleSubmit, formState, reset } = useForm<FormValues>({
    defaultValues: {
      firstName,
      lastName,
      birthday,
      zipCode,
      phoneNumber,
    },
    mode: "all",
  });
  const [phone, setPhone] = useState(phoneNumber);
  const [zip, setZip] = useState(zipCode)
  useEffect(() => {
    reset({ firstName, lastName, birthday });
  }, [firstName, lastName, birthday, zipCode, phoneNumber]);

  const { isDirty, errors, isSubmitting, isValid } = formState;

  const onSubmit = async (form: FormValues) => {
    try {
      const result = await updateUserInfo({
        variables: {
          ...form,
        },
      });
      if (result) {
        notify.success("Sucessfully Updated")
        refetch();
      } else {
        notify.error("Something went wrong. Try again later");
      }
    } catch (error) {
      notify.error(error);
    }
  };
  const handleChangePhoneNumber = (e: any) => {
    const arr = Array.from(e.target.value)
    if (arr[0] !== "0") {
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      setPhone(formattedPhoneNumber);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeZip = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value.length <= 5) {
        setZip(e.target.value);
      }
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" alignItems="flex-start" spacing={2}>
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "firstName" }}
          label="First Name"
          placeholder="Enter First Name"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          error={Boolean(isDirty && errors.firstName)}
          disabled={isSubmitting}
          helperText={(isDirty && errors.firstName?.message) || " "}
          {...register("firstName", {
            required: "First Name is required",
          })}
        />
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "lastName" }}
          label="Last Name"
          placeholder="Enter Last Name"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          error={isDirty && Boolean(errors.lastName)}
          disabled={isSubmitting}
          helperText={(isDirty && errors.lastName?.message) || " "}
          {...register("lastName", { required: "Last Name is required" })}
        />
        <TextField
          margin="dense"
          value={birthday}
          disabled
          inputProps={{ "data-testid": "birthday" }}
          label="Date of birth"
          placeholder="MM/DD/YY"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          {...register("birthday")}
        />
        <TextField
          value={email}
          disabled
          margin="dense"
          inputProps={{ "data-testid": "email" }}
          label="Email"
          placeholder="Enter email"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          helperText={" "}
        />
      </Stack>
      <TextField
        margin="dense"
        value={zip}
        inputProps={{ "data-testid": "zipcode" }}
        label="Zip Code"
        placeholder="Enter Zip Code"
        InputLabelProps={{
          shrink: true,
        }}
        type="text"
        fullWidth
        error={Boolean(isDirty && errors.zipCode)}
        disabled={isSubmitting}
        helperText={(isDirty && errors.zipCode?.message) || " "}
        {...register("zipCode", {
          required: "zipCode is required",
          minLength: { value: 5, message: "Please enter 5 minimum digits number" }
        })}
        onChange={(e) => handleChangeZip(e)}
      />
      <TextField
        margin="dense"
        value={phone}
        inputProps={{ "data-testid": "phonenumber" }}
        label="phone Number"
        placeholder="Enter phone Number"
        InputLabelProps={{
          shrink: true,
        }}
        type="text"
        fullWidth
        error={Boolean(isDirty && errors.phoneNumber)}
        helperText={(isDirty && errors.phoneNumber?.message) || " "}
        {...register("phoneNumber", {
          minLength: {
            value: 12,
            message: "Phone number should be 10 digits"
          },
        })}
        onChange={(e) => handleChangePhoneNumber(e)}
      />

      <Button
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        data-testid="submitButton"
        type="submit"
        variant="contained"
        fullWidth
        disabled={!isValid || !isDirty || isSubmitting}
      >
        Save Changes
      </Button>
    </form>
  );
}

export default Form;
