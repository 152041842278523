import React from "react";
import { IS_FAVORITE_TEAM, TOGGLE_FAVORITE_TEAM } from "queries";
import { ReactComponent as HeartEmpty } from "assets/icons/heart_empty.svg";
import { ReactComponent as HeartFull } from "assets/icons/heart_full.svg";
import { useMutation, useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const Dot = () => <span style={{ margin: "0 5px" }}>&#8226;</span>;

interface Props {
  schoolName: string;
  sportName: string;
  schoolId: string;
  sportId: string;
}

export const TeamHeader = ({
  schoolName,
  sportName,
  schoolId,
  sportId,
}: Props) => {
  const variables = { schoolId, sportId };
  const { data, refetch } = useQuery(IS_FAVORITE_TEAM, { variables });
  const [toggleFanFavoriteTeam] = useMutation(TOGGLE_FAVORITE_TEAM);

  const handleFavorite = async () => {
    await toggleFanFavoriteTeam({ variables });
    refetch();
  };

  const isFavorite = data?.isFanFavoriteTeam;

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      marginBottom={4}
    >
      <Typography color="secondary.dark" fontSize={16} fontWeight={300} marginBottom={"4px"}>
        <strong>{schoolName}</strong>
        <Dot />
        {sportName}
      </Typography>
      <Box>
        <IconButton
          onClick={handleFavorite}
          sx={{
            border: "solid 1px",
            borderColor: "error.main",
            borderRadius: "8px",
          }}
        >
          {isFavorite ? (
            <HeartFull aria-label="is favorite" />
          ) : (
            <HeartEmpty aria-label="is not favorite" />
          )}
        </IconButton>
      </Box>
    </Stack>
  );
};
