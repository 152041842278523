import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MailImage from "assets/images/mail.svg";

interface FinalStepProps {
  handleClick: () => void;
  firstName: string;
}

const FinalStep = ({ handleClick, firstName }: FinalStepProps) => {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={10} md={4} lg={3} marginTop={8}>
        <Stack spacing={4}>
          <Typography
            variant="h4"
            component="h1"
          >{`Welcome ${firstName}!`}</Typography>
          <Typography>
            You’re almost ready to start enjoying myNILpay!
          </Typography>
          <Typography>
            Simply click the link in the email you just received to verify your
            email address.
          </Typography>
          <Box margin="auto">
            <img src={MailImage} alt="mail" height={126} width={126}></img>
          </Box>
          <Button variant="contained" onClick={handleClick}>
            Continue to Sign In
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FinalStep;
