import { gql } from "@apollo/client";
import { identityVerification, UserType } from "../types";

interface Me {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  userType: UserType;
  nickname: string;
  phoneNumber: string;
  birthday: string;
  zipCode: string;
  athleteOnboarded?: boolean;
  identityVerification: null | identityVerification;
}

export interface MeResponse {
  me: Me;
}

export const ME = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
      userType
      nickname
      phoneNumber
      birthday
      zipCode
      athleteOnboarded
      identityVerification {
        status
      }
    }
  }
`;

export const MeMockResponse = {
  request: {
    query: ME,
  },
  result: {
    data: {
      me: {
        id: "123",
        firstName: "Johnny",
        lastName: "Lawrence",
        nickname: "Sensei",
        email: "Johnny@kobra.com",
        userType: "fan",
        athleteOnboarded: true,
        identityVerification: {
          status: "success",
        },
      },
    },
  },
};

export const MeMockError = {
  request: {
    query: ME,
  },
  error: new Error("An error occurred"),
};
