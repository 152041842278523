/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import Header from "components/Header";
// import FormControl from "@mui/material/FormControl";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import RadioGroup from "@mui/material/RadioGroup";
// import Radio from "@mui/material/Radio";
import { MemorabuilaErrorModel } from "components/MemorabuilaErrorModel";

import { Backdrop, CircularProgress } from "@mui/material";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
// import { checkoutInput, checkoutResponse } from "types/checkoutPayment";
import { checkoutInput, checkoutResponse, checkoutMultiAthlete, checkoutMultiAthleteResponse } from "types/checkoutPayment";
import { checkoutPayment } from "queries/checkoutPayment";
import { useNotifications } from "providers/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import { checkoutMultiAthletePayments } from "queries/multiathletecheckoutPayment";
import { ME, MeResponse } from "queries";
import { useAppState } from "providers/AppState";
const BillingContactInfo = () => {
  const notify = useNotifications();
  const location = useLocation();
  // eslint-disable-next-line
  const athleteData: any = location.state;
  const token_filed_payment = localStorage.getItem('checkoutToken');
  // const [credit, setCreditCard] = React.useState(true);
  // const [gpayToogle, setGpayToogle] = React.useState(false);
  // const [page, setPage] = React.useState<any>(null)
  const navigate = useNavigate();
  const [iframLoader, setIframeLoader] = React.useState(true)
  // eslint-disable-next-line
  const [localData, setLocalData] = React.useState<any>('');
  const appState = useAppState();
  const [memorabuilaErrorModel, setmemorabuilaErrorModel] = React.useState(false);

  const id: any = athleteData.id || '';
  const amount: number = athleteData.amount || 0;
  const tax: number = athleteData.tax || 0;
  const { data } = useQuery<MeResponse>(ME, {
    onError: (error: ApolloError) => {
      notify.error(error.message);
    },
  });
  const [setPaySingleAthlete, { loading }] = useMutation<checkoutResponse, checkoutInput>(checkoutPayment);
  const [setmultiAthletePayMents, { loading: multiAthleteLoader }] = useMutation<checkoutMultiAthleteResponse, checkoutMultiAthlete>(checkoutMultiAthletePayments);
  window.addEventListener("storage", (e) => {
    setLocalData(e.newValue)
  });
  // const onchangeCreditCard = () => {
  //   setCreditCard(true);
  //   setGpayToogle(false);
  // }
  // const onchangeGpay = () => {
  //   setIframeLoader(false)
  //   setGpayToogle(true);
  //   setCreditCard(false);
  // }


  useEffect(() => {
    const handleContextMenu = (e: any) => {
      e.preventDefault()
    }
    const onkeydownHideDeveloper = (e: any) => {
      if (e.keyCode == 123) {
        e.preventDefault()
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
        e.preventDefault()
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
        e.preventDefault()
      }
      if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
        e.preventDefault()
      }
    }
    document.addEventListener("keydown", onkeydownHideDeveloper);
    document.addEventListener("contextmenu", handleContextMenu)
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", onkeydownHideDeveloper);
    }
  }, [])

  useEffect(() => {

    if (localData === "valid") {
      const userId: any = athleteData.id || '';
      const paymentFieldTokenId: string = token_filed_payment || '';
      const amount: number = athleteData.amount || 0;
      const tax: number = Number(localStorage.getItem('checkouttax')) || 0;
      if (typeof userId === 'string' && userId !== "") {
        const setSingleAthletePayment = async () => {
          try {
            const res = await setPaySingleAthlete({
              variables: {
                athleteUserId: userId,
                paymentFieldTokenId: paymentFieldTokenId,
                paymentInfo: {
                  amount: amount,
                  tax: tax
                }
              }
            });

            if (res?.data?.purchaseMemorabilia.status === 200) {
              appState.setAmount(null);

              localStorage.setItem("success", "");
              navigate(`/fanhome?transaction=completed&id=${userId}`);
            } else {
              setmemorabuilaErrorModel(true)
            }
          } catch (error) {
            setmemorabuilaErrorModel(true)
          }
        };
        setSingleAthletePayment();
      } else {
        const setMultiAthletePayment = async () => {
          try {
            const res = await setmultiAthletePayMents({
              variables: {
                athleteUserIds: userId,
                paymentFieldTokenId: paymentFieldTokenId,
                paymentInfo: {
                  amount: amount,
                  tax: tax
                }
              }
            });

            if (res?.data?.purchaseMemorabiliaMulti.status === 200) {
              appState.setAmount(null);
              localStorage.setItem("success", "");
              navigate('/fanhome?transaction=completed&multi=true');

            } else {
              setmemorabuilaErrorModel(true)
            }
          } catch (error) {
            setmemorabuilaErrorModel(true)
          }
        };
        setMultiAthletePayment();
      }

    } else if (localData === "100" || localData === "200") {
      setmemorabuilaErrorModel(true)
    }
  }, [localData])

  { loading && <CircularProgress /> }
  { multiAthleteLoader && <CircularProgress /> }

  const hideSpinner = () => {
    setIframeLoader(false)
  }
  return (
    <Box className="billing-contact">


      {memorabuilaErrorModel && (
        <MemorabuilaErrorModel
          open={memorabuilaErrorModel}
          setOpen={setmemorabuilaErrorModel}
          amount={amount}
          id={id}
          tax={tax}
        />
      )}

      <Header text="Billing Contact Info" />
      <Box textAlign={"left"} className="billing-contact__info">
        <Box>
          <span>Name</span><strong> : {data?.me?.firstName} {data?.me?.lastName}</strong>
        </Box>
        <Box sx={{ mt: 1 }}>
          <span>Country</span><strong> : Untied States</strong>
        </Box>
        <Box sx={{ mt: 1 }}>
          <span>ZIP</span><strong> : {data?.me?.zipCode}</strong>
        </Box>
        <Box sx={{ mt: 1 }}>
          <span>phone</span><strong> : {data?.me?.phoneNumber}</strong>
        </Box>
        <Box sx={{ mt: 1 }}>
          <span>Shopper Currency</span><strong> : USD</strong>
        </Box>
      </Box>
      <Box sx={{ mt: 4 }} textAlign={"left"} className="billing-contact__payment">
        <Header text="Payment Sources" />
        {/* <FormControl>
          <RadioGroup className="billing-contact__radio"
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue="CreditCard"

          >
            <FormControlLabel value="CreditCard" control={<Radio />} label="Credit Card" onChange={onchangeCreditCard} />
            <FormControlLabel value="GooglePay" control={<Radio />} label="Google Pay" />
            <FormControlLabel value="ApplePay" control={<Radio />} label="Apple Pay" />
          </RadioGroup>
        </FormControl> */}
        <Box sx={{ marginBottom: 2 }}>Credit Card</Box>
        <Backdrop
          sx={{ color: '#592EE5', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={iframLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <iframe className="iframe-component" src={"web/cardPayment/payment.html"} id="iframeid" style={{ border: 'none' }} onLoad={hideSpinner}></iframe>
        {/* {gpayToogle && <iframe className="iframe-component" src={"web/gpay/gpayPayment.html"} id="iframeid" style={{ border: 'none' }} onChange={onchangeGpay} onLoad={hideSpinner}></iframe>} */}
      </Box>

    </Box >
  )
}
export default BillingContactInfo;