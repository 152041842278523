import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MNPLogo from "assets/images/crown_logo.svg";
import { CUSTOM_CONFIRM_SIGN_UP } from "queries";
import { ConfirmSignupArgs, ConfirmSignupResponse } from "types";
import { useMutation } from "@apollo/client";
import { Grid } from "@mui/material";
import { useNotifications } from "providers/Notification";



const ConfirmUser = () => {
    const notify = useNotifications();
    const [data, setData] = useState<string>();

    const params = new URLSearchParams(window.location.search);

    const client_id = (params.get("client_id"));
    const user_name = (params.get("user_name"));
    const confirmation_code = (params.get("confirmation_code"));

    const [ConfirmSignup] = useMutation<ConfirmSignupResponse, ConfirmSignupArgs>(
        CUSTOM_CONFIRM_SIGN_UP
    );
    useEffect(() => {

        handleConfirm()

    }, [])

    const handleConfirm = async () => {

        try {
            const variables = {
                username: user_name || "",
                clientId: client_id || "",
                confirmationCode: confirmation_code || "",

            };

            const response = await ConfirmSignup({ variables });
            const result = response.data?.customConfirmSignup?.status;

            if (result) {
                setData("User is Confirmed Sucessfully")
            }
            else {
                setData(" Unable to Confirm User Link is expired or Already Confirmed ")
            }

        }

        catch (error) {
            notify.error(error);
        }

    }


    return (
        <Grid className="landing-page" alignItems="center" >

            {/* <Grid container spacing={2} justifyContent="center">
                <Grid item md={8}>

                </Grid>

            </Grid> */}
            <Grid className="landing-page__info" container spacing={2} justifyContent="center">
                <Grid item md={4}>
                    <Box textAlign="center" marginTop={4} >
                        <img src={MNPLogo} alt="myNILpay logo" />
                    </Box>
                    <strong>Welcome to myNILpay</strong>
                </Grid>
            </Grid>
            <Grid item md={8} marginTop={"30px"} color={"#136e1c"} fontSize={"20px"}>
                <strong>{data}</strong>
            </Grid>
        </Grid>
    )
}

export default ConfirmUser;