import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { TRANSACTION_SUMMARY } from "queries";
import { useNotifications } from "providers/Notification";
import { ApolloError, useQuery } from "@apollo/client";
import { TransactionSummary } from "types";
import { formatCurrency } from "utils";

const defaultSummary = {
  totalPaid: 0,
  completedTransactions: 0,
};

const ProfileHeader = () => {
  const notify = useNotifications();

  const { data } = useQuery<TransactionSummary>(TRANSACTION_SUMMARY, {
    onError: (error: ApolloError) => {
      notify.error(error.message);
    },
  });

  const { totalPaid, completedTransactions } =
    data?.transactionSummary ?? defaultSummary;

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-around"}
      padding={8}
    >
      <Box>
        <Typography fontSize={20} fontWeight={600} color={"secondary.dark"}>
          {completedTransactions}
        </Typography>
        <Typography fontSize={14} fontWeight={300} color={"secondary.dark"}>
          Successful
          <br />
          Transactions
        </Typography>
      </Box>
      <Box>
        <Typography fontSize={20} fontWeight={600} color={"secondary.dark"}>
          {formatCurrency(totalPaid)}
        </Typography>
        <Typography fontSize={14} fontWeight={300} color={"secondary.dark"}>
          Total
          <br />
          Transaction Amounts        </Typography>
      </Box>
    </Stack>
  );
};

export default ProfileHeader;
