import React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  nickname: string;
  jerseyNumber: number;
  sport: string;
  position: string;
  school: string;
  athlete_class: string;
  highSchool: string;
  hometown: string;
  onHandel: () => void
}

const UserInfo = ({ firstName, lastName, email, jerseyNumber, sport, position, school, athlete_class, highSchool, hometown, onHandel }: Props) => {
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      autoComplete="off"
    >
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <TextField
          value={firstName}
          inputProps={{ "data-testid": "firstName" }}
          disabled
          label="First Name"
          type="text"
          margin="dense"
          variant="standard"
          fullWidth
        />
        <TextField
          value={lastName}
          inputProps={{ "data-testid": "lastName" }}
          disabled
          margin="dense"
          label="Last Name"
          type="text"
          variant="standard"
          fullWidth
        />
      </Stack>
      <Stack>
        <TextField
          value={email}
          inputProps={{ "data-testid": "email" }}
          disabled
          margin="dense"
          label="Email"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
        />
      </Stack>
      <br />
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        Athlete Information
      </Stack>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <TextField
          value={school}
          inputProps={{ "data-testid": "school" }}
          disabled
          margin="dense"
          label="School"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
        />
        <TextField
          value={sport}
          inputProps={{ "data-testid": "sport" }}
          disabled
          margin="dense"
          label="Sport"
          type="Sport"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
        />
      </Stack>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <TextField
          value={position}
          inputProps={{ "data-testid": "position" }}
          disabled
          margin="dense"
          label="Position"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
        />
        <TextField
          value={jerseyNumber}
          inputProps={{ "data-testid": "jerseyNumber" }}
          disabled
          margin="dense"
          label="Jersey Number"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
        />
      </Stack>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <TextField
          value={athlete_class}
          inputProps={{ "data-testid": "athlete_class" }}
          disabled
          margin="dense"
          label="Class"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
        />
        <TextField
          value={hometown}
          inputProps={{ "data-testid": "hometown" }}
          disabled
          margin="dense"
          label="Home Town"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
        />
      </Stack>
      <Stack>
        <TextField
          value={highSchool}
          inputProps={{ "data-testid": "highSchool" }}
          disabled
          margin="dense"
          label="High School"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
        />
      </Stack>
      <div>
        <Button
          data-testid={"requestbutton"}
          style={{ width: "400px" }}
          type="button"
          variant="contained"
          onClick={onHandel}
        >
          Request Changes
        </Button>
      </div>
    </Box>

  )
};

export default UserInfo;
