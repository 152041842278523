import { useQuery } from "@apollo/client";
import { ME, MeResponse } from "../queries";

function useUser() {
  const { loading, data, error } = useQuery<MeResponse>(ME);
  if (data) { localStorage.setItem("LoginUserObject", JSON.stringify(data.me)); return data.me };
  if (loading || error) return null;
  return null;
}

export default useUser;
