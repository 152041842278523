import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DoneImage from "assets/images/done_all.png";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import background from "assets/images/background_waves.png";

export const RequestSuccess = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `url(${background})`,
        backgroundColor: "primary.main",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0px 608px",
        backgroundSize: "cover",
        zIndex: "-1",
        color: "#FFF",
      }}
    >
      <Grid
        container
        height={"100%"}
        justifyContent={"center"}
        alignContent={"center"}
        sx={{
          padding: "20px 20px 0px 20px",
        }}
      >
        <Grid item xs={12} md={6} maxWidth="800px">
          <Stack spacing={2}>
            <Typography variant="h4" component="h1">
              Thanks
            </Typography>
            <Typography>
              We received your submission request and will review
            </Typography>

            <Box margin="auto">
              <img
                src={DoneImage}
                alt="check mark"
                height={126}
                width={126}
              ></img>
            </Box>
            <Typography fontSize={16} fontWeight={600}>
              myNILpay will reach out within 48 hours to continue this process.
              Check your email for next steps
            </Typography>
            <Box padding={4}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate("/login")}
              >
                Return to Login
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
