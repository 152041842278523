import { gql } from "@apollo/client";

export const ADMIN_ImageUpload = gql`
mutation addorUpdateSplashImageDetails($imageUrl: String!,$carouselImage: String,$screen:String!, $startDate: String!,$expiryDate: String!,$group: String!) {
addorUpdateSplashImageDetails(imageUrl: $imageUrl,carouselImage: $carouselImage, screen:$screen, startDate: $startDate,expiryDate: $expiryDate,group: $group ){ imageUrl }
}
`;
export const ADMIN_ImageEdit = gql`
mutation addorUpdateSplashImageDetails($imageUrl: String!,$screen:String!, $startDate: String!,$expiryDate: String!,$id: String!,$group: String!) {
addorUpdateSplashImageDetails(imageUrl: $imageUrl,screen:$screen, startDate: $startDate, expiryDate: $expiryDate,id:$id,group: $group ){ imageUrl }
}
`;
export const ADMIN_ImageData = gql`
query splashImages{splashImages{ imageUrl startDate expiryDate updatedAt screen id group carouselImage}}
`;
export const ADMIN_ImageSingleData = gql`
query splashImageDetails($id:String!){ splashImageDetails(id:$id){ imageUrl carouselImage startDate expiryDate updatedAt screen group}}
`;
export const ADMIN_ImageDelete = gql`
query deleteSplashImageDetails($id:String!){ deleteSplashImageDetails(id:$id)\n}
`;