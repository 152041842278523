/* eslint-disable @typescript-eslint/no-explicit-any*/
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import StyledButton from "./StyledButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Settings from "./Settings";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useUser from "../../hooks/useUser";
import { useAppState } from "../../providers/AppState";
import home from '../../assets/icons/inactive/Home.svg';
import pay from '../../assets/icons/inactive/Payments.svg';
import fav from '../../assets/icons/inactive/Favorites.svg'
import vault from '../../assets/icons/inactive/Vault.svg';
import transaction from '../../assets/icons/nav/transaction.svg';

export const pages = {
  fan: [{ icon: home, menuItem: "home", isMenuActive: true }, { icon: pay, menuItem: "pay", isMenuActive: false }, { icon: fav, menuItem: "favorites", isMenuActive: false }, { icon: vault, menuItem: "Vault", isMenuActive: false }, { icon: transaction, menuItem: "transactions", isMenuActive: false }],
  athlete: [{ icon: home, menuItem: "home", isMenuActive: true }, { icon: pay, menuItem: "Earnings", isMenuActive: false }],
  admin: [{ icon: home, menuItem: "home", isMenuActive: true }, { icon: home, menuItem: "ImageUpload", isMenuActive: true }],
  deleted: [{ icon: home, menuItem: "home", isMenuActive: true }],
};

interface Props {
  onNavigate?: () => void;
}

const SideNav = ({ onNavigate }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const appState = useAppState();
  const user = useUser();
  const path = location.pathname;
  const first = path?.split("/")[1];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pages.fan.forEach((item: any) => {
    if (first === "fanhome") {
      if (item.menuItem === "home") {
        item.isMenuActive = true
      }
    } else if (item.menuItem === first) {
      item.isMenuActive = true
    } else {
      item.isMenuActive = false;
    }
  })
  pages.athlete.forEach((item: any) => {
    if (item.menuItem === first) {
      item.isMenuActive = true
    } else {
      item.isMenuActive = false;
    }
  })
  pages.deleted.forEach((item: any) => {
    if (item.menuItem === first) {
      item.isMenuActive = true
    } else {
      item.isMenuActive = false;
    }
  })




  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (page: any) => {
    appState.setAmount(null);
    appState.setSearchString("");
    appState.setFilters([]);
    navigate(`/${page.menuItem}`, { replace: true });
    if (onNavigate) onNavigate();
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isSelected = (page: any) => {
    const path = location.pathname;
    const first = path?.split("/")[1];

    return first === page.menuItem;
  };

  const userType = user?.userType || "fan";

  return (
    <Stack textAlign="left" margin={2}>
      <Typography fontWeight={600} marginBottom={4}>
        {!user || first === "home" ? "" : `Hi, ${user?.firstName || "there"}!`}
      </Typography>
      <List>
        {/* eslint-disable-next-line */}
        {pages[userType].map((page: any) => (
          <ListItem key={page.menuItem} disablePadding>
            <StyledButton
              page={page}
              isSelected={isSelected(page)}
              onClick={() => handleClick(page)}
            />
          </ListItem>
        ))}
        <Settings onNavigate={onNavigate} />
      </List>
    </Stack>
  );
};

export default SideNav;
