/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { RequestSponsorshipResponse, RequestSponsorshipArgs } from "types";
import { EMAIL_REGEX } from "constant";
import { REQUEST_SPONSORSHIP } from "queries";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useNotifications } from "providers/Notification";
import Header from "components/Header";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import styles from "./Sponsorship.module.css"
import { formatEmployeeNumber, formatPhoneNumber } from '../../../utils'
import useUserProfile from "hooks/useUserProfile";
import { format } from "date-fns";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from "moment";
import { Grid, InputAdornment } from "@mui/material";



interface FormData {
  amount: number,
  athleteId: string,
  companyAddress: { city: string, state: string, street: string, zip: string, },
  companyName: string,
  companyType: string,
  date: string,
  ein: string,
  email: string,
  name: string,
  phoneNumber: string,
  sponsorshipDetails: string,
  sponsorshipDuration: string,
  timeCommitment: string,
  type: string,
}

const typeOfBusiness = [
  "Accommodation and Food Services",
  "Administrative and Support",
  "Agriculture, Forestry, Fishing and Hunting",
  "Arts, Entertainment, and Recreation",
  "Construction",
  "Educational Services",
  "Finance",
  "Health Care and Social Assistance",
  "Information",
  "Insurance",
  "Management of Companies and Enterprises Manufacturing",
  "Mining, Quarrying, and Oil and Gas Extraction",
  "Other Services (except Public Administration) Professional Services",
  "Public Administration",
  "Real Estate",
  "Remediation Services",
  "Rental and Leasing",
  "Retail Trade",
  "Scientific Services",
  "Technical Services",
  "Transportation and Warehousing",
  "Utilities",
  "Waste Management",
  "Wholesale Trade"

];
const typeOfStates = [
  "AL", "AK", "AZ", "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  " WY",
];

const typeOfSponsorship = [
  "Autograph",
  "Display NIL on custom content",
  "Personal Appearance",
  "Product Endorsement",
  "Post on social media",
  "Record a video"
];

const RequestForm = () => {
  const navigate = useNavigate();
  const notify = useNotifications();
  const params = useParams();
  const athleteId = params.id || "";
  const [duration, setDuration] = useState('');
  const [endDate, setEndDate] = React.useState("");
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [sponsorshipAmount, setSponserShipAmount] = useState('');
  const [zip, setZip] = useState("")
  const [requestSponsorship] = useMutation<
    RequestSponsorshipResponse,
    RequestSponsorshipArgs
  >(REQUEST_SPONSORSHIP);
  const { data } = useUserProfile(athleteId);
  const { register, handleSubmit, formState, getValues } = useForm<FormData>({
    defaultValues: {
      amount: undefined,
      athleteId: '',
      companyAddress: { city: '', state: '', street: '', zip: '', },
      companyName: '',
      companyType: '',
      date: '',
      ein: '',
      email: '',
      name: '',
      phoneNumber: '',
      sponsorshipDetails: '',
      sponsorshipDuration: '',
      timeCommitment: '',
      type: '',
    },
    mode: "all",
  });

  const today = new Date();
  const nextDay = today.setDate(today.getDate() + 1);
  const onSubmit = async (form: FormData) => {
    const values: any = getValues();
    const amount = Number(values.amount);

    try {
      const response = await requestSponsorship({
        variables: {
          requestedSponsorship: {
            ...form, date: endDate, amount: amount, sponsorshipDuration: duration, athleteId
          }
        },
      });
      if (response.data?.addRequestedSponsorship) {
        navigate("success");
      } else {
        notify.error("Something went wrong. Try again later");
      }
    } catch (error) {
      notify.error(error);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeEmployeeNumber = (e: any) => {
    const formattedIDNumber = formatEmployeeNumber(e.target.value);
    setEmployeeNumber(formattedIDNumber);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangePhoneNumber = (e: any) => {
    const arr = Array.from(e.target.value)
    if (arr[0] !== "0") {
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      setPhoneNumber(formattedPhoneNumber);
    }
  };

  const hadleChangeStartDate = (newValue: string | number | Date) => {
    setEndDate(format(new Date(newValue), "yyyy-MM-dd"))
  }
  useEffect(() => {
    if (endDate != '') {
      const dateB = moment(endDate);
      const dateC = moment();
      const totalDays = `${dateB.diff(dateC, 'days')} day(s)`;
      setDuration(totalDays)
    }
  }, [endDate])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeAmount = (e: any) => {
    const arr = Array.from(e.target.value)

    if (arr[0] !== "0") {
      const regex = /^[0-9\b]+$/;
      if (e.target.value === "" || regex.test(e.target.value)) {
        if (e.target.value.length <= 10) {
          setSponserShipAmount(e.target.value);
        }
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeZip = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value.length <= 5) {
        setZip(e.target.value);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <Box>
        <Header showBackButton text="sponsor request" />
        <Grid container spacing={0}>
          <Grid item xs={5} className={styles.subheading}>
            Sponsorship Request for :
          </Grid>
          <Grid item xs={7} className={styles.subheadingname}>
            {data?.userProfile.firstName} {data?.userProfile.lastName}
          </Grid>

        </Grid>

        {/* <div className={styles.subheading}>
          <span></span>Sponsorship Request for : <div className={styles.subheadingname}>{data?.userProfile.firstName} {data?.userProfile.lastName}</div>
        </div> */}

        <div className={styles.subheadingalign}>We’re excited to help initiate a sponsorship opportunity for you with this athlete!<br />
          <br />
          Please submit the following form and a myNILpay account executive will be in touch with you soon.</div>
      </Box>
      <Stack direction="column" alignItems="flex-start" spacing={1}>
        <p>Your Info</p>
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "name" }}
          label="Your Name"
          placeholder="Enter name"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.name)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.name?.message) || " "
          }
          {...register("name", { required: "Name is required" })}
        />
        <TextField
          margin="dense"
          label="Company Email Address"
          placeholder="Enter email"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.email)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.email?.message) || " "
          }
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: EMAIL_REGEX,
              message: "Invalid email address",
            },
          })}
        />
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "phoneNumber" }}
          label="Direct Company Phone Number"
          placeholder="XXX-XXX-XXXX"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          value={phoneNumber}

          fullWidth
          error={Boolean(formState.isDirty && formState.errors.phoneNumber)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.phoneNumber?.message) || " "
          }
          {...register("phoneNumber", {
            required: { value: true, message: "Phone number required" },
            minLength: {
              value: 12,
              message: "Phone number should be 10 digits"
            },
            // pattern: {
            //   value: PHONE_REGEX_ZERO,
            //   message: "Phone number should be 10 digits"
            // }

          })}
          onChange={(e) => handleChangePhoneNumber(e)}
        />
        <p>Company Info</p>
        <Stack direction="column" alignItems="flex-start" spacing={1}></Stack>
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "companyName" }}
          label="Company Name"
          placeholder="Enter company name"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.companyName)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.companyName?.message) || " "
          }
          {...register("companyName", { required: "Company name is required" })}
        />

        <TextField
          select
          defaultValue=""
          margin="dense"
          inputProps={{ "data-testid": "companyType" }}
          label="Company Type"
          placeholder="Select Type"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ textAlign: "left" }}
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.companyType)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.companyType?.message) || " "
          }
          {...register("companyType", { required: "Company type is required" })}
        >
          {typeOfBusiness.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "ein" }}
          label="Employer ID Number (EIN)"
          placeholder="00-0000000"
          InputLabelProps={{
            shrink: true,
          }}
          value={employeeNumber}
          type="text"
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.ein)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.ein?.message) || " "
          }
          {...register("ein", {
            minLength: 9, required: "EIN is required",
          })}
          onChange={(e) => handleChangeEmployeeNumber(e)}

        />
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "CompanyStreetAddress" }}
          label="Company Street Address"
          placeholder="Enter Company Street Address"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.companyAddress?.street)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.companyAddress?.street?.message) || " "
          }
          {...register("companyAddress.street", { required: "Company Street Address is required" })}
        />
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "CompanyAddressCity" }}
          label="Company Address City"
          placeholder="Enter Company Address City"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.companyAddress?.city)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.companyAddress?.city?.message) || " "
          }
          {...register("companyAddress.city", { required: "Company Address City is required" })}
        />

        <TextField
          select
          defaultValue=""
          margin="dense"
          inputProps={{ "data-testid": "type" }}
          label="Company State"
          placeholder="Enter Company State"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ textAlign: "left" }}
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.companyAddress?.state)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.companyAddress?.state?.message) || " "
          }
          {...register("companyAddress.state", {
            required: "Company State is required",
          })}
        >
          {typeOfStates.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "Company Zip" }}
          label="Company Zip"
          placeholder="Enter Company Zip"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          value={zip}
          error={Boolean(formState.isDirty && formState.errors.companyAddress?.zip)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.companyAddress?.zip?.message) || " "
          }
          {...register("companyAddress.zip", { required: "Company Zip is required" })}
          onChange={(e) => handleChangeZip(e)}
        />
        <p>Sponsorship Details</p>
        <Stack direction="column" alignItems="flex-start" spacing={1}></Stack>
        <TextField
          select
          defaultValue=""
          margin="dense"
          inputProps={{ "data-testid": "SponsorshipType" }}
          label="Sponsorship Type"
          placeholder="Enter Sponsorship Type"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ textAlign: "left" }}
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.type)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.type?.message) || " "
          }
          {...register("type", {
            required: "Sponsorship Type is required",
          })}
        >
          {typeOfSponsorship.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </TextField>

        <TextField

          margin="dense"
          inputProps={{ "data-testid": "amount" }}
          label="Amount"
          placeholder="0.00"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                $
              </InputAdornment>
            )

          }}

          value={sponsorshipAmount}
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.amount)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.amount?.message) ||
            " "
          }
          {...register("amount", {
            required: "amount is required",
          })}
          onChange={(e) => handleChangeAmount(e)}
        />
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "timeCommitment" }}
          label="Time Commitment"
          placeholder="Enter time commitment"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.timeCommitment)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.timeCommitment?.message) ||
            " "
          }
          {...register("timeCommitment", {
            required: "Time commitment is required",
          })}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disabled={formState.isSubmitting}
            value={duration}
            minDate={nextDay}
            onChange={(newValue: any) => {
              hadleChangeStartDate(newValue)
            }}
            renderInput={(params) => {
              return <TextField label="Sponsorship Duration" inputProps={{
                placeholder: "days", "data-testid": "endDate",
                readOnly: true, value: duration
              }} InputProps={params.InputProps}
                inputRef={params.inputRef}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(formState.isDirty && formState.errors.date)}
                helperText={
                  (formState.isDirty && formState.errors.date?.message) || " "
                }
              />
            }}
          />
        </LocalizationProvider>
        <TextField
          margin="dense"
          inputProps={{ "data-testid": "Sponsorship Details" }}
          label="Sponsorship Details"
          placeholder="Describe details of this sponsorship offer"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.sponsorshipDetails)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.sponsorshipDetails?.message) ||
            " "
          }
          {...register("sponsorshipDetails", {
            required: "Sponsorship Details is required",
          })}
        />
      </Stack>

      <Box sx={{ marginBottom: "60px" }}>
        <Button
          style={{ marginTop: 10 }}
          startIcon={
            formState.isSubmitting ? <CircularProgress size={20} /> : null
          }
          data-testid="submit-button"
          type="submit"
          variant="contained"
          disabled={!formState.isValid || formState.isSubmitting || !endDate}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </form>
  );
};

export const Sponsorship = () => (
  <Box>
    <RequestForm />
  </Box>
);
