/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { REQUEST_ATHLETE, SEARCH_ALL_SCHOOLS, SPORTS } from "queries";
import { RequestAthleteArgs, RequestAthleteResponse, SchoolsResponse, SportsResponse } from "types";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { athleteEmailValidationRegex } from "utils";
import { Autocomplete } from '@mui/material';
import SportIcon from "components/SportIcon";
interface FormData {
  email: string;
  firstName: string;
  lastName: string;
  school: string;
  sport: string;
  rosterUrl: string;
  phoneNumber: string;
}

const RequestForm = () => {
  const notify = useNotifications();
  const navigate = useNavigate();
  const { data, loading: sportsLoader } = useQuery<SportsResponse>(SPORTS);
  const [searchSchool, { data: schools, loading: schoolLoader }] = useLazyQuery<SchoolsResponse>(SEARCH_ALL_SCHOOLS);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [schoolsList, setSchoolsList] = useState<any>([])
  const [inputValue, setInputValue] = React.useState('');
  const [schoolName, setSchoolName] = React.useState('');
  const [sportInputValue, setSportInputValue] = React.useState('');
  const [sportName, setSportName] = React.useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearch = (value: any) => {
    setSchoolName(schoolName)
    searchSchool({
      variables: {
        searchString: value
      }
    }
    )
  };
  useEffect(() => {
    setSchoolsList(schools?.searchSchools?.items)
    setSchoolName(schoolName)
  }, [schools])

  const [requestAthlete] = useMutation<
    RequestAthleteResponse,
    RequestAthleteArgs
  >(REQUEST_ATHLETE);

  const { register, handleSubmit, formState, getValues } = useForm<FormData>({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      school: "",
      sport: "",
      rosterUrl: "",
      phoneNumber: ""
    },
    mode: "all",
  });
  const values: any = getValues();
  const tempSchool = values?.school && values.school;
  const tempsport = values?.sport && values.sport;
  // const result = schools?.searchSchools?.items.filter(item => item.name === tempSchool);
  // const sportresult = data == undefined ? [] : data.sports.filter(item => item.name === tempsport)
  const tempemail = values?.email && values.email;
  const tempfirstName = values?.firstName && values?.firstName;
  const templastName = values?.lastName && values?.lastName;
  const onSubmit = async () => {
    try {
      const response = await requestAthlete({
        variables: {
          requestedAthlete: {
            email: tempemail,
            firstName: tempfirstName,
            lastName: templastName,
            school: tempSchool,
            sport: tempsport,
            rosterUrl: "",
            phoneNumber: ""
          }
        },
      });
      if (response.data?.addRequestedAthlete) {
        navigate("/register/athlete/request/success");
      } else {
        notify.error("Submission Failed");
      }
    } catch (error) {
      notify.error(error);
    }
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" alignItems="flex-start" spacing={1}>
        <TextField
          margin="dense"
          label="Email"
          placeholder="Enter email.edu"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.email)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.email?.message) || " "
          }
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: athleteEmailValidationRegex(),
              message: "Invalid email address",
            },
          })}
        />

        <TextField
          margin="dense"
          inputProps={{ "data-testid": "firstName" }}
          label="First Name"
          placeholder="Enter first name"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.firstName)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.firstName?.message) || " "
          }
          {...register("firstName", { required: "First name is required" })}
        />

        <TextField
          margin="dense"
          inputProps={{ "data-testid": "lastName" }}
          label="Last Name"
          placeholder="Enter last name"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          fullWidth
          error={Boolean(formState.isDirty && formState.errors.lastName)}
          disabled={formState.isSubmitting}
          helperText={
            (formState.isDirty && formState.errors.lastName?.message) || " "
          }
          {...register("lastName", { required: "Last name is required" })}
        />


        <Autocomplete
          loading={schoolLoader}

          disablePortal
          id="combo-box-demo"
          options={schoolsList || []}
          sx={{ width: '100%' }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            setSchoolName(newInputValue)
          }}
          getOptionLabel={(option: any) => option.name}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 0, flexShrink: 0 } }} {...props}>
              <Typography align="left">{option.name}</Typography>
            </Box>
          )}

          renderInput={(params) =>
            <TextField
              value={schoolName}
              label="school"
              {...params}
              placeholder="Enter School"
              id="outlined-required"
              InputProps={{
                ...params.InputProps,
              }}
              error={Boolean(formState.isDirty && formState.errors.school)}
              disabled={formState.isSubmitting}
              helperText={
                (formState.isDirty && formState.errors.school?.message) || " "
              }
              {...register("school", { required: "school is required" })}
              onChange={(e) => handleSearch(e.target.value)}
            />
          }
        />


        <Autocomplete
          loading={sportsLoader}

          disablePortal
          id="combo-box-demo"
          options={data == undefined ? [] : data.sports}
          sx={{ width: '100%' }}
          inputValue={sportInputValue}
          onInputChange={(event, newInputValue) => {
            setSportInputValue(newInputValue);
            setSportName(newInputValue)

          }}
          getOptionLabel={(option: any) => option.name}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <Typography align="left"><SportIcon sportName={option.name} /></Typography>
              <Typography align="left" style={{ paddingLeft: 5 }}>{option.name}</Typography>
            </Box>
          )}
          renderInput={(params) =>
            <TextField
              label="Sport"
              {...params}
              placeholder="Enter sport"
              id="outlined-required"
              InputProps={{
                ...params.InputProps,
              }}
              error={Boolean(formState.isDirty && formState.errors.sport)}
              disabled={formState.isSubmitting}
              helperText={
                (formState.isDirty && formState.errors.sport?.message) || " "
              }
              {...register("sport", { required: "Sport is required" })}
              onChange={() => setSportName(sportName)}
            />
          }
        />
      </Stack>

      <Box>
        <Button
          startIcon={
            formState.isSubmitting ? <CircularProgress size={20} /> : null
          }
          data-testid="submit-button"
          type="submit"
          variant="contained"
          disabled={!formState.isValid || formState.isSubmitting || !schoolName || !sportName}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </form>
  );
};

const Request = () => (
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs={10} md={4} lg={3} marginTop={8}>
      <Stack spacing={4}>
        <Typography>
          Enter your information so myNILpay can review and work towards
          approving your athlete status
        </Typography>
        <RequestForm />
      </Stack>
    </Grid>
  </Grid>
);

export default Request;
