import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { CircularProgress } from "@mui/material";

import Stack from "@mui/material/Stack";
import AppleLogo from "assets/images/apple_logo.png";
import FacebookLogo from "assets/images/facebook_logo.png";
import GoogleLogo from "assets/images/google_logo.png";
import Typography from "@mui/material/Typography";
import useFeature from "hooks/useFeature";
import IconButton from "@mui/material/IconButton";

export type Provider = "Google" | "Apple" | "Facebook";

interface Props {
  label: Provider;
  disabled: boolean;
  onClick: () => void;
}

const imgSrc = {
  Google: GoogleLogo,
  Facebook: FacebookLogo,
  Apple: AppleLogo,
};

const ProviderButton = ({ label, disabled, onClick }: Props) => (
  <IconButton
    onClick={onClick}
    aria-label={label}
    disabled={disabled}
    sx={{ opacity: disabled ? "0.5" : "1" }}
  >
    <img src={imgSrc[label]} alt={`${label} logo`} height={32} />
  </IconButton>
);

export const SSOIcons = () => {
  const [loading, setLoading] = useState(false);
  const isEnabled = useFeature("SSO");
  const options: Provider[] = ["Google", "Apple", "Facebook"];

  if (!isEnabled) return <></>;

  const handleClick = (name: Provider) => {
    setLoading(true);
    const provider = CognitoHostedUIIdentityProvider[name];
    Auth.federatedSignIn({ provider });
    localStorage.setItem("cognitoProvider", name);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <Stack margin={6} spacing={4}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        {loading && <CircularProgress size={16} />}
        <Typography fontWeight="light">{"Sign in with:"}</Typography>
      </Stack>

      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-around"
      >
        {options.map((provider, index) => (
          <ProviderButton
            key={index}
            label={provider}
            disabled={loading}
            onClick={() => handleClick(provider)}
          />
        ))}
      </Stack>
    </Stack>
  );
};
