import React, { useEffect, useState } from "react";

import { Athlete, AthleteResponse, FilterOptions } from "types";
import { FILTER_ATHLETE } from "queries";
import { FILTER_DISPLAY } from "constant";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { useLazyQuery } from "@apollo/client";
import { useNotifications } from "providers/Notification";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FiltersSideNav from "components/FiltersSideNav";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import SearchInput from "components/SearchInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InfiniteScroll from "../InfiniteScroll";
import Skeleton from "@mui/material/Skeleton";
import { AthleteCard } from "../AthleteCard/AthleteCard";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppState } from "providers/AppState";
import NoResults from "components/NoResultsFound/NoResults";
import Button from "@mui/material/Button";
import styles from "./searchathelete.module.css"

interface SearchAthleteProps {
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  onClear?: () => void;
}

function SearchAthlete({
  onClear,
  searchString,
  setSearchString,
}: SearchAthleteProps) {
  const notify = useNotifications();
  const navigate = useNavigate();
  const appState = useAppState();
  const [clear, setClear] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState<FilterOptions[]>(appState.filters);
  const [filterAthlete, { data, fetchMore }] = useLazyQuery<AthleteResponse>(
    FILTER_ATHLETE,
    {
      onError: () => notify.error("Something went wrong. Try again later"),
    }
  );

  useEffect(() => {
    localStorage.setItem("setClearData", "")
    const schoolIds = filters?.filter((x) => x.__typename === "School").map((x) => x.id);
    const sportIds = filters?.filter((x) => x.__typename === "Sport").map((x) => x.id);

    if (searchString || schoolIds.length || sportIds.length) {
      filterAthlete({
        variables: {
          filterParams: {
            searchquery: searchString,
            schoolIds,
            sportIds,
          },
        },
      });
      setClear(false);
    }
  }, [searchString, filters]);

  const handleSearch = (text: string) => {
    setSearchString(text.trim());
  };

  const updateFilters = (filters: FilterOptions[]) => {
    setFilters(filters);
  };

  const handleClick = (item: Athlete) => {
    appState.setFilters(filters);

    if (appState.amount) {
      navigate(`/pay/search/${item.id}`);
    } else {
      navigate(`/fanhome/athlete/${item.id}`);
    }
  };

  const handleClear = () => {
    setClear(true);
    if (onClear) onClear();
  };

  const deleteFilter = (filter: FilterOptions) => {
    const filteredList = filters.filter((item) => item.id !== filter.id);
    setFilters([...filteredList]);
  };
  const location = useLocation();
  const path = location.pathname;
  const first = path?.split("/")[1];


  return (
    <Box>
      {first === "pay" ? "" : <><Typography color={'#fff'} align={'center'} className={styles.messagebutton}>
        Athletes
      </Typography>
        <div className={styles.btn}>
          <Button
            type="submit"
            size="medium"
            onClick={() => setShowFilter(true)}
            disabled={showFilter}
            color={"primary"}
          >
            Schools
          </Button>
        </div></>}

      <FiltersSideNav
        values={filters}
        showFilter={showFilter}
        closeFilter={() => setShowFilter(false)}
        setFilters={updateFilters}
      />
      <SearchInput
        value={searchString}
        onSearch={handleSearch}
        onClear={handleClear}
        placeholder={"Search Athlete"}
        onFilter={() => setShowFilter(true)}
      />
      <Grid container wrap="wrap" spacing={1} marginTop={1}>
        {filters?.map((item) => (
          <Grid item key={item.id}>
            <Chip
              label={item[FILTER_DISPLAY]}
              deleteIcon={<Delete />}
              onDelete={() => deleteFilter(item)}
              sx={{
                borderRadius: "4px",
                backgroundColor: "primary.dark",
                color: "white",
                "& .MuiChip-deleteIcon": {
                  marginRight: "8px",
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
      {searchString !== "" || filters.length !== 0 ? <Box>
        {!clear && data && (
          <>
            {data.filterAthletes?.itemTotal == 0 ? (
              <NoResults />
            ) : (
              <Typography fontWeight="600" textAlign={"left"} marginTop={2}>
                Results ({data?.filterAthletes.itemTotal})
              </Typography>
            )}
            <Box textAlign="left" marginTop={2}>
              <InfiniteScroll
                fetchMore={fetchMore}
                page={data.filterAthletes.pageNumber + 1}
              >
                <List>
                  {data.filterAthletes.items.map((item) => (
                    <AthleteCard
                      key={item.id}
                      athlete={item}
                      isFavorite={item.isFavorite}
                      handleClick={handleClick}
                    />
                  ))}
                </List>
              </InfiniteScroll>
              {data.filterAthletes.pageNumber < data.filterAthletes.pageTotal && (
                <Stack spacing={1} marginBottom={4}>
                  <Skeleton variant="rectangular" height={50} />
                  <Skeleton variant="rectangular" height={50} />
                  <Skeleton variant="rectangular" height={50} />
                </Stack>
              )}
            </Box>
          </>
        )}
      </Box> : <Box></Box>}
    </Box>
  );
}

export default SearchAthlete;
