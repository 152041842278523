import React, { useState } from "react";

import { useMutation } from "@apollo/client";
import { format } from "date-fns";
import { ReactComponent as Back } from "assets/icons/back.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import {
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AmountInput from "components/AmountInput";
import ModalWrapper from "components/ModalWrapper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PlaceholderImage from "assets/images/receipt_placeholder.jpg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useUserProfile from "hooks/useUserProfile";
import { useAppState } from "providers/AppState";
import { getFormatedCurrency } from "utils";
import CircularProgress from "@mui/material/CircularProgress";
import { useNotifications } from "providers/Notification";
import { filedPaymentResponse } from "types/createPaymentToken";
import { createFiledTokens } from "queries/createFiledToken";

function PaymentReview() {
  const notify = useNotifications();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const appState = useAppState();
  const defaultAmount =
    parseInt(searchParams.get("amount") || "0", 10) || appState.amount;
  const [amount, setAmount] = useState(defaultAmount || 0);
  const athleteId = params.id || "";
  const userProfile = useUserProfile(athleteId);
  const [createPayMentToken, { loading }] = useMutation<filedPaymentResponse>(
    createFiledTokens
  );

  const handlePayment = async () => {
    localStorage.setItem("athleteId", athleteId)
    try {
      const res = await createPayMentToken({
        variables: { "amount": `${"" + amount + ""}` }
      });
      localStorage.setItem("checkouttax", `${res?.data?.getPrePaymentDetails.tax}`)
      localStorage.setItem("checkoutToken", `${res?.data?.getPrePaymentDetails.token}`);
      navigate('/CheckoutDetails/', { state: { athleteId: athleteId, amount: amount } })

    } catch (error) {
      notify.error("Something went wrong");
    }

  };

  const handleEditAmount = (newAmount: number) => {
    setAmount(newAmount);
    setShowModal(false);
  };

  const handleBack = () => {
    navigate(appState.from || "/home");
  };

  return (
    <Box>
      <ModalWrapper open={showModal} setOpen={setShowModal}>
        <AmountInput previousValue={amount} onSubmit={handleEditAmount} />
      </ModalWrapper>
      <Stack textAlign={"left"} maxWidth="600px">
        <Stack direction="column" alignItems="flex-start" marginBottom={2}>
          <Button startIcon={<Back />} onClick={handleBack}>
            Back
          </Button>
        </Stack>
        <Stack>
          <Typography fontSize={12} color={"text.secondary"}>
            Date
          </Typography>
          <Typography fontSize={14} fontWeight={600}>
            {format(new Date(), "MMMM d, yyy")}
          </Typography>
          <Box margin="50px auto" position="relative">
            <figure>
              <img
                height={310}
                width={310}
                src={PlaceholderImage}
                alt="After the athlete accepts the direct payment, the receipt will be created"
              />
              <figcaption
                style={{
                  position: "absolute",
                  width: "310px",
                  top: "35%",
                  padding: "25px",
                }}
              >
                <Typography color="primary.main">
                  Once the athlete accepts your direct-payment, your digital receipt is created
                </Typography>
              </figcaption>
            </figure>
          </Box>
        </Stack>

        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography fontSize={12} color={"text.secondary"}>
            Paying
          </Typography>
          <Typography fontSize={12} color={"text.secondary"}>
            Direct-Payment Subtotal
          </Typography>
        </Stack>

        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography fontSize={16} fontWeight={600}>
            {userProfile.data?.userProfile.firstName}{" "}
            {userProfile.data?.userProfile.lastName}
          </Typography>
          <Stack direction={"row"} alignItems="center">
            <Typography fontSize={16} fontWeight={600}>
              {getFormatedCurrency(amount)}
            </Typography>
            <IconButton
              aria-label="edit amount"
              onClick={() => setShowModal(true)}
            >
              <Edit />
            </IconButton>
          </Stack>
        </Stack>
        <Stack marginTop={0.5} marginBottom={0.5} display={'flex'} alignItems={'end'}>
          <Typography fontSize={12} borderRadius={1} sx={{ padding: '2px 6px', backgroundColor: '#EEE4FA' }} fontWeight={300}>
            Fees & Taxes calculated at checkout
          </Typography>
        </Stack>

        <Stack marginTop={2} marginBottom={2}>
          <Typography fontSize={12} color="primary.dark" fontWeight={600}>
            Payment Approval &amp; Expiration
          </Typography>
          <Typography fontSize={12} fontWeight={300}>
            Direct-payment will be sent to the athlete and once  accepted, the transaction will complete. If not accepted within 7 days, the transaction will expire and you will not be charged or receive a receipt.
          </Typography>
        </Stack>

        <Box textAlign={"center"}>
          <Button
            startIcon={loading ? <CircularProgress size={20} /> : null}
            disabled={loading}
            fullWidth
            data-testid="send-payment"
            variant="contained"
            onClick={handlePayment}
          >
            Send Direct Payment
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default PaymentReview;
