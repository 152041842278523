import React from "react";
import assetLinks from './assetlinks.json';


const WellKnown = () => {
    return (<div>
        {`${JSON.stringify(assetLinks)}`}
    </div>)
}

export default WellKnown;