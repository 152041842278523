import React from "react";
import {
  resetPasswordResponse,
  resetPasswordArgs,
  AuthCognitoResponse,
} from "types";
import { PASSWORD_REGEX } from "constant";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useNotifications } from "providers/Notification";
import { RESET_PASSWORD } from "queries";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import PasswordField from "components/PasswordField";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";

interface FormValues {
  code: string;
  password: string;
  confirmPassword: string;
}

interface Props {
  email: string;
  sendCode: (userEmail: string) => Promise<true | undefined>;
}

const ResetPassword = ({ email, sendCode }: Props) => {
  const notify = useNotifications();
  const navigate = useNavigate();
  const [confirmCode, setconfirmCode] = React.useState('')

  const [resetPassword] = useMutation<resetPasswordResponse, resetPasswordArgs>(
    RESET_PASSWORD
  );

  const { register, handleSubmit, formState, control, watch } =
    useForm<FormValues>({
      defaultValues: {
        code: undefined,
        password: "",
        confirmPassword: "",
      },
      mode: "all",
    });

  const { isDirty, errors, isSubmitting } = formState;

  const onSubmit = async ({ code, password }: FormValues) => {
    try {
      const resetResponse = await resetPassword({
        variables: { email, confirmationCode: code, newPassword: password },
      });

      if (resetResponse.data?.resetPassword) {
        const loginResponse: AuthCognitoResponse = await Auth.signIn({
          username: email,
          password,
        });

        if (loginResponse) {
          const token = loginResponse.signInUserSession?.idToken?.jwtToken;
          window.localStorage.setItem("token", token);
          navigate("/home", { replace: true });
        } else {
          notify.error("Something went wrong. Try again later");
        }
      } else {
        notify.error("Something went wrong. Try again later");
      }
    } catch (error) {
      notify.error(error);
    }
  };

  const passwordValue = watch("password");
  const codeValue = watch("code");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeCode = (e: any) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length <= 6) {
      setconfirmCode(onlyNums);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography fontSize={16} fontWeight={500}>
        Confirmation Sent!
      </Typography>
      <Typography fontSize={14} fontWeight={300} marginTop={"12px"}>
        Please check your email and enter the code that we sent to you below
      </Typography>
      <Stack
        direction="column"
        alignItems="flex-start"
        spacing={1}
        marginTop="30px"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
          width="100%"
        >
          <Stack width="100%">
            <TextField
              margin="dense"
              inputProps={{ "data-testid": "code-input" }}
              label="Confirmation"
              placeholder="Enter code"
              InputLabelProps={{
                shrink: true,
              }}
              value={confirmCode}
              fullWidth
              error={isDirty && Boolean(errors.code?.message)}
              helperText={(isDirty && errors.code?.message) || " "}
              {...register("code", {
                required: true,
                minLength: {
                  value: 6,
                  message: "The code should be at least 6 digits long",
                },
              })}
              sx={{
                marginBottom: "0px",
              }}
              onChange={(e) => handleChangeCode(e)}
            />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginBottom={"30px"}
            >
              <Typography fontSize={14} fontWeight={300}>
                Haven’t received a code?
              </Typography>
              <Button
                data-testid="resend"
                variant="text"
                onClick={() => sendCode(email)}
              >
                Resend
              </Button>
            </Box>
          </Stack>
        </Stack>
        <Collapse
          orientation="vertical"
          in={Boolean(codeValue) && !errors.code?.message}
          timeout={500}
          sx={{ width: "100%" }}
        >
          <>
            <Stack width="100%">
              <TextField
                value={email}
                margin="dense"
                data-testid="email"
                label="Email"
                placeholder="Enter email"
                InputLabelProps={{
                  shrink: true,
                }}
                type="text"
                fullWidth
                disabled
                helperText={" "}
              />

              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Minimum password length 8 characters",
                  },
                  pattern: {
                    value: PASSWORD_REGEX,
                    message:
                      "Please use uppercase letters, lowercase letters, special characters, and numbers",
                  },
                }}
                render={({ field }) => (
                  <PasswordField
                    inputProps={{ "data-testid": "password" }}
                    label="Password"
                    placeholder="Enter password"
                    error={isDirty && Boolean(errors.password)}
                    disabled={isSubmitting}
                    helperText={(isDirty && errors.password?.message) || " "}
                    {...field}
                    ref={null}
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: "Confirmed Password is required",
                  validate: (value) =>
                    value === passwordValue || "The passwords do not match",
                }}
                render={({ field }) => (
                  <PasswordField
                    inputProps={{ "data-testid": "confirmPassword" }}
                    label="Confirm Password"
                    placeholder="Enter password"
                    error={isDirty && Boolean(errors.confirmPassword)}
                    disabled={isSubmitting}
                    helperText={
                      (isDirty && errors.confirmPassword?.message) || " "
                    }
                    {...field}
                    ref={null}
                  />
                )}
              />
            </Stack>

            <Button
              startIcon={
                formState.isSubmitting ? <CircularProgress size={20} /> : null
              }
              data-testid="submit-button"
              type="submit"
              variant="contained"
              disabled={!formState.isValid || formState.isSubmitting}
              fullWidth
            >
              Reset Password
            </Button>
          </>
        </Collapse>
      </Stack>
    </form>
  );
};

export default ResetPassword;
