import { gql } from "@apollo/client";

export const SIGN_IN = gql`
  query SignIn($data: UserPass!) {
    signIn(data: $data)
  }
`;

export const SIGN_UP = gql`
  mutation SignUp($data: UserInput!) {
    signUp(data: $data) {
      id
      email
      firstName
      isActive
      lastName
    }
  }
`;

export const requestPersonalInfoChange = gql
  `mutation requestPersonalInfoChange(
    $firstName: String
    $lastName:String
    $email: String
    $school: String
    $sport: String
    $position: String
    $jersyNumber: String
    $class: String
    $highSchool: String
    $homeTown: String
    $comment: String
    ) {
    requestPersonalInfoChange(
      firstName: $firstName
      lastName: $lastName
      email: $email
      school: $school
      sport: $sport
      position: $position
      jersyNumber: $jersyNumber
      class: $class
      highSchool: $highSchool
      homeTown: $homeTown
      comment: $comment
      )
}
`;

export const SEARCH_USERS = gql`
  query SearchUsers($searchString: String!) {
    searchUsers(searchString: $searchString) {
      items {
        id
        firstName
        lastName
      }
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation {
    deleteAccount
  }
`;

export const UPDATE_USER = gql`
  mutation updateUserInfo(
    $firstName: String
    $lastName: String
    $nickname: String
    $phoneNumber:String=""
    $zipCode: String
  ) {
    updateUserInfo(
      firstName: $firstName
      lastName: $lastName
      nickname: $nickname
      phoneNumber:$phoneNumber
      zipCode: $zipCode
    )
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $confirmationCode: String!
    $email: String!
    $newPassword: String!
  ) {
    resetPassword(
      confirmationCode: $confirmationCode
      email: $email
      newPassword: $newPassword
    )
  }
`;

export const TOGGLE_FAVORITE = gql`
  mutation toggleFanFavorite($athleteId: String!) {
    toggleFanFavorite(athleteId: $athleteId)
  }
`;

export const IS_FAVORITE_TEAM = gql`
  query isFanFavoriteTeam($schoolId: String!, $sportId: String!) {
    isFanFavoriteTeam(schoolId: $schoolId, sportId: $sportId)
  }
`;

export const TOGGLE_FAVORITE_TEAM = gql`
  mutation toggleFanFavoriteTeam($schoolId: String!, $sportId: String!) {
    toggleFanFavoriteTeam(schoolId: $schoolId, sportId: $sportId)
  }
`;

export const FAVORITES_TEAMS = gql`
  query favoriteTeams($limit: Int = 25, $page: Int = 1) {
    favoriteTeams(limit: $limit, page: $page) {
      itemTotal
      pageTotal
      pageNumber
      limit
      items {
        id
        sportId
        schoolId
        sport {
          id
          name
        }
        school {
          id
          name
        }
      }
    }
  }
`;
