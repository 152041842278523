import React from "react";
import { RequireAuth } from ".";
import { Route } from "react-router-dom";
import ImageUploadForm from "pages/Admin/ImageUpload/ImageUploadForm";
import ImageEdit from "pages/Admin/ImageUpload/ImageEdit";


export const AdminRoutes = () => (
    <Route element={<RequireAuth userTypes={["admin"]} />}>
        <Route path="/imageupload" element={<ImageUploadForm />} />
        <Route path="/imageedit" element={<ImageEdit />} />
    </Route>
);
