import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppProvider from "./providers";
import { Amplify } from "aws-amplify";

export const AWS_CONFIG = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_CLIENT_ID,
  },
  oauth: {
    domain: process.env.REACT_APP_AWS_AUTH_DOMAIN,
    scope: ["email", "profile", "openid"],
    redirectSignIn: process.env.REACT_APP_URL + "/sso",
    redirectSignOut: process.env.REACT_APP_URL,
    responseType: "token",
  },
};

Amplify.configure(AWS_CONFIG);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// To Prevent the web app from ClickJacking attack
if (window.self === window.top) {
  root.render(
    <React.StrictMode>
      <AppProvider>
        <App />
      </AppProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
