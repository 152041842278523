/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, ChangeEvent } from "react";
import useUser from "hooks/useUser";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAppState } from "providers/AppState";
import Box from "@mui/material/Box";
import { apolloClient } from "providers/Apollo";
import { CircularProgress, Stack } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SportsBaseballOutlinedIcon from '@mui/icons-material/SportsBaseballOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Image, Item } from "semantic-ui-react";

import { useNotifications } from "providers/Notification";
import useUserProfile from "hooks/useUserProfile";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { ATHLETE_EARNINGS_SUMMARY, ATHLETE_HEAD_SHOT } from "../../queries";
import { AthleteTransactionSummaryResponse, HeadShotUploadArgs } from "../../types";
import { AthleteNotFound } from "components/AthleteNotFound/AthleteNotFound";

import { formatCurrency } from "utils";
import { AthleteImage } from "components/AthleteProfileHeader/AthleteImage";

import EditIcon from '@mui/icons-material/Edit';
import useUserType from "hooks/useUserType";
import { Dot } from "components/TextDot/TextDot";
import ReadMore from "components/ReadMore";
import Snapchat from "assets/icons/snapchat-64.png";
import SnapchatIn from "assets/icons/snapchat-inActive-64.png";

import "react-multi-carousel/lib/styles.css";
import DialogBoxCarousal from "./DialogBoxCarousal";

import whatcanido from 'assets/images/what_can_do_fan.png';

import transparency from 'assets/images/transparency.png';


import howItWorksBusiness from 'assets/images/how-it-works-business.png';
import whynilluniversal from 'assets/images/what-is-nil-universal.png';


import whyusemynillpayfan from 'assets/images/whyusemynillpayfan.png'
import howItWorksBusinessStep1 from 'assets/images/how-it-works-business/Frame-1.png';
import howItWorksBusinessStep2 from 'assets/images/how-it-works-business/Frame-2.png';
import howItWorksBusinessStep3 from 'assets/images/how-it-works-business/Frame-3.png';
import howItWorksBusinessStep4 from 'assets/images/how-it-works-business/Frame-4.png';
import howItWorksBusinessStep5 from 'assets/images/how-it-works-business/Frame-5.png';
import howItWorksBusinessStep6 from 'assets/images/how-it-works-business/Frame-6.png';
import howItWorksBusinessStep7 from 'assets/images/how-it-works-business/Frame-7.png';

import Howtouseathlete from 'assets/images/Howtouseathlete.png';

import AthleteTranspancy01 from 'assets/images/TranspancyAthlete/AthleteTranspancy01.png';

import WhyUse01 from 'assets/images/whyuseAthleteCorporate/WhyUse01.png';
import WhyUse02 from 'assets/images/whyuseAthleteCorporate/WhyUse02.png';
import WhyUse03 from 'assets/images/whyuseAthleteCorporate/WhyUse03.png';
import WhyUse04 from 'assets/images/whyuseAthleteCorporate/WhyUse04.png';

import how_it_works_fan_01 from 'assets/images/athlete-profile/direct-pay/01/01.png';
import how_it_works_fan_02 from 'assets/images/athlete-profile/direct-pay/01/02.png';
import how_it_works_fan_03 from 'assets/images/athlete-profile/direct-pay/01/03.png';
import how_it_works_fan_04 from 'assets/images/athlete-profile/direct-pay/01/04.png';
import how_it_works_fan_05 from 'assets/images/athlete-profile/direct-pay/01/05.png';
import how_it_works_fan_06 from 'assets/images/athlete-profile/direct-pay/01/06.png';

import fan_legal_fan_01 from 'assets/images/fanlegal/Frame-1.png';
import fan_legal_fan_02 from 'assets/images/fanlegal/Frame-2.png';
import fan_legal_fan_03 from 'assets/images/fanlegal/Frame-3.png';
import safe_banner from 'assets/images/mynilpaysafeandlegal.png';

import universalnillpaystep1 from 'assets/images/what-is-universal-nil/Frame-1.png';
import universalnillpaystep2 from 'assets/images/what-is-universal-nil/Frame-2.png';
import universalnillpaystep3 from 'assets/images/what-is-universal-nil/Frame-3.png';

import whyusestep2 from 'assets/images/athlete-profile/direct-pay/02/02.png';

import whyusemynilpay02 from 'assets/images/athlete-profile/direct-pay/03/02.png';


import paymentTransparency01 from 'assets/images/athlete-profile/direct-pay/04/01.png';
import paymentTransparency02 from 'assets/images/athlete-profile/direct-pay/04/02.png';

import about_banner01 from 'assets/images/athlete-profile/about/01.png'


import how_it_works_fan from 'assets/images/how-it-works-for-fan.png';
import about from 'assets/images/about.png';

import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
interface ProfileProps {
    athleteId: string;
}

const Profile = ({ athleteId }: ProfileProps) => {
    const notify = useNotifications();
    const [userProfileData, setUserProfileData] = React.useState<any>('')
    const [file, setFile] = useState<File>();
    const [imageloader, setImageLoader] = useState<boolean>(false);
    const [imgUrl, setImgUrl] = useState('')
    const [searchParams] = useSearchParams();
    const [popUpImages, setPopUpImages] = useState<any>([]);

    const userType = useUserType();
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    const { loading, data } = useUserProfile(athleteId);
    const [updateImage] = useMutation<HeadShotUploadArgs>(
        ATHLETE_HEAD_SHOT
    );


    const how_its_for_fan_flow = [
        {
            imageUrl: how_it_works_fan_01
        },
        {
            imageUrl: how_it_works_fan_02
        },
        {
            imageUrl: how_it_works_fan_03
        },
        {
            imageUrl: how_it_works_fan_04
        },
        {
            imageUrl: how_it_works_fan_05
        },
        {
            imageUrl: how_it_works_fan_06
        }
    ]


    const howItworksBusiness = [
        {
            imageUrl: howItWorksBusinessStep1
        },
        {
            imageUrl: howItWorksBusinessStep2
        },
        {
            imageUrl: howItWorksBusinessStep3
        },
        {
            imageUrl: howItWorksBusinessStep4
        },
        {
            imageUrl: howItWorksBusinessStep5
        },
        {
            imageUrl: howItWorksBusinessStep6
        },
        {
            imageUrl: howItWorksBusinessStep7
        }
    ]

    const howToUse = [

        {
            imageUrl: Howtouseathlete
        },

    ]

    const PaymentTransp = [
        {
            imageUrl: AthleteTranspancy01
        },
    ]

    const whyshouldI = [

        {
            imageUrl: WhyUse02
        },
        {
            imageUrl: WhyUse01
        },
        {
            imageUrl: WhyUse03
        },
        {
            imageUrl: WhyUse04
        },

    ]

    const aboutbanner = [
        {
            imageUrl: about_banner01
        },

    ]

    const whyusemynilpay = [{
        imageUrl: whyusemynilpay02
    },]


    const whyusemynillpay = [

        {
            imageUrl: whyusestep2
        },
    ]

    const myNILpayPaymentTransparency = [{
        imageUrl: paymentTransparency01
    },
    {
        imageUrl: paymentTransparency02
    }
    ]

    const handleClickOpenMyNillPayFan = () => {
        setPopUpImages(whyusemynillpay);
        setOpen(true);
    }

    const handleClickOpenTransparency = () => {
        setOpen(true);
        setPopUpImages(myNILpayPaymentTransparency);
    }


    const handleClickOpenHowItWorkBusiness = () => {
        setOpen(true);
        setPopUpImages(howItworksBusiness);
    }

    const handleClickOpenHowTouse = () => {
        setOpen(true);
        setPopUpImages(howToUse);
    }

    const handleClickOpenWhyShouldIuse = () => {
        setOpen(true);
        setPopUpImages(whyshouldI);
    }
    const handleClickOpenTranparency = () => {
        setOpen(true);
        setPopUpImages(PaymentTransp);
    }

    const handleAboutOpen = () => {
        setOpen(true);
        setPopUpImages(aboutbanner);
    }
    const handleClickOpenworksfan = () => {
        setOpen(true);
        setPopUpImages(how_its_for_fan_flow)
    }

    const handleClickOpenWhatCando = () => {
        setPopUpImages(whyusemynilpay);
        setOpen(true);
    }

    useEffect(() => {
        if (data) {
            setUserProfileData(data.userProfile)
        }

    }, [data])

    const { data: athleteData } = useQuery<AthleteTransactionSummaryResponse>(
        ATHLETE_EARNINGS_SUMMARY,
        {
            onError: (error: ApolloError) => {
                notify.error(error.message);
            },
        }
    );

    const { publicProfile } = useAppState();


    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    useEffect(() => {
        apolloClient.cache.reset()
        // const transaction = searchParams.get("transaction");
        // if (transaction === "completed") {
        //     setShowFeedbackModal(true);
        // }
    }, [searchParams]);

    if (publicProfile) {
        // if the user comes from a public profile
        // redirect to the same profile after they register
        return <Navigate to={`/home/athlete/${publicProfile}`} replace />;
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <>{children}</>
                    </Box>
                )}
            </div>
        );
    }

    useEffect(() => {
        function setUploadImge() {
            if (file) {
                setImageLoader(true)
                const formdata = new FormData();
                const myHeaders = new Headers();
                formdata.append("file", file);
                formdata.append("type", "headshot");
                myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
                fetch(`${process.env.REACT_APP_SERVER_URI}/file/upload`, {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow',
                    headers: myHeaders,
                }).then(
                    response => response.text())
                    .then(result => {
                        setImageLoader(false);
                        setImgUrl(result);
                        setImageMutation(result);
                    }
                    ).catch((err) => {
                        setImageLoader(false);
                        notify.error(err.message)
                    }
                    );
            }
        }
        setUploadImge()

    }, [file]);



    const setImageMutation = async (result: string) => {

        setImageLoader(true)
        try {
            setImageLoader(false);
            const res = await updateImage({
                variables: {
                    imageUrl: result
                }

            });
            if (res?.data?.updateHeadshotDetails) {
                setUserProfileData({
                    ...userProfileData,
                    imageUrl: imgUrl,
                });
                apolloClient.cache.reset();

                notify.success("Image Sucessfully Updated");
            }

        } catch (error) {
            setImageLoader(false);
            notify.error("Upload Failed");
        }

    }

    const handleChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    }
    //  const completeNumber = complteCount?.listAthleteTransactions.itemTotal || 0;
    const athleteTotalEarnings = athleteData?.athleteTransactionSummary.totalEarnings || 0;

    if (loading) return <CircularProgress />;
    if (!data) return <AthleteNotFound />;

    const { firstName, lastName, school, isAlumni, socialMedia } = data.userProfile;
    const athleteSport = data?.userProfile.athleteSport;
    const str = firstName + " " + lastName;
    const words = str.split(" ");
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0] ? words[i][0]?.toUpperCase() + words[i].substring(1) : "";
    }
    const fullName = words.join(' ')
    const universalNillPay = [{
        imageUrl: universalnillpaystep3

    }, {
        imageUrl: universalnillpaystep1
    },
    {
        imageUrl: universalnillpaystep2
    }
    ]
    const fanLegal = [{ imageUrl: fan_legal_fan_01 }, { imageUrl: fan_legal_fan_02 }, { imageUrl: fan_legal_fan_03 }]

    const handleClickOpenBusinessUniversal = () => {
        setOpen(true);
        setPopUpImages(universalNillPay);
    }
    const handleClickOpenSafe = () => {
        setPopUpImages(fanLegal);
        setOpen(true);
    }
    return (<>
        <Box className="main-home" sx={{ position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px", }} paddingTop={{ xs: "10px", md: "0" }}>
            <Box className="main-home__header">
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                    <Grid item xs={8} sx={{ textAlign: 'right' }}>
                        <h4>First-of-its-kind experience to direct-pay college athletes</h4>
                    </Grid>
                    <Grid item xs={4} sx={{ justifyContent: 'flex-end' }}>
                        <Box className="user" sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                            <span>
                                <strong>{fullName}</strong>
                                <small>Athlete</small>
                            </span>
                            <AccountCircleIcon />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="main-home__body">
                <Box className="main-home__athlete">
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Box className="profile">
                                <Box className="profile__info">
                                    <Stack>
                                        {/* <img src={AthImg} alt="" /> */}
                                        {(userProfileData?.imageUrl === undefined || imageloader === true) ? <Stack sx={{ position: 'absolute', top: 40, left: 40 }}><CircularProgress /></Stack> : <AthleteImage imageUrl={userProfileData?.imageUrl} sport={athleteSport.sport.name} />
                                        }
                                    </Stack>
                                    {userType == "athlete" ? <Stack sx={{ position: 'absolute', left: 138, top: 115, width: 30, height: 30, cursor: 'pointer', borderRadius: 60, backgroundColor: "#592ee5" }}>
                                        <input accept="image/*" id="icon-button-file"
                                            type="file" style={{ display: 'none' }} onChange={handleChangeImage} />
                                        <label htmlFor="icon-button-file">
                                            <IconButton color="primary" aria-label="upload picture"
                                                component="span">
                                                <EditIcon sx={{ width: 30, height: 20, left: -26, color: '#fff', position: 'absolute', top: 2 }} />
                                            </IconButton>
                                        </label>

                                    </Stack> : ""}

                                    <span>
                                        <strong>{fullName}</strong>
                                        <small>
                                            <em><><LocationOnOutlinedIcon />{school.name}</></em>
                                            <em><><SportsBaseballOutlinedIcon /> {athleteSport.sport.name}</></em>
                                            <em><PermIdentityOutlinedIcon />{isAlumni ? <>
                                                Alumni</> : <> Student</>}</em>
                                            <em>{athleteSport.sportDetails.position ? <> <Dot />{athleteSport.sportDetails.position}</> : ""}</em>
                                            <em>{athleteSport.jerseyNumber ?
                                                <> <Dot />
                                                    &#35;{athleteSport.jerseyNumber}</>
                                                : ""}</em>
                                        </small>
                                        <small className="social">
                                            {socialMedia.facebook_link ? <a href={`https://www.facebook.com/${socialMedia.facebook_link}`} rel="noreferrer" target="_blank"><FacebookIcon sx={{ color: '#ffffff' }} /></a> : <FacebookIcon sx={{ color: '#c096e3' }} />}
                                            {socialMedia.twitter_link ? <a href={`https://www.twitter.com/${socialMedia.twitter_link}`} rel="noreferrer" target="_blank"><TwitterIcon sx={{ color: '#ffffff' }} /></a> : <TwitterIcon sx={{ color: '#c096e3' }} />}
                                            {socialMedia.instagram_link ? <a href={`https://www.instagram.com/${socialMedia.instagram_link}`} rel="noreferrer" target="_blank"><InstagramIcon sx={{ color: '#ffffff' }} /></a> : <InstagramIcon sx={{ color: '#c096e3' }} />}
                                            {socialMedia.snapchat_link ? <a href={`https://www.snapchat.com/${socialMedia.snapchat_link}`} rel="noreferrer" target="_blank"><img src={Snapchat} /></a> : <img src={SnapchatIn} />}
                                        </small>
                                    </span>
                                </Box>
                                <Box>
                                    <h5>Bio</h5>
                                    <Box marginBottom={"44px"}>
                                        <ReadMore text={athleteSport.sportDetails.bio} />
                                    </Box>
                                    {/* <h5>Honors & Awards</h5>
                                    <ul>
                                        <li>Honor Roll - Advanced Business Courses</li>
                                        <li>Leadership Award</li>
                                    </ul> */}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box className="total-earnings">
                                <Box className="total-earnings__fans">
                                    <span>
                                        <small>Favorites</small>
                                        <strong>{data.userProfile.athleteProfileStats.favorites}</strong>
                                    </span>
                                    <span>
                                        <small>Fans</small>
                                        <strong>{data.userProfile.athleteProfileStats.supporters}</strong>
                                    </span>
                                </Box>
                                <Box className="total-earnings__earnings">
                                    <span>Total Earnings</span>
                                    <strong>{formatCurrency(athleteTotalEarnings)}</strong>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Dialog className="carousal-dialog"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">
                        <div style={{ float: 'right' }}>
                            <IconButton onClick={handleClose}>  <div style={{ float: 'right' }}><CloseIcon /></div></IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ height: '100%' }}>
                        <DialogBoxCarousal
                            popUpImages={popUpImages}
                        />
                    </DialogContent>
                </Dialog>
                <Box className="main-home__tabs">
                    <Box>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Direct-Pay" {...a11yProps(0)} />
                            <Tab label="corporate" {...a11yProps(1)} />
                            <Tab label="About" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}> <Item>
                                <div className="carousalmainContainer" onClick={handleClickOpenworksfan}>
                                    <div>How does myNILpay works?</div>
                                    <Image src={how_it_works_fan} alt="Tabs Image" /> </div>
                            </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <div className="carousalmainContainer" onClick={handleClickOpenMyNillPayFan}> <div>How to use myNILpay?</div><Image src={whyusemynillpayfan} alt="Tabs Image" /></div>
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <div className="carousalmainContainer" onClick={handleClickOpenWhatCando}> <div>Why should I use myNILpay?</div><Image src={whatcanido} alt="Tabs Image" /></div>
                                </Item>
                            </Grid>
                            <Grid item xs={3}> <Item> <div className="carousalmainContainer" onClick={handleClickOpenTransparency}><div>Payment Transparency</div><Image src={transparency} alt="Tabs Image" /></div></Item>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}> <Item>
                                <div className="carousalmainContainer" onClick={handleClickOpenHowItWorkBusiness}> <div>How does MyNILpay works?</div>
                                    <Image src={howItWorksBusiness} alt="Tabs Image" /></div>
                            </Item>
                            </Grid>
                            <Grid item xs={3}> <Item>
                                <div className="carousalmainContainer" onClick={handleClickOpenHowTouse}> <div>How to use MyNILpay?</div>
                                    <Image src={whatcanido} alt="Tabs Image" /></div>
                            </Item>
                            </Grid>
                            <Grid item xs={3}> <Item>
                                <div className="carousalmainContainer" onClick={handleClickOpenWhyShouldIuse}> <div>Why should I use MyNILpay?</div>
                                    <Image src={whyusemynillpayfan} alt="Tabs Image" /></div>
                            </Item>
                            </Grid>
                            <Grid item xs={3}> <Item>
                                <div className="carousalmainContainer" onClick={handleClickOpenTranparency}> <div>Payment Transparency?</div>
                                    <Image src={transparency} alt="Tabs Image" /></div>
                            </Item>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}> <Item>
                                <div className="carousalmainContainer" onClick={handleClickOpenBusinessUniversal} ><div>What is NIL</div><Image src={whynilluniversal} className="whyUseMyNilpay" alt="Tabs Image" /></div>
                            </Item></Grid>
                            <Grid item xs={3}>  <Item>
                                <div className="carousalmainContainer" onClick={handleAboutOpen}><div>About Us</div><Image className="whyUseMyNilpay" src={about} alt="Tabs Image" /></div>
                            </Item></Grid>
                            <Grid item xs={3}> <Item>
                                <div className="carousalmainContainer" onClick={handleClickOpenSafe} ><div>myNILpay is SAFE and LEGAL-Universal</div><Image src={safe_banner} className="whyUseMyNilpay" alt="Tabs Image" /></div>
                            </Item></Grid>
                        </Grid>
                    </TabPanel>
                </Box>
            </Box>
        </Box>
    </>);
};

function AthleteHome() {
    // Athlete Home Change
    const user = useUser();
    if (user === null) return <CircularProgress />;

    const athleteOnboarded = user.athleteOnboarded;

    if (!athleteOnboarded) return <Navigate to="/home/payment" replace />;

    // End Athlete Home Change


    return (
        <Box>
            <Profile athleteId={user.id} />
        </Box>
    );
}

export default AthleteHome;
