import { ApolloError } from "@apollo/client";
import { AlertsResponse } from "types";
import { ALERTS } from '../queries'
import { useNotifications } from "providers/Notification";
import { useQuery } from "@apollo/client/react/hooks";

function useAlerts(deviceType: string, sortByDesc: boolean, dismissed: string, athleteEarningActivities: boolean, page: number, limit: number) {
    const notify = useNotifications();
    const data = useQuery<AlertsResponse>(
        ALERTS,
        {
            variables: {
                "deviceType": deviceType,
                "sortByDesc": sortByDesc,
                "dismissed": dismissed,
                "athleteEarningActivities": athleteEarningActivities,
                "page": page,
                "limit": limit
            },
            onError: (error: ApolloError) => {
                notify.error(error.message || "Something went wrong. Try again later");
            }
        }
    );

    return data;
}

export default useAlerts;
