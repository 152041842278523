import { Auth } from "aws-amplify";
import { SignInUserSession } from "../types";
import { useEffect, useState } from "react";
import jwtDecode, { JwtPayload } from "jwt-decode";

export async function refreshToken() {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const token = currentSession?.getRefreshToken();
    cognitoUser.refreshSession(
      token,
      (err: unknown, session: SignInUserSession) => {
        if (err) {
          console.error("failed to refresh token", err);
        } else {
          const idToken = session?.idToken;
          localStorage.setItem("token", idToken?.jwtToken);
        }
      }
    );
  } catch (error) {
    console.error("failed to refresh token", error);
  }
}

async function checkToken() {
  const token = localStorage.getItem("token");

  if (token) {
    const decoded = jwtDecode<JwtPayload>(token);
    const expiration = decoded.exp || 0;
    const dateNow = new Date().getTime() / 1000;
    const timeLeft = expiration && expiration - dateNow;
    const timeLeftInMinutes = timeLeft / 60;

    if (timeLeftInMinutes < 5) {
      await refreshToken();
    }
  }
}

export async function isValidToken(): Promise<boolean> {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.isValid();
  } catch (error) {
    // console.error("failed to refresh token", error);
    return false;
  }
}

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    async function update() {
      const token = localStorage.getItem("token");
      const isAuthenticated = await isValidToken();

      if (isAuthenticated && token) {
        checkToken();
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    }
    update();
  });

  return { isAuthenticated };
};

export default useAuth;
