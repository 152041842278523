import React, { useState } from "react";
import { FILTER_DISPLAY } from "constant";
import { ReactComponent as ChevronUp } from "assets/icons/chevron_up.svg";
import { School, SchoolsResponse } from "types";
import { SEARCH_ALL_SCHOOLS } from "queries";
import { Skeleton } from "@mui/material";
import { useQuery } from "@apollo/client";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import InfiniteScroll from "../InfiniteScroll";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

interface Props {
  isSelected: (x: School) => boolean;
  handleCheckbox: (school: School) => void;
}

const LoadingBoxes = (props: { count: number }) => {
  const array = [];

  for (let step = 0; step < props.count; step++) {
    array.push(<Skeleton key={step} sx={{ height: 42 }} />);
  }

  return <>{array}</>;
};

function SchoolList({ isSelected, handleCheckbox }: Props) {
  const [show, setShow] = useState(true);
  const { data, fetchMore } = useQuery<SchoolsResponse>(SEARCH_ALL_SCHOOLS);

  if (!data) return <></>;
  const { pageTotal, pageNumber } = data.searchSchools;
  const isLastPage = pageTotal === pageNumber;

  return (
    <>
      <ListItemButton
        onClick={() => setShow(!show)}
        sx={{
          backgroundColor: "background.paper",
          zIndex: 1,
          position: "sticky",
          top: -5,
          "&:hover": {
            backgroundColor: "background.paper",
          },
        }}
      >
        <ListItemText primary="Schools" />
        <Box
          sx={{
            transition: "transform 300ms",
            transform: show ? "rotate(0deg)" : "rotate(180deg)",
          }}
        >
          <ChevronUp />
        </Box>
      </ListItemButton>
      <Divider />
      {show && (
        <Box
          sx={{
            maxHeight: "50vh",
            overflow: "scroll",
          }}
        >
          <InfiniteScroll
            fetchMore={fetchMore}
            page={data.searchSchools.pageNumber + 1}
          >
            <List component="div">
              {data.searchSchools.items.map((school) => (
                <ListItem
                  key={school.id}
                  sx={{
                    paddingTop: "0",
                    paddingBottom: "0",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSelected(school)}
                        onChange={() => handleCheckbox(school)}
                      />
                    }
                    labelPlacement="start"
                    label={
                      <Typography fontSize={"14px"} fontWeight={"500"}>
                        {school[FILTER_DISPLAY]}
                      </Typography>
                    }
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </InfiniteScroll>
          {!isLastPage && <LoadingBoxes count={5} />}
        </Box>
      )}
    </>
  );
}

export default SchoolList;
