import Box from "@mui/material/Box";
import React from "react";
import Header from "components/Header";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router";
const CheckoutDetails = () => {
    const location = useLocation();
    // eslint-disable-next-line
    const athleteData: any = location.state;
    // eslint-disable-next-line
    const amount: any = athleteData.amount;
    const tax = localStorage.getItem('checkouttax');

    // eslint-disable-next-line
    const getTotal = (amount: number, tax: number) => {
        return Number(amount) + tax
    }
    const navigate = useNavigate();
    const handlePayment = async () => {
        navigate('/BillingContactInfo', { state: { id: athleteData.athleteId, amount: amount, tax: tax } });
        localStorage.setItem("success", "")
    }
    return (
        <Box className="checkout-details">
            <Header text="Checkout Details" />
            <Box className="checkout-details__info">
                <Box flexDirection={"column"} justifyContent={"start"} display={"flex"} textAlign={"left"}>
                    <small>Pay myNILpay,Inc</small>
                    <strong>${getTotal(Number(amount), Number(tax))}</strong>
                </Box>
                <Box sx={{ mt: 4 }} flexDirection={"row"} justifyContent={"space-between"} display={"flex"}>
                    <span>Payment</span>
                    <span>${amount}</span>
                </Box>
                <Divider sx={{ mt: 4 }} />
                <Box sx={{ mt: 1 }} flexDirection={"row"} justifyContent={"space-between"} display={"flex"}>
                    <span>Sub Total</span>
                    <span>${amount}</span>
                </Box>
                <Box sx={{ mt: 8 }} flexDirection={"row"} justifyContent={"space-between"} display={"flex"}>
                    <em>Add Promotion code</em>
                </Box>
                <Box sx={{ mt: 2 }} flexDirection={"row"} justifyContent={"space-between"} display={"flex"}>
                    <span> State Sales Tax</span>
                    <span>${tax}</span>
                </Box>
                <Box sx={{ mt: 6 }} flexDirection={"row"} justifyContent={"space-between"} display={"flex"}>
                    <span> Total Due</span>
                    <span>${getTotal(Number(amount), Number(tax))}</span>
                </Box>
                <Divider sx={{ mt: 1 }} />
            </Box>
            <Button sx={{ mt: 8 }}
                type="submit"
                fullWidth
                color={"primary"}
                variant="contained"
                onClick={handlePayment}
            >

                Pay
            </Button>


        </Box>
    )
}
export default CheckoutDetails;