import React, { useEffect, useState } from "react";
import { ApolloError, useQuery } from "@apollo/client";
import { ME, MeResponse } from "queries";
import { Navigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";
import background from "assets/images/background_waves.png";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Icon from "assets/icons/identity/contact_support.svg";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CANCEL_POLLING_MS, POLLING_MS } from "constant";

const ContactSupport = () => {
  // const EMAIL = process.env.REACT_APP_EMAIL_SUPPORT;
  // const href = `mailto:${EMAIL}?subject=Identity Verification Support`;

  return (
    <Box marginTop={{ xs: "10px", md: "90px" }} padding="20px">
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          backgroundImage: `url(${background})`,
          backgroundColor: "primary.main",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "0px 608px",
          backgroundSize: "cover",
          zIndex: "-1",
        }}
      ></Box>
      <Stack
        spacing={2}
        sx={{
          color: "#FFF",
        }}
      >
        <Typography fontSize={34} fontWeight={600}>
          HANG TIGHT
        </Typography>
        <Typography>
          We have submitted your details to complete the identity verification
        </Typography>

        <Box margin="auto" padding={4}>
          <img src={Icon} alt="warning icon" height={126} width={126}></img>
        </Box>
        <Typography>
          Check your email for updates as this process may take 24-48 hours
        </Typography>

        <Box padding={4}>
          <Button variant="contained" color="secondary" href={"/contactsupport"}>
            Contact Support
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

const Loader = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setShow((prev) => !prev);
    }, POLLING_MS);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box marginTop={{ xs: "10px", md: "90px" }} padding="20px">
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          backgroundImage: `url(${background})`,
          backgroundColor: "primary.main",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "0px 608px",
          backgroundSize: "cover",
          zIndex: "-1",
        }}
      ></Box>
      <Stack
        spacing={2}
        sx={{
          color: "#FFF",
        }}
      >
        <Typography fontSize={34} fontWeight={600}>
          Checking Identity Verification
        </Typography>

        <LinearProgress />
        <Box position="relative">
          <Typography
            sx={{
              position: "absolute",
              top: 0,
              opacity: show ? 1 : 0,
              transition: "all 0.25s ease",
            }}
          >
            Processing information...
          </Typography>
          <Typography
            sx={{
              position: "absolute",
              top: "0",
              opacity: !show ? 1 : 0,
              transition: "all 0.25s ease",
            }}
          >
            Please wait...{" "}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

const Pending = () => {
  const notify = useNotifications();
  const [pollingDone, setPollingDone] = useState(false);
  const { data, startPolling, stopPolling } = useQuery<MeResponse>(ME, {
    fetchPolicy: "no-cache",
    onError: (error: ApolloError) => {
      notify.error(error.message);
    },
  });

  useEffect(() => {
    startPolling(POLLING_MS);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      stopPolling();
      setPollingDone(true);
    }, CANCEL_POLLING_MS);

    return () => clearTimeout(timer);
  }, []);

  const status = data?.me.identityVerification?.status;

  if (status === "pending") {
    return pollingDone ? <ContactSupport /> : <Loader />;
  }

  return <Navigate to="/home/identity" replace />;
};

export default Pending;
