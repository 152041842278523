import { ApolloError } from "@apollo/client";
import { notifcationsCountResponse } from "types";
import { getNotificationsCount } from '../queries'
import { useNotifications } from "providers/Notification";
import { useQuery } from "@apollo/client/react/hooks";

function useNotificationsCount(deviceType: string) {
    const notify = useNotifications();
    const data = useQuery<notifcationsCountResponse>(
        getNotificationsCount,
        {
            variables: {
                "deviceType": deviceType,
            },
            onError: (error: ApolloError) => {
                notify.error(error.message || "Something went wrong. Try again later");
            }
        }
    );

    return data;
}

export default useNotificationsCount;
