import React from "react";
import assetLinks from './apple-app-site-association.json';


const AppleAppState = () => {
    return (<div>
        {`${JSON.stringify(assetLinks)}`}
    </div>)
}

export default AppleAppState;