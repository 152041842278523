import React from "react";
import { pages } from "./SideNav";
import { ReactComponent as FavoritesIcon } from "../../assets/icons/nav/favorites.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/nav/home.svg";
import { ReactComponent as PayIcon } from "../../assets/icons/nav/pay.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/nav/settings.svg";
import { ReactComponent as TransactionIcon } from "../../assets/icons/nav/transaction.svg";
import { ReactComponent as VaultIcon } from "../../assets/icons/nav/vault.svg";
import { useNavigate } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useUser from "../../hooks/useUser";
//eslint-disable-next-line @typescript-eslint/no-explicit-any 
function GetIcon(page: any) {
  switch (page) {
    case "pay":
      return <PayIcon />;

    case "earnings":
    case "transactions":
      return <TransactionIcon />;

    case "favorites":
      return <FavoritesIcon />;

    case "vault":
      return <VaultIcon />;

    case "settings":
      return <SettingsIcon />;

    default:
      return <HomeIcon />;
  }
}
const BottomNav = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const user = useUser();
  const userType = user?.userType || "fan";
  const userPages = [...pages[userType], "settings"];
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/${userPages[newValue]}`, { replace: true });
    setValue(newValue);
  };
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="navigation"
      sx={{
        background: "#FFF",
        ".MuiTabs-flexContainer": {
          justifyContent: "center",
        },
        ".MuiTabs-indicator": {
          top: 0,
          height: "4px",
        },
        ".MuiTab-root": {
          minWidth: 0,
        },
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {userPages.map((page: any) => (
        <Tab key={page.menuItem} icon={GetIcon(page.menuItem)} aria-label={page} />
      ))}
    </Tabs>
  );
};

export default BottomNav;
