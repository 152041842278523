import React, { useEffect } from "react";
import {
  TRANSACTION
} from "queries";
import {
  TransactionArgs,
  TransactionResponse,
} from "types";
import { Button, CircularProgress } from "@mui/material";
import { format } from "date-fns";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { useLazyQuery } from "@apollo/client";
import { useNotifications } from "providers/Notification";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "utils";
import { useNavigate } from "react-router";

interface Props {
  transactionId: string | null;
  onClose: () => void;
  onCancel: () => void;
}

const SideNavDetails = ({ transactionId, onClose }: Props) => {
  const notify = useNotifications();

  const [transaction, { data, loading }] =
    useLazyQuery<TransactionResponse, TransactionArgs>(TRANSACTION, {
      fetchPolicy: "no-cache",
      onError: () => notify.error("Something went wrong. Try again later"),
    });

  useEffect(() => {
    if (transactionId) {
      transaction({ variables: { transactionId } });
    }
  }, [transactionId]);
  const navigate = useNavigate();

  const athlete = data?.transaction.athlete;
  const athletes = data?.transaction.athletes;
  const handleClick = () => {
    if (transactionId) {
      window.open(`/invoice/${transactionId}`, `_blank`);
    }
  };
  return (
    <Drawer anchor={"right"} open={transactionId !== null} onClose={onClose}>
      <Box width={"400px"} padding={"32px"}>
        <Stack
          direction={"row"}
          spacing={10}
          alignItems={"center"}
          justifyContent="space-between"
          marginBottom={2}
        >
          <Typography fontSize={14} fontWeight={600}>
            Transaction Details
          </Typography>
          <IconButton aria-label="close nft" onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>

        {loading && !data && <CircularProgress />}

        {data && (
          <Stack spacing={4} marginTop={4}>
            <Box>
              <Typography
                fontSize={12}
                fontWeight={500}
                color={"text.secondary"}
              >
                Date
              </Typography>
              <Typography>
                {format(new Date(data.transaction?.createdAt), "MMMM d, yyyy")}
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize={12}
                fontWeight={500}
                color={"text.secondary"}
              >
                Transaction Amount
              </Typography>
              <Typography>{formatCurrency(data.transaction.amount)}</Typography>
            </Box>
            <Box>
              <Typography
                fontSize={12}
                fontWeight={500}
                color={"text.secondary"}
              >
                Athlete{!athlete && "s"}
              </Typography>
              {athlete ? (
                <Typography>
                  {athlete.firstName} {athlete.lastName}
                </Typography>
              ) : (
                <ul style={{ paddingLeft: "20px", margin: 0 }}>
                  {athletes?.map((a) => (
                    <li key={a.id}>
                      {a.firstName} {a.lastName}
                    </li>
                  ))}
                </ul>
              )}
            </Box>
            <Box>
              <Typography
                fontSize={12}
                fontWeight={500}
                color={"text.secondary"}
              >
                Status
              </Typography>
              <Typography sx={{ textTransform: "capitalize" }}>
                {data.transaction.status}
              </Typography>
            </Box>
            {(data.transaction.status === "successful" || data.transaction.status === "refunded") &&
              <Button
                variant="outlined"
                onClick={handleClick}
                component={"a"}
                target="_blank"
              >
                View Transaction Details
              </Button>
            }
          </Stack>
        )}
      </Box>
    </Drawer>
  );
};

export default SideNavDetails;
