import React, { useEffect } from "react";
import AppRoutes from "./router";
import ReactGA from "react-ga4"
ReactGA.initialize("G-C29E918RBQ");
import { datadogRum } from '@datadog/browser-rum'
datadogRum.init({
  applicationId: '666f4e45-492c-4a61-a615-410ab4c29a41',
  clientToken: 'pubb4452df4672612a9bd8e3045fb82b61d',
  site: 'datadoghq.com',
  service: 'mnp_datadog_rum',
  env: 'development',
  // Specify a version number to identify the deployed version of your application in Datadog     // version: '1.0.0',    sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
});

datadogRum.startSessionReplayRecording();

function App() {
  useEffect(() => {

    ReactGA.send({ hitType: "pageview", page: "/my-path" });
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="app">
      <AppRoutes />
    </div>
  );
}

export default App;
