/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { ATHLETE_TRANSACTIONS, Cancel_And_Refund_Success_Transaction_By_Athlete } from "queries";
import { EarningsHeader } from "./Header";
import { format } from "date-fns";
import { formatCurrency } from "utils";
import { listAthleteTransactions, AthleteTransaction } from "types";
import { ReactComponent as ChevronUp } from "assets/icons/chevron_up.svg";
import { useNotifications } from "providers/Notification";

import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Header from "components/Header";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import styled from "@emotion/styled";
import TransactionIcon from "components/TransactionIcon";
import Typography from "@mui/material/Typography";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon

} from "react-share";
import InstagramIcon from '@mui/icons-material/Instagram';
import FilterTopBar from "components/FilterTopBar";
import './earnings.css'
import useAlerts from "hooks/useAlerts";
import CircularProgress from "@mui/material/CircularProgress";
import { Dialog, DialogActions, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';



type Order = "asc" | "desc";

enum SortOptions {
  date = "date",
  name = "name",
  amount = "amount",
}

interface ArrowProp {
  active: boolean;
  direction: Order;
}

const Arrow = ({ active, direction }: ArrowProp) => {
  return (
    <Box
      sx={{
        opacity: active ? 1 : 0,
        transition: "transform 300ms",
        transform: direction === "asc" ? "rotate(0deg)" : "rotate(180deg)",
      }}
    >
      <ChevronUp width={10} height={10} />
    </Box>
  );
};

const HeaderButton = styled(Button)<ButtonProps>(() => ({
  color: "secondary.dark",
  fontSize: 10,
  fontWeight: 600,
  padding: "2px 15px",
  justifyContent: 'start'
}));

const LoadingBoxes = (props: { count: number }) => {
  const array = [];

  for (let step = 0; step < props.count; step++) {
    array.push(
      <Skeleton
        key={step}
        sx={{ bgcolor: "#FFF", borderRadius: "8px" }}
        variant="rectangular"
        height={56}
      />
    );
  }

  return <>{array}</>;
};

export const Earnings = () => {
  const notify = useNotifications();
  const [tableData, setTableData] = useState<AthleteTransaction[]>([]);
  const [sortBy, setSortBy] = useState<SortOptions>(SortOptions.date);
  const [order, setOrder] = useState<Order>("desc");
  const [refundLoader, setRefundLoader] = useState({ loader: false, id: "" })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [share, setShare] = useState<any>();
  const tabs = ["Earnings", "All Activity"];
  const [onCancel_And_Refund_Success_Transaction_By_Athlete] = useMutation(Cancel_And_Refund_Success_Transaction_By_Athlete);
  const [currentTab, setCurrentTab] = useState("Earnings");
  const [open, setOpen] = React.useState(false);
  const [tempId, setTempId] = React.useState('');

  const handleSelect = (value: string) => {
    setCurrentTab(value);
  };

  const { data, loading, refetch } = useQuery<listAthleteTransactions>(
    ATHLETE_TRANSACTIONS,
    {
      fetchPolicy: "no-cache",
      onError: (error: ApolloError) => {
        notify.error(error.message);
      },
      onCompleted: (response) => {
        setTableData(response.listAthleteTransactions.items);
      },
    }
  );
  async function refundHandel(id: string) {
    try {
      setRefundLoader({ loader: true, id: id })
      const response = await onCancel_And_Refund_Success_Transaction_By_Athlete({
        variables: {
          transactionId: id
        },
      });
      if (response) {
        if (response.data.cancelAndRefundSuccessTransactionByAthlete.status > 300) {
          notify.error(`${response.data.cancelAndRefundSuccessTransactionByAthlete.status} : ${response.data.cancelAndRefundSuccessTransactionByAthlete.data}`);
        } else {
          refetch();
          notify.success(`${response.data.cancelAndRefundSuccessTransactionByAthlete.status} : ${response.data.cancelAndRefundSuccessTransactionByAthlete.data}`);
        }
        setRefundLoader({ loader: false, id: id })
      } else {
        setRefundLoader({ loader: false, id: id })
      }
    } catch (error) {
      setRefundLoader({ loader: false, id: "" })
      notify.error(error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any


  const deviceType = "ATHLETE_EARNINGS";
  const sortByDesc = true;
  const dismissed = "0";
  const athleteEarningActivities = true;

  const { loading: activities_loader, data: data_Activities, refetch: activities_refetch } = useAlerts(deviceType, sortByDesc, dismissed, athleteEarningActivities, 1, 5);

  const activities_pageTotal = data_Activities?.getNotifications?.pageTotal || 0;
  const activities_pageNumber = data_Activities?.getNotifications?.pageNumber;

  const pageTotal = data?.listAthleteTransactions.pageTotal || 0;
  const pageNumber = data?.listAthleteTransactions.pageNumber;
  const shareUrl = "www.mynilpay.com";
  const instagramUrl = "https://www.instagram.com/";

  useEffect(() => {
    const page = pageNumber || 1;
    const sort = {
      column: sortBy,
      order,
    };
    refetch({ page, sort });
  }, [sortBy, order]);

  const updateSort = (value: SortOptions) => {
    if (sortBy === value) {
      const isAsc = order === "asc";
      setOrder(isAsc ? "desc" : "asc");
    } else {
      setOrder("asc");
      setSortBy(value);
    }
  };

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    refetch({ page });
  };

  const activities_HandlePagination = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    activities_refetch({ page });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = (id: any) => {
    setTempId(id)
    setOpen(true);
  };
  const on = () => {
    refundHandel(tempId)
    setOpen(false)
  }
  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ margin: '20px', color: '#592EE5', marginTop: '40px' }}>You are about to refund a transaction.</div>
        <div style={{ margin: '20px', fontSize: '13px', textAlign: 'center', color: '#592EE5' }}>Do you want to procced?</div>
        <DialogActions style={{ justifyContent: 'center', lineHeight: '0.75' }} >
          <Button onClick={() => on()} autoFocus>
            Yes
          </Button>
          <Button onClick={handleClose}
            variant={"contained"}>No</Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          backgroundColor: "#F8F7FD",
        }}
      ></Box>

      <Box
        sx={{
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#FFF",
        }}
        paddingTop={{ xs: "30px", md: "80px" }}
      >
        <Grid
          container
          justifyContent={"center"}
          sx={{
            padding: "20px 20px 0px 20px",
          }}
        >
          <Grid item xs={12} md={6} maxWidth="800px">
            <Header text="Earnings" />
            <EarningsHeader />
          </Grid>
        </Grid>
      </Box>
      <Box marginTop="180px">

        <Stack
          sx={{
            display: "flex",
            width: "100%",
            position: "relative"
          }}>
          <FilterTopBar
            selected={currentTab}
            options={tabs}
            onSelect={handleSelect}
          />
        </Stack>
        {currentTab === "Earnings" ? (
          <React.Fragment>
            <List>
              {((activities_loader && !data_Activities?.getNotifications?.items.length) || (loading && !tableData.length)) && (
                <Stack spacing={2}>
                  <LoadingBoxes count={6} />
                </Stack>
              )}
              {tableData.length != 0 &&
                <ListItem className="social-main" sx={{
                  width: "123%"
                }}>
                  <Stack className="earnings"
                    direction={"row"}
                    sx={{
                      borderRadius: "8px",
                      padding: "0 24px",
                      width: "80%"
                    }}
                  >
                    <Stack direction={"row"} alignItems={"center"} width={'100%'}>
                      <Stack className="earnings__date">
                        <HeaderButton
                          onClick={() => updateSort(SortOptions.date)}
                          variant="text"

                          endIcon={
                            <Arrow
                              direction={order}
                              active={sortBy === SortOptions.date}
                            />
                          }
                        >
                          Date
                        </HeaderButton>
                      </Stack>
                      <Stack className="earnings__name">
                        <HeaderButton
                          onClick={() => updateSort(SortOptions.name)}
                          variant="text"
                          endIcon={
                            <Arrow
                              direction={order}
                              active={sortBy === SortOptions.name}
                            />
                          }
                        >
                          Name
                        </HeaderButton>
                      </Stack>
                      <Stack className="earnings__amount">
                        <HeaderButton
                          onClick={() => updateSort(SortOptions.amount)}
                          variant="text"
                          endIcon={
                            <Arrow direction={order} active={sortBy === SortOptions.amount} />
                          }
                        >
                          Amount
                        </HeaderButton>
                      </Stack>
                    </Stack>
                  </Stack>
                </ListItem>
              }
              {Boolean(tableData.length) &&
                tableData.map((t, index) => (
                  <React.Fragment key={t.id}>
                    <ListItem className="social-main" sx={{
                      width: "135%"
                    }}>
                      <Stack className="earnings"
                        direction={"row"}
                        sx={{
                          backgroundColor: "#FFF",
                          borderRadius: "8px",
                          padding: "16px 24px",
                          width: "80%"
                        }}
                      >
                        <Stack direction={"row"} alignItems={"center"} width={'100%'}>
                          <TransactionIcon status={t.status} />
                          <Typography className="earnings__date">
                            {format(new Date(t.updatedAt), "MMM d, yyyy")}
                          </Typography>

                          <Typography className="earnings__name"
                            color="primary.main"
                          >
                            {t.fanName}
                          </Typography>
                          <Typography className="earnings__amount"
                            color="secondary.dark"
                          >
                            {formatCurrency(t.athleteAmount)}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack className="social-icons" sx={{
                        margin: '2px',
                        width: "15%",
                        position: 'relative',
                      }}>
                        <Box sx={{ position: 'relative' }}>
                          <Button className="social-icons__btn"
                            variant={"contained"}
                            disabled={refundLoader.id == t.id && refundLoader.loader || t.status == "expired" || t.status == "pending" || t.status == "refunded" || t.isRefundTimeExpired}
                            onClick={() => handleClickOpen(t.id)}
                            sx={{ float: 'right', fontSize: '12px' }}
                          > {!(refundLoader.id == t.id && refundLoader.loader) && <span>Refund</span>}{(refundLoader.id == t.id && refundLoader.loader) && <CircularProgress size={18} sx={{ marginLeft: 0.2 }} thickness={5} color={"secondary"} />}</Button>
                        </Box>
                      </Stack>
                      <Stack className="social-icons" sx={{
                        width: "15%",
                        position: 'relative',
                      }}>
                        <Box sx={{ position: 'relative' }}>
                          <Button className="social-icons__btn"
                            variant={(share == index ? "outlined" : "contained")}
                            onClick={(() => { if (index == share) { setShare(null) } else { setShare(index) } })}
                            sx={{ float: 'right', fontSize: '12px' }}
                          >
                            Share
                            {share == index && (
                              <Stack sx={{
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'row',
                                top: '2px',
                                left: '65px'

                              }}>
                                <Typography
                                  sx={{
                                    position: 'relative',
                                    margin: '2px'
                                  }}
                                >
                                  <FacebookShareButton className="social-icons__icon" url={shareUrl} quote={`Hey ${t.fanName} has just sent me $${t.athleteAmount} on myNILpay. Click here to visit`} hashtag="#myNILpay">
                                    <FacebookIcon size={15} round={true} />
                                  </FacebookShareButton>
                                </Typography>
                                <Typography
                                  sx={{
                                    position: 'relative',
                                    margin: '2px',
                                  }}
                                >
                                  <TwitterShareButton className="social-icons__icon" url={shareUrl} title={`Hey ${t.fanName} has just sent me $${t.athleteAmount} on myNILpay. Click here to visit`} >
                                    <TwitterIcon size={15} round={true} />
                                  </TwitterShareButton>
                                </Typography>
                                <Typography
                                  sx={{
                                    position: 'relative',
                                    margin: '2px',
                                    top: '2px'
                                  }}
                                >
                                  <InstagramIcon className="social-icons__insta" color="primary" onClick={() => window.open(instagramUrl, '_blank')} />
                                </Typography>
                              </Stack>
                            )}
                          </Button>


                        </Box>
                      </Stack>
                    </ListItem>

                  </React.Fragment >

                ))}
              {pageTotal > 1 && (
                <Pagination
                  sx={{ display: "flex", justifyContent: "center" }}
                  size="small"
                  count={pageTotal}
                  page={pageNumber}
                  onChange={handlePagination}
                />
              )}

              {!loading && !tableData.length && (
                <ListItem
                  className="allActivityNoDataList"
                >
                  <Stack>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color={"secondary.dark"}
                    >
                      {"You don't have any earnings yet"}
                    </Typography>
                  </Stack>
                </ListItem>
              )}
            </List>
          </React.Fragment>
        ) :
          <React.Fragment>
            {!activities_loader && data_Activities?.getNotifications?.items?.length != 0 ?
              data_Activities?.getNotifications?.items.map((t) => (
                <React.Fragment key={t.id}>
                  <ListItem
                    className="allActivityList"
                    sx={{
                      display: "block",
                      backgroundColor: "#FFF",
                      borderRadius: "8px",
                      padding: "6px 16px",
                      margin: "10px 0 0 0",
                      border: "#FFF",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      width={"100%"}
                      alignItems={"center"} spacing={2}
                      sx={{
                        display: "flex",
                        padding: "2px 16px"
                      }}
                    >
                      <Typography className="break-all-cmn"
                        fontSize={12}
                        fontWeight={600}

                        sx={{
                          width: '100%'
                        }}
                      >
                        {t.data}
                      </Typography>


                    </Stack>
                    <Stack
                      width={"100%"}
                      sx={{
                        padding: "0px 16px"
                      }}>
                      <Typography fontSize={12} fontWeight={500}
                        color={"secondary.dark"}>
                        {format(new Date(t.createdAt), "MMM d, yyyy")}
                      </Typography>
                    </Stack>
                  </ListItem>
                </React.Fragment >
              )) :
              <List>
                <ListItem
                  className="allActivityNoDataList"
                >
                  <Stack>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color={"secondary.dark"}
                    >
                      {"You don't have any activity yet"}
                    </Typography>
                  </Stack>
                </ListItem>
              </List>
            }
            {activities_pageTotal > 1 && (
              <Pagination
                sx={{ display: "flex", justifyContent: "center" }}
                size="small"
                count={activities_pageTotal}
                page={activities_pageNumber}
                onChange={activities_HandlePagination}
              />
            )}

          </React.Fragment>}


      </Box>
    </Box>
  );
};
