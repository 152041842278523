import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import mnpLogo from "assets/images/mnp_logo.svg";
import Button from "@mui/material/Button";
// import { useNavigate } from "react-router-dom";
function LandingPage() {
  // const navigate = useNavigate();
  return (<Grid className="landing-page" alignItems="center" style={{ height: '100vh' }}>
    <Grid container spacing={2} justifyContent="center">
      <Grid item md={8}>
        <Box textAlign="left" marginTop={4} marginLeft={{ xs: 6, md: 15 }}>
          <img src={mnpLogo} alt="myNILpay logo" />
        </Box>
      </Grid>
      <Grid className="landing-page__link" item md={4} marginTop={4}>
        Already have an account? <Link to="/login">Sign In</Link>
      </Grid>
    </Grid>
    <Grid className="landing-page__info" container spacing={2} justifyContent="center">
      <Grid item md={4}>
        <strong>Welcome to myNILpay</strong>
        <p>Direct-pay any athlete at any time</p>
        <Button
          data-testid="submit-button"
          type="submit"
          variant="contained"
          fullWidth

          component={Link} to="/register"
        >
          Register
        </Button>
      </Grid>
    </Grid>
  </Grid>
  )

}
export default LandingPage;