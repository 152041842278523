import React from "react";
import { CircularProgress } from "@mui/material";
import { Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoginForm from "./LoginForm";
import mnpLogo from "assets/images/mnp_logo.svg";
import useAuth from "hooks/useAuth";
import Typography from "@mui/material/Typography";

function Login() {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated === null) return <CircularProgress />;
  if (isAuthenticated) return <Navigate to="/home" replace />;

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Box textAlign="left" marginTop={4} marginLeft={{ xs: 6, md: 15 }}>
          <Typography fontSize={13} marginBottom={0.5} fontWeight="light">
            {"Welcome back to"}
          </Typography>
          <img src={mnpLogo} alt="myNILpay logo" />
        </Box>
      </Grid>
      <Grid item xs={10} md={4} lg={3} marginTop={4}>
        <LoginForm />
      </Grid>
    </Grid>
  );
}

export default Login;
