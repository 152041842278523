import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Auth } from "aws-amplify";

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_SERVER_URI}/graphql`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem("token")}` || null,
    },
  }));

  return forward(operation);
});

const errorHandler = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      const code = extensions.code;

      if (code === "FORBIDDEN" || code === "UNAUTHENTICATED") {
        Auth.signOut();
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("LoginUserObject");
        window.location.replace("/login");
      }
      console.log(`[GraphQL error]: Message: ${message}`);
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // merge old and new items to accomplish infinite scroll
          searchSchools: {
            keyArgs: ["searchString"],
            merge(existing = { items: [] }, incoming) {
              const items = [...existing.items, ...incoming.items];
              return { ...incoming, items };
            },
          },
          filterAthletes: {
            keyArgs: ["filterParams"],
            merge(existing = { items: [] }, incoming) {
              const items = [...existing.items, ...incoming.items];
              return { ...incoming, items };
            },
          },
          listNFTs: {
            keyArgs: false,
            merge(existing = { items: [] }, incoming) {
              const items = [...existing.items, ...incoming.items];
              return { ...incoming, items };
            },
          },
          getNotifications: {
            keyArgs: ["deviceType"],
            merge(existing = { items: [] }, incoming) {
              const items = [...existing.items, ...incoming.items];
              return { ...incoming, items };
            },
          }
        },
      },
    },
  }),
  link: ApolloLink.from([authMiddleware, errorHandler, httpLink]),
});
