import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Layout from "components/Layout";
import useAuth from "hooks/useAuth";
import useUserType from "hooks/useUserType";
import { UserType } from "types";
import { AthleteRoutes } from "./AthleteRoutes";
import { CommonRoutes } from "./CommonRoutes";
import { FanRoutes } from "./FanRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { AdminRoutes } from './AdminRoutes'

interface RequireAuthProps {
  userTypes: UserType[];
}

export function RequireAuth({ userTypes }: RequireAuthProps) {
  const userType = useUserType();
  const { isAuthenticated } = useAuth();
  // show loading screen if authentication/authorization is not calculated yet
  if (isAuthenticated === null || userType === null)
    return <CircularProgress />;

  const hasRequiredUserType = userTypes.includes(userType);

  if (!hasRequiredUserType) return <Navigate to="/login" replace />;

  if (isAuthenticated) {
    return <Outlet />;
  } else {
    // Redirect to Home page if not authenticated
    return <Navigate to="/login" replace />;
  }
}

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        {PublicRoutes()}
        <Route element={<Layout />}>
          {AdminRoutes()}
          {CommonRoutes()}
          {AthleteRoutes()}
          {FanRoutes()}

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
