/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { EMAIL_REGEX } from "constant";
import {
    useLazyQuery,
    useMutation, useQuery,
} from "@apollo/client";
import { MISSING_ATHLETE, SEARCH_ALL_SCHOOLS, SPORTS } from "queries";
import { useNotifications } from "providers/Notification";
import Typography from "@mui/material/Typography";
import { Autocomplete, Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SchoolsResponse, SportsResponse } from "types";
import SportIcon from "components/SportIcon";
import { athleteEmailValidationRegex } from "utils";
interface athleteFormValues {
    name: string;
    email: string;
    athleteName: string;
    school: string;
    sport: string;
    athleteEmail: string;

}

export const MissingAthlete = () => {
    const [ReportAthlete] = useMutation(MISSING_ATHLETE);
    const notify = useNotifications();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const form = useForm<athleteFormValues>(
        {
            defaultValues:
            {
                name: "",
                email: "",
                athleteName: "",
                school: "",
                sport: "",
                athleteEmail: "",
            },
            mode: "all",
        });

    const { isDirty, errors, isSubmitting, isValid } = form.formState
    const { data, loading: sportsLoader } = useQuery<SportsResponse>(SPORTS);
    const [searchSchool, { data: schools, loading: schoolLoader }] = useLazyQuery<SchoolsResponse>(SEARCH_ALL_SCHOOLS);
    const [schoolsList, setSchoolsList] = useState<any>([])
    const [inputValue, setInputValue] = React.useState('');
    const [schoolName, setSchoolName] = React.useState('');
    const [sportInputValue, setSportInputValue] = React.useState('');
    const [sportName, setSportName] = React.useState('');
    const handleSearch = (value: any) => {
        setSchoolName(schoolName)
        searchSchool({
            variables: {
                searchString: value
            }
        }
        )


    };
    useEffect(() => {
        setSchoolsList(schools?.searchSchools?.items)
    }, [schools])


    const onSubmit = async ({
        name,
        email,
        athleteName,
        school,
        sport,
        athleteEmail,

    }: athleteFormValues) => {

        try {
            const response = await ReportAthlete({
                variables: {
                    name, email, athleteName, school, sport, athleteEmail
                },
            });
            form.reset()
            if (response?.data) {
                navigate("/missingAthlete/success");
            }
        } catch (error) {
            notify.error(error);
        }
    };


    return (<>

        <Grid marginTop={0} marginBottom={4} color={"#091F82"} fontWeight={"600"} fontSize={"24px"}>
            Missing Athlete
        </Grid>
        <Typography textAlign={"start"} fontSize={"14px"} color={"#808080"}>
            Submission to add or update athlete
        </Typography>

        <Stack marginTop={4}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Stack direction="column" alignItems="flex-start" spacing={1}>
                    <Typography marginBottom={2}>
                        Your Info
                    </Typography>

                    <TextField
                        margin="dense"
                        inputProps={{ "data-testid": "name" }}
                        label="Name"
                        placeholder="Enter Your Name"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="text"
                        fullWidth
                        error={Boolean(isDirty && errors.name)}
                        disabled={isSubmitting}
                        helperText={(isDirty && errors.name?.message) || " "}
                        {...form.register("name", {
                            required: "Name  is required",

                        })}


                    />
                    <TextField
                        margin="dense"
                        inputProps={{ "data-testid": "email" }}
                        label="Email For myNILpay account"
                        placeholder="Enter your account email"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="text"
                        fullWidth
                        error={Boolean(isDirty && errors.email)}
                        disabled={isSubmitting}
                        helperText={(isDirty && errors.email?.message) || " "}
                        {...form.register("email", {
                            required: "Email is required",
                            pattern: {
                                value: EMAIL_REGEX,
                                message: "Invalid email address",
                            },
                        })}

                    />

                </Stack>
                <Stack direction="column" alignItems="flex-start" spacing={1}>
                    <Typography marginBottom={2}>
                        Athlete Info
                    </Typography>

                    <TextField
                        margin="dense"
                        inputProps={{ "data-testid": "athleteName" }}
                        label="Athlete Name"
                        placeholder="Enter athlete's name"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="text"
                        fullWidth
                        error={Boolean(isDirty && errors.athleteName)}
                        disabled={isSubmitting}
                        helperText={(isDirty && errors.athleteName?.message) || " "}
                        {...form.register("athleteName", {
                            required: "Athlete Name  is required",
                        })}
                    />


                    <Autocomplete
                        loading={schoolLoader}
                        disablePortal
                        id="combo-box-demo"
                        options={schoolsList || []}
                        sx={{ width: '100%' }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                            setSchoolName(newInputValue)
                        }}
                        getOptionLabel={(option: any) => option.name}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <Typography align="left"> {option.name}</Typography>
                            </Box>
                        )}
                        renderInput={(params) =>
                            <TextField {...params} error={Boolean(isDirty && errors.school)}
                                value={schoolName}
                                disabled={isSubmitting}
                                helperText={(isDirty && errors.school?.message) || " "}
                                {...form.register("school", {
                                    required: "Athlete's school is required",

                                })}
                                onChange={(e) => handleSearch(e.target.value)}
                                onClick={() => {
                                    setSchoolsList([])
                                }}
                                label="Athlete's school" />

                        }
                    />
                    <Autocomplete
                        loading={sportsLoader}
                        disablePortal
                        id="combo-box-demo"
                        options={data == undefined ? [] : data.sports}
                        sx={{ width: '100%' }}
                        inputValue={sportInputValue}
                        onInputChange={(event, newInputValue) => {
                            setSportInputValue(newInputValue);
                            setSportName(newInputValue)
                        }}
                        getOptionLabel={(option: any) => option.name}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <Typography align="left"><SportIcon sportName={option.name} /></Typography>
                                <Typography align="left" style={{ paddingLeft: 5 }}> {option.name}</Typography>
                            </Box>
                        )}
                        renderInput={(params) => <TextField {...params} error={Boolean(isDirty && errors.sport)}
                            disabled={isSubmitting}
                            helperText={(isDirty && errors.sport?.message) || " "}
                            {...form.register("sport", {
                                required: "Athlete's Sports is required",

                            })}
                            label="Athlete's Sports" />}
                    />


                    <TextField
                        autoFocus
                        margin="dense"
                        inputProps={{ "data-testid": "athleteEmail" }}
                        label="Athlete's .EDU School Email (if known)"
                        placeholder="Enter athlete's school email"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="text"
                        fullWidth
                        error={Boolean(isDirty && errors.athleteEmail)}
                        helperText={(isDirty && errors.athleteEmail?.message) || " "}
                        {...form.register("athleteEmail", {
                            pattern: {
                                value: athleteEmailValidationRegex(),
                                message: "Invalid email address",
                            },
                        })}

                    />

                    <Button
                        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                        data-testid="missingSubmitButton"
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={!isValid || isSubmitting || !schoolName || !sportName}

                    >
                        Submit
                    </Button>

                </Stack>

            </form>
        </Stack>
    </>)
}

export default MissingAthlete;
